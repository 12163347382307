.widgets {
	position: relative;
	width: 100%;
	max-width: 1300px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	margin-top: 5px;
	> .rotationWidget {
		padding: 5px;
	}
	.wSlots,
	.wDice,
	.wCrash,
	.wJackpot {
		width: 100%;
		box-sizing: border-box;
		padding: 20px;
		margin: 2px 0;
		// @include themify($themes) {
		// 	background-color: themed("shade005");
		// }
		display: flex;
		align-items: center;
		> * {
			box-sizing: border-box;
			&:not(:last-child) {
				margin-right: 5px;
			}
		}
		> .profit {
			display: flex;
			flex-direction: column;
			> *:not(:last-child) {
				margin-bottom: 3px;
			}
			font-family: "Roboto Mono";
			font-size: 16px;
			@include themify($themes) {
				&.w {
					color: themed("success");
				}
				&.l {
					color: themed("alert");
				}
			}
		}
		> .avatarComponent .btnA {
			min-width: 111px;
		}
		> .shareBtn {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: unset;
			width: 50px;
			> button {
				width: 40px;
				height: 40px;
				flex: unset;
				border-radius: 5px;
			}
		}
	}
	.wJackpot {
		> .f1 {
			flex: 1;
		}
		> .amount {
			box-sizing: border-box;
			width: 120px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			> img {
				margin-left: 5px;
			}
		}
		> .t {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-left: 5px;
			width: 65px;
			> b {
				&.d {
					font-weight: bold;
				}
				&.t {
					font-size: 10px;
				}
				&:not(:last-child) {
					margin-right: 3px;
				}
			}
		}
	}
	.wDice {
		> .f1 {
			flex: 1;
			> .bars {
				position: relative;
				margin: auto;
				max-width: 350px;
				background-color: rgba(0, 0, 0, 0.3);
				border-radius: 5px;

				height: 55px;
				display: flex;
				flex-direction: row;
				> .img {
					cursor: pointer;
					background-color: gray;
					width: 82px;
					height: 100%;
					border-radius: 5px;
					overflow: hidden;
					> img {
						width: 100%;
						height: 100%;
					}
				}
				> .stripW {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 0 5px;
					> .strip {
						> label {
							position: absolute;
							bottom: 17px;
							text-align: center;
							width: 100%;
							font-size: 11px;
							display: flex;
							align-items: center;
							justify-content: space-around;
							> .pa {
								margin-left: 5px;
								font-weight: bold;
								@include themify($themes) {
									color: themed("_cyan");
								}
							}
						}
						background-color: #000;
						position: relative;
						padding: 2px;
						.red {
							@include themify($themes) {
								background-color: themed("alert");
							}
						}
						.green {
							@include themify($themes) {
								background-color: themed("success");
							}
						}
						> .stripFill {
							height: 10px;
						}
						> .outcomeWrapper {
							position: absolute;
							width: 1px;
							> .outcome {
								position: relative;
								margin-top: 15px;
								width: 50px;
								margin-left: -25px;
								height: 20px;
								border-radius: 5px;
								user-select: none;
								font-size: 16px;
								font-weight: 700;
								display: flex;
								align-items: center;
								justify-content: center;
								color: #000;
							}
						}
					}
				}
			}
		}
	}
	.static {
		.wSlots {
			&:first-of-type {
				.profit {
					// filter: blur(5px);
				}
			}
		}
	}
	.wSlots {
		.info {
			cursor: pointer;
			flex: 1;
			display: flex;
			justify-content: center;
			> .wrapper {
				cursor: pointer;
				max-width: 350px;
				width: 100%;
				background-color: rgba(0, 0, 0, 0.3);
				display: flex;
				align-items: center;
				border-radius: 5px;
				overflow: hidden;
				> img {
					width: 82px;
					height: 55px;
					cursor: pointer;
				}
				> .detail {
					cursor: pointer;
					margin-left: 5px;
					display: flex;
					flex-direction: column;
					// width: 100%;
					> b:not(:last-child) {
						margin-bottom: 3px;
						&.name {
							font-size: 12px;
							font-weight: bold;
						}
						&.provider {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
	.wCrash {
		.info {
			cursor: pointer;
			flex: 1;
			display: flex;
			justify-content: center;
			> .wrapper {
				cursor: pointer;
				max-width: 350px;
				width: 100%;
				// background-color: rgba(0, 0, 0, 0.3);
				display: flex;
				align-items: center;
				border-radius: 5px;
				overflow: hidden;
				> .detail {
					cursor: pointer;
					margin-left: 5px;
					display: flex;
					flex-direction: column;
					align-items: center;
					// width: 100%;
					> b:not(:last-child) {
						margin-bottom: 3px;
					}
					> b {
						&.cashOut {
							font-size: 12px;
							font-weight: bold;
						}
						&.outcome {
							font-size: 25px;
							font-weight: 700;
							@include themify($themes) {
								&.red {
									color: themed("alert");
								}
								&.green {
									color: themed("success");
								}
							}
						}
					}
				}
			}
		}
	}
	&::after {
		@include themify($themes) {
			background-image: themed("upTransparent");
		}
		content: "";
		z-index: 2;
		position: absolute;
		bottom: 0px;
		left: 0px;
		height: 30%;
		width: 100%;
		pointer-events: none;
	}
}
@media (max-width: (550px + $chatPaneWidth)) {
	.chatOn {
		&.bodyPane .widgets {
			.wDice > .f1 > * {
				display: none;
			}
			.wSlots > .info > .wrapper,
			.wCrash > .info > .wrapper,
			.wDice > .info > .wrapper {
				display: none;
			}
		}
	}
}
@media (max-width: 550px) {
	.chatOff {
		&.bodyPane .widgets {
			.wDice > .f1 > * {
				display: none;
			}
			.wSlots > .info > .wrapper,
			.wCrash > .info > .wrapper,
			.wDice > .info > .wrapper {
				display: none;
			}
		}
	}
}
@media (max-width: (700px + $chatPaneWidth)) {
	.chatOn {
		&.bodyPane .widgets {
			.wSlots > .info > .wrapper,
			.wCrash > .info > .wrapper,
			.wDice > .info > .wrapper {
				background-color: transparent;
				justify-content: center;
				> .detail {
					display: none;
				}
			}
		}
	}
}
@media (max-width: 700px) {
	.chatOff {
		&.bodyPane .widgets {
			.wSlots > .info > .wrapper,
			.wCrash > .info > .wrapper,
			.wDice > .info > .wrapper {
				background-color: transparent;
				justify-content: center;
				> .detail {
					display: none;
				}
			}
		}
	}
}
