.menuButton {
	margin: auto 2px;
	> button {
		position: relative;
		z-index: 0;
		background-size: contain;
		> .icon {
			transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
			pointer-events: none;
			position: absolute;
			z-index: 1;
			bottom: -8px;
			left: 50%;
			margin-left: -8px;
			@include themify($themes) {
				background-color: themed("menuButtonBackgroundColor");
				color: themed("menuButtonColor");
			}
			width: 16px;
			height: 16px;
			border-radius: 3px;
			padding: 0 0 !important;
			display: flex;
			align-items: center;
			justify-content: center;
			i {
				display: flex !important;
				align-items: center;
				justify-content: center;
				svg {
					display: flex !important;
					align-items: center;
					justify-content: center;
					padding: 0;
					margin: 0;
				}
			}
		}
		@media (hover: hover) {
			&:hover > .icon {
				@include themify($themes) {
					background-color: themed("menuButtonBackgroundColorHover");
					color: themed("menuButtonColorHover");
				}
			}
		}
	}
}
.chatOff .btnLogoHome {
	margin: auto 5px !important;
}
.chatOn {
	@media (max-width: ($chatPaneWidth + 800px)) {
		.btnLogoHome {
			&.small {
				display: block;
			}
			&.big {
				display: none;
			}
		}
	}
	@media (min-width: ($chatPaneWidth + 800px)) {
		.btnLogoHome {
			&.small {
				display: none;
			}
			&.big {
				display: block;
			}
		}
	}
}
.chatOff {
	@media (max-width: 800px) {
		.btnLogoHome {
			&.small {
				display: block;
			}
			&.big {
				display: none;
			}
		}
	}
	@media (min-width: 800px) {
		.btnLogoHome {
			&.small {
				display: none;
			}
			&.big {
				display: block;
			}
		}
	}
}

.btnLogoHome {
	transition: transform 0.15s ease-in-out;
	@media (hover: hover) {
		&:hover {
			transform: scale(1.05);
		}
	}
}
