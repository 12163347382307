.exchange {
	padding: 10px;
	padding-bottom: 70px;
	display: flex;
	align-items: center;
	flex-direction: column;
	> .wrapper {
		width: 100%;
		max-width: 750px;
		position: relative;
		> .loader {
			margin-top: 45px;
		}
		> h1,
		> h3 {
			margin-bottom: 10px;
		}
		> h1 {
			margin-bottom: 10px;
			font-size: 22px;
		}
		> .coins {
			display: flex;

			> .from {
				margin-right: 10px;
			}
			> .icon {
				display: flex;
				align-items: center;
				padding-bottom: 10px;
			}
			> .to {
				margin-left: 10px;
			}
			> .from,
			> .to {
				flex: 1;
				padding: 10px;
				border-radius: 5px;
				margin-bottom: 10px;
				@include themify($themes) {
					background-color: themed("formBackgroundColor");
				}
				// > h3 {
				// 	font-size: 16px;
				// }
				> .wrapper {
					flex: 1;
					margin-top: 5px;
					display: flex;
					flex-wrap: wrap;
					> button {
						display: flex;
						flex-direction: row;
						> b {
							font-size: 18px;
							margin-left: 3px;
						}
						align-items: center;
						justify-content: center;
						margin: 2px;
						padding: 3px 5px;
					}
				}
			}
		}
		> .info {
			> div {
				> b {
					&:last-child {
						font-family: "Roboto Mono";
						@include themify($themes) {
							color: themed("warn");
						}
					}
					&:not(:last-child) {
						margin-right: 10px;
					}
					&.warn {
						@include themify($themes) {
							color: themed("warn");
						}
					}
				}
				&:not(:last-child) {
					margin-bottom: 5px;
				}
			}
			margin-bottom: 10px;
		}
		> .actions {
			margin-bottom: 10px;
			> .wrapper {
				box-sizing: border-box;
				border-radius: 5px;
				width: 100%;
				padding: 10px;
				@include themify($themes) {
					background-color: themed("formBackgroundColor");
				}
				> .buttons {
					display: flex;
					align-items: center;
					margin-bottom: 3px;
					margin-left: 37px;
					> button {
						flex: 1;
						min-height: 24px;
						&:not(:last-child) {
							margin-right: 3px;
						}
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				> .inpWrapper {
					width: 100%;
					display: flex;
					align-items: center;
					> img {
						margin-right: 5px;
					}
					> input {
						text-align: center;
						&.inpAmount {
							margin-bottom: 3px;
						}
					}
				}
			}
			> .iconDown {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 10px;
				margin-bottom: 13px;
			}
		}
		> .reasons {
			display: flex;
			flex-direction: column;
			> b {
				&:not(:last-child) {
					margin-bottom: 3px;
				}
				@include themify($themes) {
					color: themed("alert");
				}
			}
			margin-bottom: 10px;
		}
		> .btnWrapper {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			> .btnExchange {
				padding: 20px 25px;
				> b {
					font-size: 18px;
				}
			}
		}
		> .rates {
			display: flex;
			flex-wrap: wrap;
			@include themify($themes) {
				background-color: themed("formBackgroundColor");
			}
			> button {
				flex-direction: column;
				margin-right: 0px;
				> div {
					display: flex;
					flex-direction: row;
					align-items: center;
					> b {
						font-size: 12px;
						margin-left: 5px;
					}
				}
				> progress {
					width: calc(100% - 20px);
					margin-top: 5px;
				}
				> .status {
					margin-top: 5px;
					font-size: 10px;
					user-select: none;
					pointer-events: none;
					&.alert {
						@include themify($themes) {
							color: themed("alert");
						}
					}
					&.success {
						@include themify($themes) {
							color: themed("success");
						}
					}
				}
			}
		}
	}

	progress {
		height: 4px;
		display: block; /* default: inline-block */
		// padding: 4px;
		border: 0 none;
		// background: #444;
		border-radius: 2px;
		box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
	}
	progress::-moz-progress-bar {
		border-radius: 2px;
		background: #fff;
		box-shadow: inset 0 -2px 4px rgba(0, 0, 0, 0.4), 0 2px 5px 0px rgba(0, 0, 0, 0.3);
	}
	progress::-webkit-progress-bar {
		background: transparent;
	}
	progress::-webkit-progress-value {
		border-radius: 2px;
		background: #fff;
		box-shadow: inset 0 -2px 4px rgba(0, 0, 0, 0.4), 0 2px 5px 0px rgba(0, 0, 0, 0.3);
	}
}
