.crashGameInfo {
	min-width: 320px;
	box-sizing: border-box;
	min-height: 450px;
	display: flex;
	flex-direction: column;
	.row {
		width: 100%;
		box-sizing: border-box;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		padding: 5px;
		@include themify($themes) {
			background-color: themed("diceBackground");
		}
		&.info {
			display: flex;
			font-size: 18px;
			> div {
				flex: 1;
				&:not(:last-child) {
					margin-right: 5px;
				}
				> .outcome {
					&.success {
						@include themify($themes) {
							color: themed("success");
						}
					}
					&.grey {
						@include themify($themes) {
							color: themed("_grey");
						}
					}
				}
				> .dt {
					> .d {
						margin-right: 5px;
						font-weight: bold;
					}
					> .t {
						text-decoration: underline;
					}
				}
				padding: 5px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}
		&.pp {
			height: 100%;
			flex: 1;
		}
		&.fair {
			display: flex;
			> button {
				padding: 0 5px;
				> label,
				b {
					font-size: 14px;
				}
			}
		}
		.listScroll {
			.entry {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 11px;
				padding: 3px;
				&.my {
					background-color: rgba(255, 255, 255, 0.3);
				}
				> .bet {
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: "Roboto Mono";
					> *:not(:last-child) {
						margin-right: 5px;
					}
					> .green {
						@include themify($themes) {
							color: themed("success");
						}
					}
					> .red {
						@include themify($themes) {
							color: themed("alert");
						}
					}
				}
			}
		}
	}
}
