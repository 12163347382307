@mixin mBtnDefault($color: "") {
	min-height: 38px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	border-radius: 3px;
	&:not(.toggleButton):not(.btnShortcut) {
		> * {
			padding-right: 5px;
			&:first-child:not(svg):not(.noPadding) {
				padding-left: 5px;
			}
		}
	}
	> label {
		user-select: none;
		pointer-events: none;
		margin: auto 0;
	}
	transition: all 0.2s ease, outline 0;
	@include themify($themes) {
		background-color: themed("btnIcon#{$color}BackgroundColor");
		color: themed("btnIcon#{$color}Color");
		> svg {
			fill: themed("btnIcon#{$color}Color");
		}
		box-shadow: themed("btnIcon#{$color}BoxShadow");
	}
	transition: background-color 0.25s ease, color 0.25s ease;
	@media (hover: hover) {
		&:hover:not(.selected):not(:active) {
			@include themify($themes) {
				background-color: themed("btnIcon#{$color}BackgroundColorHover");
				color: themed("btnIcon#{$color}ColorHover");
				> svg {
					fill: themed("btnIcon#{$color}ColorHover");
				}
			}
		}
	}
	&:active,
	&.selected {
		@include themify($themes) {
			background-color: themed("btnIcon#{$color}BackgroundColorActive");
			color: themed("btnIcon#{$color}ColorActive");
			> svg {
				fill: themed("btnIcon#{$color}ColorActive");
			}
		}
	}
}
button.modalClose {
	&:focus {
		outline: 2px solid rgba(238, 130, 238, 1);
		outline-offset: -2px;
	}
}
.registration,
.chatPane,
.chatRooms,
.menu,
.menuButton {
	a,
	button,
	input,
	textarea {
		&:focus {
			outline: 2px solid rgba(238, 130, 238, 1);
			outline-offset: -2px;
		}
	}
}
button {
	font-family: "Montserrat", "BPG Glaho WEB Caps", sans-serif;
	font-weight: 500;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	outline: none;
	user-select: none;
	background-color: transparent;
	touch-action: pan-y;
	padding: 0px;
	&:disabled {
		pointer-events: none;
		opacity: 0.3;
	}
	box-sizing: border-box;
	border: none;

	&.btnDefault {
		@include mBtnDefault;
		&.alert {
			@include mBtnDefault("Alert");
		}
		&.success {
			@include mBtnDefault("Success");
		}
		&.warn {
			@include mBtnDefault("Warn");
		}
	}
	&.btnBorder {
		@include mBtnDefault;
		background-color: transparent !important;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(255, 255, 255, 0.2);
		@media (hover: hover) {
			&:hover {
				background-color: rgba(255, 255, 255, 0.01) !important;
			}
		}
		&:active {
			background-color: rgba(255, 255, 255, 0.02) !important;
		}

		@include themify($themes) {
			&.alert {
				border-color: themed("alert");
			}
			&.success {
				border-color: themed("warn");
			}
			&.warn {
				border-color: themed("success");
			}
		}
	}
	&.btnIcon {
		@include mBtnDefault;
		&.alert {
			@include mBtnDefault("Alert");
		}
		&.success {
			@include mBtnDefault("Success");
		}
		&.warn {
			@include mBtnDefault("Warn");
		}
		width: 38px;
		height: 38px;
		padding: 0 0;
		> i > svg {
			width: 20px;
			height: 20px;
		}
		&.transparent01 {
			background-color: rgba(255, 255, 255, 0.1);
			@media (hover: hover) {
				&:hover {
					background-color: rgba(255, 255, 255, 0.1) !important;
				}
			}
			&:active {
				background-color: rgba(255, 255, 255, 0.1);
			}
		}
		&.large {
			width: 48px;
			height: 48px;
			min-height: 48px;
			> i > svg {
				width: 25px;
				height: 25px;
			}
		}
		&.small {
			width: 28px;
			height: 28px;
			min-height: 28px;
			> i > svg {
				width: 20px;
				height: 20px;
			}
			&:not(.toggleButton) {
				> * {
					padding-right: 2px;
					&:first-child {
						padding-left: 2px;
					}
				}
			}
		}
		> i {
			display: block;
			margin: auto;
			transform-origin: center center;
			> svg {
				transform-origin: center center;
				transition: transform 0.25s ease;
			}
		}
		@media (hover: hover) {
			&:hover {
				& > i > svg {
					transform: scale(1.4, 1.4);
				}
			}
		}
		/* ----------- */
	}
}

.diceBtn {
	&:not(:last-of-type) {
		margin-right: 1px;
	}
	outline: none;
	border: none;
	flex: 1;
	padding: 5px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 0px;
	cursor: pointer;
	user-select: none;
	> label {
		pointer-events: none;
		opacity: 0.5;
		text-align: center;
		white-space: nowrap;
	}
	> b {
		pointer-events: none;
		display: block;
		text-align: center;
		font-size: 18px;
	}

	position: relative;
	transition: background-color 0.15s ease-in-out;
	&.off {
		display: none;
	}
	@include themify($themes) {
		background-color: themed("diceButtonBackgroundColor");
		color: themed("diceButtonColor");
		&.red {
			background-color: themed("diceButtonRedBackgroundColor");
			color: themed("diceButtonRedColor");
		}
		&.yellow {
			background-color: themed("diceButtonYellowBackgroundColor");
			color: themed("diceButtonYellowColor");
		}
		&.grey {
			background-color: themed("diceButtonGreyBackgroundColor");
			color: themed("diceButtonGreyColor");
		}
		&.dark {
			background-color: themed("diceButtonDarkBackgroundColor");
			color: themed("diceButtonDarkColor");
		}
		&.lightgrey {
			background-color: themed("diceButtonLightgreyBackgroundColor");
			color: themed("diceButtonLightgreyColor");
		}
		&.green {
			background-color: themed("diceButtonGreenBackgroundColor");
			color: themed("diceButtonGreenColor");
		}
	}
	@include themify($themes) {
		border-bottom: themed("diceButtonBottomBorder");
	}
	&.info {
		border-top: 4px solid rgba(255, 255, 255, 0.04);
		border-bottom: 0px solid rgba(255, 255, 255, 0);
		cursor: default;
		@include themify($themes) {
			background-color: themed("diceButtonInfoBackgroundColor");
		}
	}
	@media (hover: hover) {
		&:hover:not(:active):not(.info):not(.toggled) {
			@include themify($themes) {
				background-color: themed("diceButtonBackgroundColorHover");
				color: themed("diceButtonColorHover");
				&.red {
					background-color: themed("diceButtonRedBackgroundColorHover");
					color: themed("diceButtonRedColorHover");
				}
				&.yellow {
					background-color: themed("diceButtonYellowBackgroundColorHover");
					color: themed("diceButtonYellowColorHover");
				}
				&.grey {
					background-color: themed("diceButtonGreyBackgroundColorHover");
					color: themed("diceButtonGreyColorHover");
				}
				&.dark {
					background-color: themed("diceButtonDarkBackgroundColorHover");
					color: themed("diceButtonDarkColorHover");
				}
				&.lightgrey {
					background-color: themed("diceButtonLightgreyBackgroundColorHover");
					color: themed("diceButtonLightgreyColorHover");
				}
				&.green {
					background-color: themed("diceButtonGreenBackgroundColorHover");
					color: themed("diceButtonGreenColorHover");
				}
			}
		}
	}
	&:active:not(.info),
	&.toggled {
		@include themify($themes) {
			border-bottom: themed("diceButtonBottomBorderToggled");
		}
		border-top: 2px solid rgba(0, 0, 0, 0);
		@include themify($themes) {
			background-color: themed("diceButtonBackgroundColorActive");
			color: themed("diceButtonColorActive");
			&.red {
				background-color: themed("diceButtonRedBackgroundColorActive");
				color: themed("diceButtonRedColorActive");
			}
			&.yellow {
				background-color: themed("diceButtonYellowBackgroundColorActive");
				color: themed("diceButtonYellowColorActive");
			}
			&.grey {
				background-color: themed("diceButtonGreyBackgroundColorActive");
				color: themed("diceButtonGreyColorActive");
			}
			&.dark {
				background-color: themed("diceButtonDarkBackgroundColorActive");
				color: themed("diceButtonDarkColorActive");
			}
			&.lightgrey {
				background-color: themed("diceButtonLightgreyBackgroundColorActive");
				color: themed("diceButtonLightgreyColorActive");
			}
			&.green {
				background-color: themed("diceButtonGreenBackgroundColorActive");
				color: themed("diceButtonGreenColorActive");
			}
		}
	}
	> .mark {
		position: absolute;
		top: 0;
		right: 0;
		width: 16px;
		height: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include themify($themes) {
			color: themed("diceButtonMarkColor");
		}
		i,
		svg {
			display: flex;
		}
	}
}
