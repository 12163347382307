.formGeneral {
	display: flex;
	flex-direction: column;
	padding: 5px;
	border-radius: 5px;
	@include themify($themes) {
		background-color: themed("formBackgroundColor");
		color: themed("formColor");
	}
	> main,
	> footer {
		display: flex;
		flex-wrap: wrap;
	}
	> footer {
		justify-content: flex-end;
		border-radius: 5px;
		margin: 5px 2px 0px 2px;
		box-sizing: border-box;
		padding: 3px;
		@include themify($themes) {
			background-color: themed("formFooterBackgroundColor");
		}
	}
	> main > .item > .itemTip {
		position: absolute;
		box-sizing: border-box;
		z-index: 1;
		top: 0;
		min-width: 100%;
		display: none;
		pointer-events: none;
		> .itemTipContent {
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: lightcyan;
			font-family: "Roboto Mono";
			color: black;
			font-weight: 600;
			padding: 3px;
			box-sizing: border-box;
			font-size: 10px;
			border-radius: 3px;
			user-select: none;
			pointer-events: none;
		}
	}
	@media (hover: hover) {
		> main > .item:hover .itemTip {
			display: flex;
		}
	}
	> main > .item,
	> footer > .item {
		margin: 2px;
		position: relative;
		flex: 1;
		&.button,
		&.toggle {
			flex: none;
		}
		display: flex;
		justify-content: center;
		flex-direction: column;
		> .required {
			cursor: pointer;
			position: absolute;
			@include themify($themes) {
				color: themed("alert");
			}
			z-index: 1;
			right: 0;
			top: 18px;
			> * {
				user-select: none;
				pointer-events: none;
			}
		}
		> label {
			margin-bottom: 3px;
			font-family: "Roboto Mono";
			white-space: nowrap;
		}
		> [type="input"] {
			width: 100%;
			font-family: "Roboto Mono";
		}
	}
}
