.statsMgr {
	> header,
	> main,
	> footer,
	> .formGeneral {
		margin-bottom: 10px;
	}
	> header {
		> h2 {
			font-weight: bold;
			margin-bottom: 5px;
		}
	}
	> .stats {
		flex-wrap: wrap;
		display: flex;
		width: 100%;
		> * {
			width: 100%;
			padding: 10px;
			border-radius: 5px;
			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
				color: themed("profileGeoColor");
			}
			box-sizing: border-box;
			margin: 5px;
		}
		> .stat {
			display: flex;
			flex: 1;
			align-items: center;
			flex-direction: column;
			> label {
				margin-bottom: 10px;
				font-size: 18px;
				align-self: flex-start;
			}
			> .datablock {
				display: flex;
				flex-direction: column;
				> .row {
					display: flex;
					> label {
						width: 150px;
					}
					> b {
						width: 120px;
						white-space: nowrap;
						&.red {
							@include themify($themes) {
								color: themed("alert");
							}
						}
						&.green {
							@include themify($themes) {
								color: themed("success");
							}
						}
						font-family: "Roboto Mono";
					}
					&:not(:last-child) {
						margin-bottom: 5px;
					}
					&.line {
						border-top: 1px solid;
						@include themify($themes) {
							border-color: themed("profileGeoColor");
						}
					}
				}
			}
			// &.diceRun,
			// &.pandoraRun,
			// &.slotsRun
			&.statRun {
				min-width: 90%;
				> .content {
					display: flex;
					flex-wrap: wrap;
				}
			}
		}
		#ostats_pie1,
		#ostats_pie2,
		#ostats_pie3 {
			width: 200px;
			height: 200px;
			display: flex;
			align-items: center;
			justify-content: center;
			.ct-label {
				font-size: 14px;
				fill: rgba(0, 0, 0, 1);
				color: rgba(0, 0, 0, 1);
				font-family: "Roboto Mono";
				font-weight: bold;
			}
		}
	}
}
.statsMgr {
	.stat.stat-daily {
		flex-basis: unset;
		.chartByUrl {
			.st_deposit {
				background-color: lawngreen;
				stroke: lawngreen;
			}
			.st_withdraw {
				background-color: red;
				stroke: red;
			}
			.st_wager,
			.st_uniquePlayers {
				background-color: rgb(0, 174, 139);
				stroke: rgb(0, 174, 139);
			}
			.st_exchange {
				background-color: rgb(238, 164, 130);
				stroke: rgb(238, 164, 130);
			}
			.st_exchCommission {
				background-color: rgb(238, 164, 130);
				stroke: rgb(238, 164, 130);
			}
			.st_activity {
				background-color: rgb(121, 73, 97);
				stroke: rgb(121, 73, 97);
			}
			.st_chatEN {
				background-color: rgb(159, 255, 161);
				stroke: rgb(159, 255, 161);
			}
			.st_chatRU {
				background-color: rgb(85, 182, 86);
				stroke: rgb(85, 182, 86);
			}
			.st_fortuneWheel {
				background-color: violet;
				stroke: violet;
			}
			.st_faucet {
				background-color: orange;
				stroke: orange;
			}
			.st_tip {
				background-color: blue;
				stroke: blue;
			}
			.st_rainSent {
				background-color: cyan;
				stroke: cyan;
			}
			.st_freespin {
				background-color: rgb(22, 72, 72);
				stroke: rgb(22, 72, 72);
			}
			.st_freespinAssign {
				background-color: rgb(0, 66, 66);
				stroke: rgb(0, 66, 66);
			}
		}
	}

	.uniqueVisitorsForm {
		background-color: rgba(255, 255, 255, 0.3);
		padding: 5px;
		display: flex;
		flex-direction: row;
		gap: 5px;
	}
	h3{
		margin-bottom: 10px;
	}
}
