.crashOutcomesLine {
  width: 100%;
  > .wrapper {
    width: 100%;
    @include themify($themes) {
      background-color: themed("diceOutline");
    }
    padding: 1px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > button {
      margin: 1px;
      border-radius: 5px;
      padding: 3px;
      font-weight: bold;
      min-width: 55px;
      flex:1;
      &.grey {
        opacity: 0.6;
      }
    }
  }
}
