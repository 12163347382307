.prompt {
	width: 300px;
	padding: 20px;
	box-sizing: border-box;
	.title {
		display: flex;
		align-items: center;
		> .icon {
			@include themify($themes) {
				color: themed("warn");
			}
		}
		h2 {
			font-weight: bold;
			margin-left: 10px;
		}
		margin-bottom: 10px;
	}

	button {
		width: 100%;
		padding: 0 10px;
		align-items: center;
		justify-content: center;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
	
	.row {
		display: flex;
		width: 100%;
		> button {
			margin-bottom: 0;
			&:first-child {
				margin-right: 5px;
			}
		}
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		> textarea {
			height: 100px;
		}
	}
	input {
		width: 100%;
		margin-bottom: 10px;
		text-align: center;
	}
}
