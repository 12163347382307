.faq {
  > *,
  > * > * {
    box-sizing: border-box;
  }
  display: flex;
	flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  > .faq_body {
    max-width: 1300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    line-height: 22px;
    @include themify($themes) {
      background-color: themed("profileGeoBackgroundColor");
      color: themed("profileGeoColor");
    }
    > p {
      background-color: unset;
      color: unset;
    }
    > .faq_menu {
      @include themify($themes) {
        background-color: themed("profileGeoBackgroundColor");
        color: themed("profileGeoColor");
      }
      transition: padding-top 0.1s linear;
      width: 200px;
      display: flex;
      flex-direction: column;
      > .btnDefault {
        border-radius: 0px;
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 3px;
        }
        &._h2 {
          border-left-width: 3px;
          border-left-style: solid;
          @include themify($themes) {
            border-left-color: themed("btnIconColor");
          }
          margin-left: 5px;
          width: calc(100% - 5px);
        }
        > b {
          width: 100%;
          text-align: left;
        }
      }
    }
    > .faq_content {
			h1 {
				line-height: 25px;
			}
			img {
				max-width: 93%;
			}
      flex: 1;
      padding: 10px;
      ul {
        list-style: unset;
        li {
          list-style-position: inside;
        }
      }
    }
  }
}
