.uploader {
	$w: 170px;
	$h: 170px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: $w;
	height: $h;
	padding: 5px 5px;
	&.off {
		pointer-events: none;
		> input,
		> label {
			display: none;
		}
	}
	> .preview {
		position: absolute;
		z-index: 1;
		border-radius: 5px;
		width: $w;
		height: $h;
		pointer-events: none;
		background-position: center;
		background-size: contain;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	> input {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		opacity: 0;
		cursor: pointer;
	}
	> label {
		position: relative;
		z-index: 1;
		padding-right: 0;
		padding-left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;
		flex-direction: column;
		> .progress {
			margin-bottom: 5px;
		}
	}
}
