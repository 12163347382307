.balance {
	position: relative;
	> .multiSelect > header > .items > button.btnDefault {
		background-color: transparent;
	}
	li .item {
		font-family: "Roboto Mono";
		label {
			width: 40px;
			flex: unset !important;
		}
		> b {
			flex: 1;
			text-align: right;
		}
	}
	.otherContentTop {
		display: flex;
		.balanceTab,
		.cashTab,
		.bankTab {
			flex: 1;
			> button {
				width: 0;
				padding: 0 2px;
			}
		}
	}
	.otherContent {
		// @include themify($themes) {
		// 	background-color: themed("profilePlayerBackgroundColor");
		// }
		// box-sizing: border-box;
		// padding: 3px;
		// border-radius: 3px;
		display: flex;
		flex-direction: column;
		margin-top: 3px;
		> *:not(:last-child) {
			margin-bottom: 3px;
		}
		> .progress {
			width: 100%;
			border-radius: 3px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			font-family: "Roboto Mono";
			box-sizing: border-box;
			padding: 3px 7px;
			> .vvv {
				display: flex;
				flex-direction: column;
				gap: 3px;
				> b {
					// @include themify($themes) {
					// 	color: themed("warn");
					// }
					font-family: "Roboto Mono";
					font-weight: 700;
					font-size: 12px;
					&.gold {
						color: gold;
					}
					&.pending {
						background-color: yellow;
						color: black;
						border-radius: 5px;
					}
				}
			}
			> .iconArrow {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		> .onlyWithBalance,
		> .inUsd {
			display: flex;
			flex: 1;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 0 7px;
			> b {
				font-size: 13px;
			}
		}
		> .diceBtn {
			background-color: rgba(255, 255, 255, 0.1);
		}
	}
	> .rateSlider {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		height: 1px;
		box-sizing: border-box;
		> .fill {
			box-sizing: border-box;
			height: 1px;

			@include themify($themes) {
				background-color: themed("_cyan");
			}
		}
	}
}
