.BattleCreate {
	margin-top: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;
	gap: 10px;
	> h2 {
		font-size: 22px;
		font-weight: bold;
	}

	> .frmBattleCreate {
		flex: 1;
		width: 300px;
		> main {
			flex-wrap: nowrap;
			flex-direction: column;
			.multiSelect.gameSelect {
				li .item {
					gap: 5px;
					font-size: 14px;
					img {
						height: 50px !important;
						margin-left: -5px !important;
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
					}
				}
			}
		}
		> footer {
			align-items: center;
			justify-content: center;
			> .item > button {
				flex-direction: row;
				gap: 5px;
				> label {
					font-weight: bold;
					font-size: 18px;
				}
			}
		}
	}
}
