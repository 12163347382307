.shortcutModal {
	width: 300px;
	box-sizing: border-box;
	padding: 10px;
	> * {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
	> h2 {
		height: 30px;
		font-size: 22px;
		font-weight: bold;
		display: flex;
		align-items: center;
	}
	> header {
		width: 100%;
		display: flex;
		> .btnAdd {
			width: 50px;
			margin-left: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	> main {
		&.myList {
			height: 300px;
			li > .entry {
				margin-bottom: 3px;
				width: 100%;
				margin-right: 20px;
				display: flex;
				align-items: center;
				> .btnItem {
					flex: 1;
				}
				> .btnRemove {
					width: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 5px;
				}
			}
		}
	}
}
