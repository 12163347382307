.multiSelect {
	position: relative;
	// width: 200px;
	@mixin item {
		> .item {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			pointer-events: none;
			> img {
				margin-left: 5px;
				height: 24px;
				pointer-events: none;
			}
			> .digitScreen {
				margin-left: 5px;
			}
			> b {
				display: block;
				margin: 0 5px;
				pointer-events: none;
			}
			> label {
				flex: 1;
				display: block;
				pointer-events: none;
				> b {
					@include themify($themes) {
						background-color: themed("selectFoundLettersBackgroundColor");
						color: themed("selectFoundLettersColor");
					}
				}
				&.r {
					margin-right: 5px;
					text-align: right;
				}
				&.l {
					margin: 0 5px;
					text-align: left;
				}
			}
		}
	}
	&.opened {
		> .bodyFix > .body {
			// max-height: 301px;
			padding: 5px 5px;
			box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
		}
		> header > .toggle > i > svg {
			transform: rotate(0deg);
		}
	}
	&.closed {
		> .bodyFix > .body {
			padding: 0px 5px;
			max-height: 0px;
		}
		> header > .toggle > i > svg {
			transform: rotate(180deg);
		}
	}
	> header {
		> .headerLabel {
			position: absolute;
			bottom: 0;
			height: 10px;
			font-size: 10px;
			left: 0;
			right: 0;
			pointer-events: none;
			user-select: none;
			display: flex;
			justify-content: center;
			background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.5), transparent);
		}
		cursor: pointer;
		padding: 5px;
		min-height: 48px;
		box-sizing: border-box;
		position: relative;
		display: flex;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		@include themify($themes) {
			background-color: themed("selectHeaderBackgroundColor");
			color: themed("selectHeaderColor");
		}
		> .items {
			flex: 1;
			display: flex;
			flex-wrap: wrap;
			> label {
				margin: auto 0;
				user-select: none;
				opacity: 0.8;
			}
			> button {
				margin: 2px;
				min-height: 34px;
				flex: 1;
				> label {
					padding: 0;
				}
				@include item;
			}
			// &.single > button {
			//   flex: 1;
			// }
		}
		> button.toggle {
			width: 34px;
			height: 34px;
			min-height: 34px;
			margin: auto 2px;
			background-color: transparent;
			> i > svg {
				transition: transform 0.3s ease-in-out;
			}
		}
	}
	//-----------
	> .bodyFix {
		position: absolute;
		bottom: 0;
		min-width: 100%;
		z-index: 2;
	}
	> .bodyFix > .body {
		transition: max-height 0.15s ease-in-out, padding 0.15s ease-in-out;
		overflow-y: hidden;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		@include themify($themes) {
			background-color: themed("selectBackgroundColor");
			color: themed("selectColor");
		}
		position: absolute;
		top: 0;
		min-width: 100%;
		padding: 5px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		@include themify($themes) {
			background-color: themed("selectBackgroundColor");
			color: themed("selectColor");
		}
		> .paneInput {
			margin-bottom: 5px;
			display: flex;
			position: relative;
			> input {
				flex: 1;
			}
			> button {
				position: absolute;
				z-index: 2;
				right: 6px;
				top: 6px;
				margin: auto;
				width: 24px;
				height: 24px;
				min-height: auto;
				transition: opacity 0.3s ease-in-out;
				&.off {
					opacity: 0.5;
					transform: scale(0.7);
				}
			}
		}
		> .paneList {
			height: 250px;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			> .listScroll {
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
				> ul > li {
					button {
						margin: 3px 0;
						padding-right: 15px;
						width: 100%;
						&:not(.selected):not(:active):not(:hover) {
							@include themify($themes) {
								background-color: themed("selectItemBackgroundColor");
								color: themed("selectItemColor");
							}
						}
						@include item;
					}
				}
			}
		}
	}
}
