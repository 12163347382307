.depositMgr {
  font-family: "Roboto Mono" !important;
  white-space: nowrap;
  > *,
  > * > * {
    font-family: "Roboto Mono" !important;
    white-space: nowrap;
    font-size: 15px;
  }
  .success {
    @include themify($themes) {
      color: themed("success");
    }
  }
  .alert {
    @include themify($themes) {
      color: themed("alert");
    }
  }
  .warn {
    @include themify($themes) {
      color: themed("warn");
    }
  }
  .amountUsd {
    font-size: 22px;
  }
  .status {
    width: 16px;
    height: 16px;
    > .status {
      border-radius: 50%;
    }
    box-sizing: border-box;
    border: 1px solid #333;
    &.success {
      @include themify($themes) {
        background-color: themed("success");
      }
    }
    &.alert {
      @include themify($themes) {
        background-color: themed("alert");
      }
    }
    &.warn {
      @include themify($themes) {
        background-color: themed("warn");
      }
    }
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    > *:not(:last-child) {
      margin-bottom: 3px;
    }
    > button > img {
      width: 24px;
      height: 24px;
    }
    &.info {
      > div {
        display: flex;
        align-items: center;
        > .btnDefault {
          min-height: 20px !important;
          padding: 0px !important;
          width: 50px;
          margin-right: 5px;
        }
        > b {
          margin-right: 5px;
        }
      }
    }
  }
}
