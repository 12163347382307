.FBI {
	padding: 10px;
	padding-right: 45px;
	.fbiSection.off {
		display: none;
	}
	> header {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 10px;
		> button {
			padding: 0 10px;
			margin: 2px;
			font-weight: bold;
			font-size: 20px;
			flex: 1;
			align-items: center;
			justify-content: center;
			white-space: nowrap;
			> label {
				text-align: center;
				margin-left: 5px;
			}
		}
		> .fbiIcon {
			margin-right: 10px;
		}
	}
	> figure {
		display: flex;
		flex-direction: column;
		margin-bottom: 10px;
		border: 1px solid rgba(55, 90, 102, 0.5);

		@include themify($themes) {
			background-color: themed("fbiBackgroundColor");
		}
		border-radius: 5px;
		box-sizing: border-box;
		padding: 5px;

		width: 100%;
		h2 {
			font-size: 16px;
		}
		> h2 {
			margin-bottom: 5px;
		}
		> .selectPlayer {
			&.off {
				pointer-events: none;
				user-select: none;
				opacity: 0.3;
			}
			width: 100%;
			display: flex;
			align-items: center;
			.btnPlayerClick {
				margin-left: 10px;
				// padding: 0 10px;
			}
		}
		> .form {
			margin-bottom: 5px;
			display: flex;
			flex-wrap: wrap;
			padding: 5px;
			border-radius: 5px;
			> button {
				padding: 0 5px;
			}
			@include themify($themes) {
				background-color: themed("formEditBackgroundColor");
				color: themed("formEditColor");
			}
			> * {
				margin: 2.5px;
			}
			> label {
				display: flex;
				align-items: center;
			}
		}
	}
	.btnDefault:not(.toggleButton) {
		padding: 0 10px;
	}
	//-------
	.fSelectPlayer {
		> .header {
			display: flex;
			align-items: center;
			min-height: 38px;
			> button {
				margin-left: 10px;
				align-items: center;
				> label {
					margin-left: 5px;
				}
			}
			margin-bottom: 10px;
		}
	}
	//-------
	.fbiPermission {
		> .header {
			display: flex;
			align-items: center;
			> .btnNew {
				margin-left: 10px;
				padding: 0 5px;
			}
			margin-bottom: 10px;
		}
	}
	//------------
	.security {
		width: 100%;
		> .blocks {
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			> .block {
				display: flex;
				align-items: center;
				margin-right: 10px;
				margin-bottom: 10px;
				&.pw {
					display: flex;
					align-items: center;
					> label {
						margin-right: 5px;
					}
				}
			}
		}
	}
	.actionsData {
		> label {
			margin-bottom: 10px;
		}
		&.balance {
			> h3 {
				margin-bottom: 5px;
				font-weight: bold;
			}
			> div {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				> button {
					flex: 1;
					margin: 2px;
					display: flex;
					flex-direction: row;
					> b {
						font-size: 12px;
						margin-left: 5px;
					}
				}
			}
		}
		.togglers {
			display: flex;
			align-items: center;
			margin-bottom: 5px;
			> * {
				flex: 1;
				min-width: 35px;
				&._off {
					opacity: 0.5;
				}
			}
			flex-wrap: wrap;
			button > i {
				pointer-events: none;
			}
		}
	}
	.generalInfo {
		width: 100%;
		> .elements {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			> .photo {
				margin-right: 10px;
				> img {
					border-radius: 50%;
					border: 2px solid;
					box-sizing: border-box;
					width: 48px;
					height: 48px;
				}
			}
			> .blocksWrapper {
				display: flex;
				flex-direction: column;
				width: 100%;
				align-items: center;
				> .blocks {
					&:not(:last-child) {
						margin-bottom: 5px;
					}
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					> * {
						margin: 3px;
						> b:first-child {
							opacity: 0.8;
						}
						> b:not(:first-child) {
							margin-left: 5px;
							font-weight: bold;
							word-break: break-all;
							&.red {
								@include themify($themes) {
									color: themed("alert");
								}
							}
							&.green {
								@include themify($themes) {
									color: themed("success");
								}
							}
						}
					}
				}
			}
		}
	}
}
