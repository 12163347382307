.withdrawsMgr {
  font-family: "Roboto Mono" !important;
  white-space: nowrap;
  > *,
  > * > * {
    font-family: "Roboto Mono" !important;
    white-space: nowrap;
    font-size: 15px;
    outline: none;
  }
  .processedChk {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .success {
    @include themify($themes) {
      color: themed("success");
    }
  }
  .alert {
    @include themify($themes) {
      color: themed("alert");
    }
  }
  .warn {
    @include themify($themes) {
      color: themed("warn");
    }
  }
  ._grey {
    @include themify($themes) {
      color: themed("_grey");
    }
  }
  ._cyan {
    @include themify($themes) {
      color: themed("_cyan");
    }
  }
  .player > .wrapper > .btnDefault {
    padding: 0px 0px;
    min-width: 150px;
    width: 100%;
    > img {
      width: 24px;
      height: 24px;
    }
  }
  .player > .wrapper,
  .amount > .wrapper,
  .status > .wrapper,
  .balance > .wrapper,
  .ratios > .wrapper,
  .info > .wrapper,
  .time > .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    > *:not(:last-child) {
      margin-bottom: 3px;
    }
  }
  .amount > .wrapper > b:last-child,
  .status > .wrapper > b {
    font-size: 22px;
    font-weight: bold;
  }
  .status > .wrapper {
    > .message {
      font-size: 10px;
      word-break: break-all;
      white-space: break-spaces;
      text-align: left;
    }
    > button {
      > b:first-child {
        margin-right: 5px;
        font-weight: bold;
      }
    }
  }
  .amount > .wrapper > button {
    > b:first-child {
      font-weight: bold;
    }
    width: 100%;
  }
  .info > .wrapper {
    > .btnComment {
      min-height: unset !important;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    > div {
      display: flex;
      button {
        width: 45px;
        padding: unset !important;
        min-height: unset !important;
        margin-right: 5px;
      }
    }
  }
  .btn > .actions {
    position: absolute;
    margin-top: 18px;
    width: 80px;
    > .btnHeader {
      padding: 10px 10px;
    }
    .buttons {
      > * {
        width: 100%;
        min-width: 150px;
        border-radius: 0px;
        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }
  }
}
