.vipMgr {
	gap: 10px;
	>header,
	>main,
	>footer,
	>.formGeneral {
		margin-bottom: 10px;
	}

	>header {
		>h2 {
			font-weight: bold;
			margin-bottom: 5px;
		}
	}
}