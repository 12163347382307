.slotMgr {
  > *:not(:last-child),
  > header > h2 {
    margin-bottom: 10px;
  }
  .table {
    .act {
      > .wrapper {
        display: flex;
        > button {
          height: unset;
          padding: unset;
          &:last-child {
            margin-left: 3px;
          }
        }
      }
    }
    .uuid {
      button {
        width: 100%;
        font-family: "Roboto Mono";
      }
    }
  }
}
