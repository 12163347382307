$slotsGameOffset: 0px;
$slotsH3Height: 40px;

.slotTabs2 {
	position: sticky;
	top: 50px;
	z-index: 2;
	width: unset;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 1310px;
}

h2.h2games {
	font-size: 22px;
	font-weight: bold;
	margin-top: 10px;
}

.bodyPane.right {
	&.chatOn .slots>.gameFrame:not(.expanded) {
		right: $slotsGameOffset;
		left: $chatPaneWidth + $slotsGameOffset;
	}

	&.chatOff .slots>.gameFrame:not(.expanded) {
		right: $slotsGameOffset;
		left: $slotsGameOffset;
	}

	&.chatOn .slotTabs2 {
		left: $chatPaneWidth;
		right: 0;
	}
}

.bodyPane.left {
	&.chatOn .slots>.gameFrame:not(.expanded) {
		left: $slotsGameOffset;
		right: $chatPaneWidth + $slotsGameOffset;
	}

	&.chatOff .slots>.gameFrame:not(.expanded) {
		left: $slotsGameOffset;
		right: $slotsGameOffset;
	}

	&.chatOn .slotTabs2 {
		left: 0;
		right: $chatPaneWidth;
	}
}

.slots .slotSearch {
	width: 100%;
	display: flex;
	align-items: center;

	>.inputGameSearch {
		width: unset;
		flex: 1;
		margin-left: 5px;
		height: 48px;
		box-shadow: unset;
		background-color: transparent;
		border-radius: 0;
	}

	>.inpGameSearch {
		height: 48px;
		box-shadow: unset;
		margin-left: 3px;
	}

	@include themify($themes) {
		background-color: themed("containerBackgroundColor");
		color: themed("containerColor");
	}
}

.slots {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 0px 0 70px;
	position: relative;
	margin-top: 70px;

	// .tab {
	// 	&:not(.favouriteTab) {
	// 		min-width: 170px;
	// 	}
	// }
	.favouriteTab {
		width: 48px;
		flex: unset;
	}

	>.gameFrame {
		position: fixed;
		transition: transform 0.3s ease;

		&.off {
			transform: scale(0);
			pointer-events: none;
		}

		&.on {
			transform: scale(1);
			pointer-events: auto;
		}

		&:not(.expanded) {
			top: $headerHeight + $slotsGameOffset;
			bottom: $slotsGameOffset;
			z-index: 2;
			padding: 0px; //3px;
			border-radius: 5px;
		}

		&.expanded {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 10;
			padding: 0px;
			border-radius: 0px;
		}

		@include themify($themes) {
			background-color: themed("formBackgroundColor");
			color: themed("formColor");
		}

		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		&.on>.btns {
			display: flex;
		}

		&.off>.btns {
			display: none;
		}

		>.btns {
			padding: 2px;
			background-color: rgba(0, 0, 0, 0.5);
			border-radius: 5px;
			position: absolute;
			z-index: 20;
			right: 10px;
			top: 10px;

			button {
				background-color: rgba(255, 255, 255, 0.1) !important;

				>i {
					padding: 0 !important;
				}
			}

			align-items: center;

			>*:not(:last-child) {
				margin-right: 5px;
			}
		}

		>iframe {
			border: none;
			width: 100%;
			height: 100%;

			&.on {
				pointer-events: auto;
			}

			&.off {
				pointer-events: none;
			}
		}
	}

	>.wrapper {
		width: 100%;
		box-sizing: border-box;
		max-width: 1310px;

		// max-width: calc(100% - 40px);
		>.games {
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			padding: 2.5px;
		}
	}
}

.games a {
	flex: 1;
	max-width: 270px;
	padding: 0;
}

.slotGameSlide {
	position: relative;

	>.sticker {
		position: absolute;
		z-index: 1;
		font-weight: bold;
		font-size: 10px;
		left: 0px;
		top: 0px;
		padding: 5px;
		box-sizing: border-box;
		border-bottom-right-radius: 8px;
	}

	transition: transform 0.2s ease-in-out;

	&.off {
		transform: scale(0);
	}

	&.on {
		transform: scale(1);
	}

	border-radius: 5px;
	box-sizing: border-box;
	flex: 1;

	&.chatOn {
		@media (min-width: ($chatPaneWidth + 599px)) {
			min-width: 160px;
		}

		@media (max-width: ($chatPaneWidth + 599px)) {
			min-width: 130px;
		}
	}

	&.chatOff {
		@media (min-width: 599px) {
			min-width: 160px;
		}

		@media (max-width: 599px) {
			min-width: 130px;
		}
	}

	max-width: 270px;
	margin: 12px;
	overflow: hidden;
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			>.bg {
				transform: scale(1.1);
				// filter: brightness(1.2) contrast(1.1);
				filter: saturate(50%) brightness(0.5);
			}

			>.content>.buttons {
				pointer-events: auto;
			}
		}

		&:not(:hover) {
			>.bg {
				transform: scale(1);
				// filter: brightness(1) contrast(1);
				filter: saturate(100%) brightness(1);
			}

			>.content>.buttons {
				pointer-events: none;

				>.btnPlaySvg {
					opacity: 0;
					transform: scale(1.75);
				}
			}
		}
	}

	>.bg {
		transform-origin: center;
		transition: transform 0.3s ease, filter 0.3s ease;
		width: 100%;
		position: relative;
		// background-size: 100% calc(100% - #{$slotsH3Height}); //strech
		// background-repeat: no-repeat;
		// height: 0;
		padding-bottom: calc(72% + #{$slotsH3Height});

		// height: calc(100% - #{$slotsH3Height});
		// padding-bottom: $slotsH3Height;
		>.imgWrapper {
			transition: all 0.3s ease;
			width: 100%;
			height: calc(100% - #{$slotsH3Height});
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			&:not(.loaded) {
				@include animPlaceholderShimmerInf;
			}

			>img {
				width: 100%;
				height: 100%;
				opacity: 0;
				transition: opacity 1s ease-in-out;

				&.loaded {
					opacity: 1;
				}
			}
		}
	}

	>.content {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		>.buttons {
			transition: all 0.2s ease-in-out;
			position: absolute;
			bottom: $slotsH3Height;
			left: 0;
			top: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			>.btnPlaySvg {
				fill: rgba(245, 246, 247, 0.9);
				width: 3rem;
				height: 3rem;
				position: absolute;
				left: 50%;
				top: 50%;
				margin-left: -1.5rem;
				margin-top: -1.5rem;
				transform: scale(1.15);
				transition: transform 0.5s, opacity 1s;
			}

			>.btnPlay {
				&.real {
					margin-bottom: 5px;
				}

				transition: all 0.2s ease-in-out;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgba(0, 0, 0, 0.8);
				border-radius: 20px;
				color: white;
				font-size: 18px;
				height: 40px;
				width: 150px;
				border: 3px solid rgba(255, 255, 255, 0.5);
				user-select: none;
				cursor: pointer;

				@media (hover: hover) {
					&:hover {
						color: rgba(255, 255, 255, 1);
					}

					&:not(:hover) {
						color: rgba(255, 255, 255, 0.7);
					}
				}
			}
		}

		>h3 {
			box-sizing: border-box;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: $slotsH3Height;
			display: flex;
			align-items: center;

			@include themify($themes) {
				background-color: themed("inputBackgroundColor");
			}

			>.btnIcon {
				@include themify($themes) {
					color: themed("cgold");
				}

				background-color: transparent;
			}

			>b {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;

				@include themify($themes) {
					color: themed("inputColor");
				}

				line-height: 15px;
				font-weight: 500;
				font-size: 13.5px;
				text-align: center;
				user-select: none;
			}
		}

		>.providerCap {
			position: absolute;
			top: 0;
			right: 0;
			min-height: 20px;
			background-color: rgba(0, 0, 0, 0.4);
			display: flex;
			align-items: center;
			font-size: 10px;
			padding: 0 5px;
			border-bottom-left-radius: 10px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			gap: 3px;

			.red {
				color: red;
				font-weight: bold;
			}

			.green {
				color: lime;
				font-weight: bold;
			}
		}
	}

	&.mobile {
		border: 1px solid rgba(255, 255, 255, 0.1);

		>.bg {
			padding-bottom: calc(72% + #{$slotsH3Height * 1});

			>img {
				height: calc(100% - #{$slotsH3Height * 1});
			}
		}

		// > .content {
		// 	> .buttons {
		// 		top: unset;
		// 		flex-direction: row;
		// 		> button {
		// 			border-radius: 0;
		// 			margin: 0 !important;
		// 			border: none;
		// 			@include themify($themes) {
		// 				background-color: themed("inputBackgroundColor");
		// 			}
		// 			&:first-of-type {
		// 				border-right: none;
		// 			}
		// 		}
		// 	}
		// }
	}
}

.slots>.showMore {
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		text-decoration: none !important;
	}

	button {
		flex-direction: row;
		border-radius: 5px;
		padding: 5px 10px;

		>b:first-of-type {
			margin-left: 5px;
		}
	}
}