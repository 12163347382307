.chatRooms {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: $chatLocationPadding;
	> section {
		padding: $chatLocationPadding;
		height: 0; //without this overflows
		overflow: hidden;
		@include themify($themes) {
			background-color: themed("shade005");
		}
		&.standard {
			flex: 2;
		}
		&.vip {
			flex: 1;
		}
		> h3 {
			margin-bottom: 5px;
			opacity: 0.6;
		}
		&:not(:last-child) {
			margin-bottom: $chatLocationPadding;
		}
		> .listScroll {
			button {
				width: 100%;
				padding: 5px;
				margin-bottom: 3px;
				display: flex;
				flex-direction: row;
				align-items: center;
				> .entry {
					flex: 1;
					display: flex;
					> * {
						&:not(:last-child) {
							margin-right: 10px;
						}
					}
				}
				> .roomOnline {
					margin-right: 10px;
					font-family: "Roboto Mono";
				}
			}
		}
	}
}
