.LobbyGameSlider {
	display: flex;
	max-width: 1310px;
	width: calc(100% - 10px);
	box-sizing: border-box;
	padding-top: 25px;
	min-height: 50px;
	margin: 10px 0;
	&.c_big_wins > .wrapper {
		background-color: rgba(177, 79, 255, 0.31);
	}
	> .wrapper {
		position: relative;
		width: 100%;
		padding: 5px;
		padding-bottom: 15px;
		box-sizing: border-box;
		// background-image: linear-gradient(180deg, #5779c2, transparent);
		background-color: rgba(87, 121, 194, 0.278);
		border-radius: 10px;
		> .caption {
			scale: 0.7;
			transform-origin: top left;
			text-align: center;
			position: absolute;
			@include themify($themes) {
				color: themed("profilePlayerColor");
			}
			font-weight: bold;
			font-size: 16px;
			z-index: 1;
			padding: 5px;
			min-width: 150px;
			left: 0px;
			top: 0px;
			border-top-left-radius: 10px;
			border-bottom-right-radius: 15px;
			&.blue {
				background-color: rgba(40, 127, 184, 0.6);
			}
			&.cyan {
				background-color: rgba(0, 217, 255, 0.5);
			}
			&.limegreen {
				background-color: rgba(0, 255, 47, 0.5);
			}
			&.red {
				background-color: rgba(255, 0, 0, 0.5);
			}
			&.goldenrod {
				background-color: rgba(255, 213, 0, 0.5);
			}
			&.gold {
				background-color: rgba(230, 191, 0, 1);
			}
		}
		.splide__pagination {
			bottom: -0.7em;
		}
		// .splide__arrow {
		// 	top: calc(50% - 20px);
		// }
		.slotFrame {
			margin: 10px;
			padding: 10px;
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 10px;
			.slotGameSlide {
				max-width: unset;
				min-width: unset;
				margin: unset;
				> .content {
					> h3 {
						background-color: unset;
					}
				}
			}
		}
	}
}
