._2fa_management {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 280px;
  margin: auto;
  > .block {
    padding: 10px;
    @include themify($themes) {
      background-color: themed("profileGeoBackgroundColor");
      color: themed("profileGeoColor");
    }
    margin-bottom: 10px;
    > button,
    > input {
      width: 100%;
      display: block;
      text-align: center;
    }
    > label,
    input {
      margin-bottom: 5px;
      display: block;
    }
    &.anim {
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
    }
    &.imgWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.codeWrapper {
      > label {
        @include themify($themes) {
          background-color: themed("profileGeoLabelBackgroundColor");
          color: themed("profileGeoLabelColor");
        }
      }
      > button {
        margin-bottom: 5px;
        font-size: 11px;
        > label {
          word-break: break-all;
          width: 100%;
          user-select: none;
        }
      }
    }
  }
}
