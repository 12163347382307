.balanceChange {
	width: 300px;
	padding: 20px;
	box-sizing: border-box;
	> *:not(:last-child) {
		display: block;
		margin-bottom: 10px;
	}
	> h1 {
		font-size: 22px;
	}
	> label {
		display: flex;
		> b {
			font-weight: bold;
			font-size: 22px;
			margin-left: 5px;
			@include themify($themes) {
				color: themed("alert");
			}
		}
	}
	> .photo {
		display: flex;
		align-items: center;
		> img {
			border-radius: 50%;
			border: 2px solid;
			box-sizing: border-box;
			width: 48px;
			height: 48px;
		}
		> b {
			font-weight: bold;
			margin-left: 5px;
		}
	}
	> .curBalance {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
		box-sizing: border-box;
		> b {
			margin-left: 5px;
		}
	}
	> button {
		width: 100%;
		align-items: center;
		justify-content: center;
	}
}
