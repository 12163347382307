.BattleWrapper {
	box-sizing: border-box;
	display: flex;
	min-width: 300px;
	max-width: 400px;
	flex: 1;
	flex-direction: column;
	&.expired {
		opacity: 0.5;
	}
	> *:not(:last-child) {
		margin-bottom: 5px;
	}
	> .actions {
		z-index: 0;
		animation-delay: 0.3s;
		@include mixinSlideDownFadeIn;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		box-sizing: border-box;
		padding: 5px;
		display: flex;
		justify-content: center;
		gap: 5px;
		// background-color: hsl(149, 100%, 25%);
		background-color: rgb(255, 69, 0);
		&.running {
			background-color: green;
		}
		&.expired {
			background-color: #333;
		}

		// background-image: linear-gradient(#271a61, rgba(100, 219, 230, 0.8));
		.btnJoin,
		.btnGo {
			padding: 8px;
			font-size: 18px;
			font-weight: bold;
			color: hsl(120, 100%, 83%);
			background-color: transparent;
		}
	}
}

.Battle {
	cursor: pointer;
	z-index: 1;
	@include mixinScaleFadeIn;
	position: relative;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	flex: 1;
	padding: 10px;
	min-height: 180px;
	overflow: hidden;
	background-image: linear-gradient(rgba(100, 219, 230, 0.8), #271a61);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	> .bg {
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		right: 0;
		width: 220px;
		clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.f1 {
		flex: 1;
	}
	.row {
		display: flex;
		align-items: center;
		transform-origin: left;
		.mono {
			font-family: "Roboto Mono";
		}
		&:not(:last-child) {
			margin-bottom: 5px;
		}
		> *:not(:last-child) {
			margin-right: 10px;
		}
		&.timeLeft {
			user-select: none;
			background-color: #212352;
			padding: 5px;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			justify-content: center;
			font-size: 12px;
			line-height: 0;
			height: 10px;
		}
		> h4 {
			font-weight: bold;
			font-size: 17px;
		}
		> .item {
			display: flex;
			align-items: center;
			> * {
				line-height: 18px;
			}
			> .v {
				margin-left: 5px;
				font-size: 22px;
				font-weight: bold;
				text-shadow: 2px 2px 5px #000;
				pointer-events: none;
				user-select: none;
			}
		}
	}
	.battleLogo {
		width: 32px;
		height: 32px;
		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
}
