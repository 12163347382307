.playersMgr {
  font-family: "Roboto Mono" !important;
  white-space: nowrap;
  > *,
  > * > * {
    font-family: "Roboto Mono" !important;
    white-space: nowrap;
    font-size: 15px;
  }
  .success {
    @include themify($themes) {
      color: themed("success");
    }
    font-size: 16px;
  }
  .alert {
    @include themify($themes) {
      color: themed("alert");
    }
    font-size: 16px;
  }
  .warn {
    @include themify($themes) {
      color: themed("warn");
    }
  }
  .cyan {
    @include themify($themes) {
      color: themed("_cyan");
    }
  }
  .grey {
    opacity: 0.7;
  }
  .player .btnDefault {
    padding: 0;
    width: 100%;
  }
  .time .d {
    @include themify($themes) {
      color: themed("warn");
    }
    margin-right: 5px;
  }
  > .filters {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    > *:not(:last-child) {
      margin-bottom: 3px;
    }
    > button > img {
      width: 24px;
      height: 24px;
    }
    > div {
      display: flex;
      &.uid_lvl {
        flex-direction: row;
        > div {
          &:not(:last-child) {
            margin-right: 5px;
          }
          display: flex;
          align-items: center;
          > b:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
      align-items: center;
      > b:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}
