.button3d {
	$h: 30px;
	width: 100px;
	height: $h;
	perspective: 76em;
	> .cube {
		position: relative;
		width: 100%;
		height: 100%;
		transform-origin: 0 50%;
		transform-style: preserve-3d;
		transition: transform 0.3s ease-in;
		&.v0 {
			transform: rotateX(0deg);
		}
		&.v1 {
			transform: rotateX(-90deg);
		}
		> .content {
			width: 100%;
			height: 100%;
			position: absolute;
			&:nth-child(2) {
				transform: translateZ($h/2);
			}
			&:nth-child(1) {
				transform: rotateX(-270deg) translateY(-50%);
				transform-origin: top left;
			}
		}
	}
}
