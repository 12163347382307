.BannerRotator {
	$h: 300px;
	$capH: 100px;
	position: relative;
	width: 100%;
	@for $i from 1 through 10 {
		&.slide#{$i} > .wrapper > .bg > .pane1,
		&.slide#{$i} > .wrapper > .bg > .pane3 {
			top: -$h * ($i - 1);
		}
		&.slide#{$i} > .wrapper > .bg > .pane2 {
			bottom: -$h * ($i - 1);
		}
		&.slide#{$i} > .wrapper > .bg > .caption > .hWrapper {
			margin-top: -$capH * ($i - 1);
		}
	}
	&.hasLink {
		cursor: pointer;
	}
	> .wrapper {
		background-image: repeating-linear-gradient(
			45deg,
			rgba(255, 255, 255, 0.1),
			rgba(255, 255, 255, 0.1) 10px,
			rgba(0, 0, 0, 0.1) 10px,
			rgba(0, 0, 0, 0.1) 20px /* determines size */
		);
		> .bg {
			position: relative;
			height: $h;
			overflow: hidden;
			> .caption {
				width: 100%;
				position: absolute;
				z-index: 1;
				height: $capH;
				overflow-y: hidden;
				top: #{$h/2 - $capH/2 + $headerHeight};
				> .hWrapper {
					width: 100%;
					transition: margin-top 0.5s linear; //cubic-bezier(0.075, 0.82, 0.165, 1); //0.7, 0, 0.3, 1
					> h3 {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						height: $capH;
						font-size: 40px;
						font-weight: bold;
						color: white;
						// -webkit-text-stroke: 1px rgba(0, 0, 0, 0.5);
						text-align: center;
					}
				}
			}
			> .pane {
				position: absolute;
				width: 100%;
				> .img {
					height: $h;
					// background-size: cover;
					// background-position: 50% 30%;
					object-fit: cover;
					object-position: 50% 30%;
					width: 100%;
				}
				&.pane1 {
					left: 0;
					clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
					// clip-path: polygon(0% 0%, 33.4% 0%, 33.4% 100%, 0% 100%);
					transition: top 1s cubic-bezier(0.7, 0, 0.3, 1); //0.7, 0, 0.3, 1   //0.075, 0.82, 0.165, 1
				}
				&.pane2 {
					right: 1px;
					clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
					// clip-path: polygon(33.3% 0%, 66.7% 0%, 66.7% 100%, 33.3% 100%);
					transition: bottom 1s cubic-bezier(0.7, 0, 0.3, 1); //0.7, 0, 0.3, 1
				}
				&.pane3 {
					display: none;
				}
				// &.pane3 {
				// 	// right: 1px;
				// 	// clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
				// 	clip-path: polygon(66.6% 0%, 100% 0%, 100% 100%, 66.6% 100%);
				// 	transition: top 1s cubic-bezier(0.7, 0, 0.3, 1); //0.7, 0, 0.3, 1
				// }
			}
		}
	}
}
.chatOn {
	@media (max-width: ($chatPaneWidth + 600px)) {
		.BannerRotator > .wrapper > .bg > .caption > .hWrapper > h3 {
			font-size: 30px;
		}
	}
}
.chatOff {
	@media (max-width: 600px) {
		.BannerRotator > .wrapper > .bg > .caption > .hWrapper > h3 {
			font-size: 30px;
		}
	}
}
