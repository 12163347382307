.contestMgr {
	.important {
		@include themify($themes) {
			color: themed("alert");
		}
		font-size: 22px;
		margin-left: 5px;
	}
	> .wrapper {
		> .frm {
			margin-bottom: 10px;
			display: flex;
			flex-wrap: wrap;
			&.p100 {
				width: 100%;
				> .block {
					width: 100%;
				}
			}
			> .block {
				position: relative;
				margin: 2px;
				flex: 1;
				@media (hover: hover) {
					&:hover .itemTip {
						display: flex;
					}
				}
				&.cal {
					min-width: 190px;
				}
				> label {
					margin-bottom: 5px;
				}
				> .itemTip {
					position: absolute;
					box-sizing: border-box;
					z-index: 1;
					top: 0;
					min-width: 100%;
					display: none;
					pointer-events: none;
					> .itemTipContent {
						position: absolute;
						bottom: 0;
						left: 0;
						background-color: lightcyan;
						font-family: "Roboto Mono";
						color: black;
						font-weight: 600;
						padding: 3px;
						box-sizing: border-box;
						font-size: 10px;
						border-radius: 3px;
						user-select: none;
						pointer-events: none;
					}
				}
			}
		}
		> .buttons {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}
}
.contestlist {
	position: relative;
	display: flex;
	flex-direction: column;
	gap:10px;
}