.simpleDropDown {
	width: 100%;
	> button.btnHeader {
		height: auto;
		min-height: auto;
		padding: 0;
		margin: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		@media (hover: hover) {
			&:hover > .icon > i > svg {
				transform: scale(1.4);
			}
		}
		.icon {
			&.off > i > svg {
				transform: rotate(0deg);
			}
			&.on > i > svg {
				transform: rotate(180deg);
			}
			margin: 0 10px;
			> i {
				display: flex;
				align-items: center;
				justify-content: center;
				transform-origin: center center;
				> svg {
					transition: transform 0.3s linear;
					transform-origin: center center;
					transition: transform 0.25s ease;
				}
			}
		}
		> * {
			user-select: none;
			pointer-events: none;
		}
		// @include themify($themes) {
		// 	background-color: themed("menuAvatarBackgroundColor");
		// }
	}
	position: relative;
	&.down {
		> .dropList {
			bottom: 0px;
			> .dropWindow {
				top: 0;
				transform-origin: top;
				> .dropArrow {
					margin-top: 3px;
					border-width: 0 9px 9px 9px;
				}
			}
		}
	}
	&.up {
		> .dropList {
			top: 0px;
			> .dropWindow {
				bottom: 0;
				transform-origin: bottom;
				> .dropArrow {
					margin-bottom: 3px;
					border-width: 0 9px 9px 9px;
					transform: rotate(180deg);
				}
			}
		}
	}
	> .dropList {
		position: absolute;
		z-index: 10;
		width: 100%;
		left: 0px;
		height: 0px;
		> .dropWindow {
			position: absolute;
			left: 0;
			min-width: 100%;
			display: flex;
			flex-direction: column;
			transition: transform 0.15s ease-in-out;
			&.on {
				transform: scale(1, 1);
			}
			&.off {
				transform: scale(1, 0);
			}
			> .dropArrow {
				margin-left: 10px;

				width: 0;
				height: 0;
				border-style: solid;

				@include themify($themes) {
					border-color: themed("selectArrowColor");
				}
			}
			> .content {
				min-height: 25px;
				border-radius: 5px;
				box-sizing: border-box;
				padding: 5px;
				box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
				@include themify($themes) {
					background-color: themed("selectBackgroundColor");
					color: themed("selectColor");
				}
			}
		}
	}
}
.modalContent {
	> .buttons {
		> .btnDefault {
			width: 100%;
			&:not(:last-child) {
				margin-bottom: 3px;
			}
		}
	}
}
