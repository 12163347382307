.multiLangField {
	button.btnDefault.btnHeader {
		height: 39px;
	}
	.mHeader {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		> .status {
			margin-right: 5px;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			box-sizing: border-box;
			border: 1px solid #333;
			&.success {
				@include themify($themes) {
					background-color: themed("success");
				}
			}
			&.warn {
				@include themify($themes) {
					background-color: themed("warn");
				}
			}
		}
	}
	.entry {
		display: flex;
		align-items: center;
		&:not(:last-child) {
			margin-bottom: 3px;
		}
		> b {
			font-weight: bold;
			user-select: none;
		}
		> input {
			flex: 1;
			margin-left: 5px;
		}
	}
}
