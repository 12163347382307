.crashBets {
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
	margin-top: 5px;
	> .crashBet {
		width: 100%;
		box-sizing: border-box;
		padding: 0 10px;
		display: flex;
		max-width: 769px;
		height: 50px;
		margin-bottom: 3px;
		@include themify($themes) {
			background-color: themed("diceBackground");
		}
		> .col {
			> .diceBtn {
				width: 40px;
				height: 40px;
				flex: unset;
				border-radius: 5px;
			}
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			> .outcome {
				font-size: 25px;
				font-weight: bold;
				@include themify($themes) {
					&.green {
						color: themed("success");
					}
					&.red {
						color: themed("alert");
					}
				}
			}
			> .row {
				display: flex;
				> b > .z {
					opacity: 0.8;
				}
				> b > i:not(.z) {
					font-weight: bold;
				}
				> b:first-child {
					min-width: 70px;
					text-align: right;
					margin-right: 5px;
					font-weight: bold;
				}
				> b:last-child {
					min-width: 90px;
					text-align: left;
				}
				> b {
					&.bet,
					&.delta,
					&.profit {
						font-family: "Roboto Mono";
					}
				}
			}
			&.coin {
				flex: unset;
				width: 34px;
			}
		}
	}
}
