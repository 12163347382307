.Formula {
	> header,
	> main,
	> footer {
		margin-bottom: 10px;
	}
	> header {
		> h2 {
			font-weight: bold;
		}
	}
	> main {
		> .block {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
			display: flex;
			flex-direction: column;
			> .vars {
				display: flex;
				flex-wrap: wrap;
				> button {
					margin: 2px;
				}
			}
			> label {
				margin-bottom: 5px;
			}
			> textarea {
				min-height: 150px;
			}
			&.buttons {
				flex-direction: row;
				align-items: center;
				flex-wrap: wrap;
				> .space {
					flex: 1;
				}
				> button:last-child {
					margin-left: 5px;
				}
				> button {
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
				}
			}
			//params
			> .paramsInfo,
			> .vars {
				margin-bottom: 5px;
			}
			> .paramsInfo {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				> label {
					margin-right: 10px;
				}
				> div {
					display: flex;
					align-items: center;
					> * {
						margin-right: 5px;
					}
					> .warn,
					> .success {
						width: 16px;
						height: 16px;
						border-radius: 50%;
					}
					> .warn {
						@include themify($themes) {
							background-color: themed("warn");
						}
					}
					> .success {
						@include themify($themes) {
							background-color: themed("success");
						}
					}
				}
			}
		}
	}
}
