.profileLevel {
	box-sizing: border-box;
	> .wrapper {
		box-sizing: border-box;
		> .levels {
			box-sizing: border-box;
			padding: 10px;

			> .total {
				box-sizing: border-box;
				padding: 20px;
				@include themify($themes) {
					background-color: themed("profileGeoBackgroundColor");
					color: themed("profileGeoColor");
				}
				margin-bottom: 10px;
				display: flex;
				> .icon {
					margin-right: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				> .info {
					display: flex;
					flex-direction: column;
					> label {
						font-weight: 800;
						font-size: 22px;
						margin-bottom: 5px;
					}
					> b {
						&:not(:last-child) {
							margin-bottom: 5px;
						}
					}
				}
				> .flex1 {
					flex: 1;
				}
				> b {
				}
			}
			> .level {
				box-sizing: border-box;
				padding: 20px;
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				@include themify($themes) {
					background-color: themed("profileGeoBackgroundColor");
					color: themed("profileGeoColor");
				}
				> header {
					margin-bottom: 5px;
					display: flex;
					> label {
						font-weight: 800;
					}
					> .flex1 {
						flex: 1;
					}
				}
				> progress {
					position: relative;
					width: 100%;
					margin-bottom: 5px;
					height: 20px;
					display: block; /* default: inline-block */
					// padding: 4px;
					border: 0 none;
					// background: #444;
					border-radius: 5px;
					// box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.2);
					@include themify($themes) {
						background-color: themed("shade005");
						// &._green {
						// 	color: themed("progressGreenBackgroundColor");
						// }
						// &._red {
						// 	color: themed("progressRedBackgroundColor");
						// }
					}
					&:after {
						content: attr(data-p);
						position: absolute;
						top: 10px;
						left: 15px;
						z-index: 1;
						line-height: 0;
						font-weight: 500;
						mix-blend-mode: difference;
					}
				}

				@mixin progressColorsGreen {
					@include themify($themes) {
						background-color: themed("progressGreenBackgroundColor");
						color: themed("progressGreenColor");
					}
				}
				@mixin progressColorsRed {
					@include themify($themes) {
						background-color: themed("progressRedBackgroundColor");
						color: themed("progressRedColor");
					}
				}
				@mixin progressPColorGreen {
					@include themify($themes) {
						color: themed("progressGreenBackgroundColor");
					}
				}
				@mixin progressPColorRed {
					@include themify($themes) {
						color: themed("progressRedBackgroundColor");
					}
				}
				&._green {
					progress::-moz-progress-bar {
						border-radius: 5px;
						@include progressColorsGreen;
					}
					progress::-webkit-progress-value {
						border-radius: 5px;
						@include progressColorsGreen;
					}
					progress:after {
						@include progressPColorGreen;
					}
				}
				&._red {
					> header,
					> footer {
						@include themify($themes) {
							color: themed("tableRedColor");
						}
					}
					progress::-moz-progress-bar {
						border-radius: 5px;
						@include progressColorsRed;
					}
					progress::-webkit-progress-value {
						border-radius: 5px;
						@include progressColorsRed;
					}
					progress:after {
						@include progressPColorRed;
					}
				}
				progress::-webkit-progress-bar {
					background: transparent;
				}

				> footer {
				}
			}
		}
	}
}
