.diceMgr {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	border: 1px solid rgba(55, 90, 102, 0.5);

	@include themify($themes) {
		background-color: themed("fbiBackgroundColor");
	}
	border-radius: 5px;
	box-sizing: border-box;
	padding: 5px;

	width: 100%;
	> header {
		display: flex;
		> *:not(:last-child) {
			margin-right: 5px;
		}
		.btnRemove {
			flex: unset;
			width: 48px;
			border-radius: 5px;
		}
	}
	> .navButtons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-top: 1px;
	}
	.togglers {
		display: flex;
		align-items: center;
		margin-bottom: 5px;
		> * {
			flex: 1;
			min-width: 35px;
			&._off {
				opacity: 0.5;
			}
		}
		flex-wrap: wrap;
		button > i {
			pointer-events: none;
		}
	}
}
.JParams {
	padding: 10px;
	min-width: 300px;
	b {
		display: block;
	}
	> *:not(:last-child) {
		margin-bottom: 5px;
	}
	> div > b {
		display: block;
		margin-bottom: 5px;
	}
	> .btns {
		display: flex;
		align-items: center;
		justify-content: center;
		> .btnApplyJParams {
			padding: 5px;
			border-radius: 5px;
		}
	}
}
.diceMgr {
	> header {
		margin-bottom: 3px;
	}
	.btnJam {
		flex: unset;
		width: 50px;
		border-radius: 5px;
		font-weight: bold;
	}
	.btnJamParams {
		flex: unset;
		width: 70px;
		border-radius: 5px;
		flex-direction: column;
		> b {
			font-size: 10px;
		}
	}
}
.diceMgr .blocks {
	$h: 300px;
	position: relative;
	width: 100%;
	box-sizing: border-box;
	overflow-x: scroll;
	height: $h;
	font-family: "Roboto Mono";
	> .content {
		.jam {
			background-color: #572b33 !important;
		}
		.line {
			width: 100%;
			height: 1px;
			@include themify($themes) {
				background-color: themed("_grey");
			}
		}
		position: absolute;
		@include themify($themes) {
			background-color: themed("containerBackgroundColor");
			color: themed("containerColor");
		}
		height: calc(#{$h} - 20px);
		font-family: "Roboto Mono";
		font-size: 11px;
		> .b {
			&:nth-last-of-type(10n-9) {
				background-color: rgba($color: #fff, $alpha: 0.02);
				border-right: 3px solid;
				@include themify($themes) {
					border-color: themed("cgold");
				}
			}
			&:nth-last-of-type(10n-8) {
				background-color: rgba($color: #fff, $alpha: 0.04);
			}
			&:nth-last-of-type(10n-7) {
				background-color: rgba($color: #fff, $alpha: 0.06);
			}
			&:nth-last-of-type(10n-6) {
				background-color: rgba($color: #fff, $alpha: 0.08);
			}
			&:nth-last-of-type(10n-5) {
				background-color: rgba($color: #fff, $alpha: 0.1);
			}
			&:nth-last-of-type(10n-4) {
				background-color: rgba($color: #fff, $alpha: 0.12);
			}
			&:nth-last-of-type(10n-3) {
				background-color: rgba($color: #fff, $alpha: 0.14);
			}
			&:nth-last-of-type(10n-2) {
				background-color: rgba($color: #fff, $alpha: 0.16);
			}
			&:nth-last-of-type(10n-1) {
				background-color: rgba($color: #fff, $alpha: 0.18);
			}
			&:nth-last-of-type(10n) {
				background-color: rgba($color: #fff, $alpha: 0.2);
			}
			flex-direction: column;
			padding: 5px 0;
			position: absolute;
			box-sizing: border-box;
			border-right: 1px solid #000;
			@include themify($themes) {
				border-color: themed("_grey");
			}
			height: calc(#{$h} - 20px);
			font-family: "Roboto Mono";
			> *:not(:last-child) {
				margin-bottom: 3px;
			}
			> .short {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-right: 3px;
				font-size: 16px;
				font-weight: bold;
				height: 25px;
				> .diceBtn {
					flex: unset;
					padding: 0;
					width: 24px;
				}
			}
			> .o,
			> .rv > div {
				padding: 3px;
				border: 1px solid #000;
				color: #000;
				font-weight: bold;
				border-radius: 5px;
			}
			> .o {
				&.g {
					@include themify($themes) {
						background-color: themed("success");
					}
				}
				&.r {
					@include themify($themes) {
						background-color: themed("alert");
					}
				}
				margin: 0 3px 3px 3px;
				width: 40px;
				font-size: 13px;
			}
			> .rv {
				position: relative;
				height: 18px;
				> div {
					box-sizing: border-box;
					position: absolute;
					background-color: lightblue;
					// align-self: center;
					font-size: 10px;
					width: 40px;
				}
			}
			> .bar {
				position: relative;
				height: 9px;
				box-sizing: border-box;
				margin-left: -1px;
				> div {
					height: 7px;
					position: absolute;
					border: 1px solid #000;
					&.g {
						// @include themify($themes) {
						// 	background-color: themed("success");
						// }
						background-color: lightgreen;
					}
					&.r {
						// @include themify($themes) {
						// 	background-color: themed("alert");
						// }
						background-color: lightcoral;
					}
				}
			}
			> .bet {
				&.g {
					@include themify($themes) {
						color: themed("success");
					}
				}
				&.r {
					@include themify($themes) {
						color: themed("alert");
					}
				}
			}
			> .bet,
			> .pa,
			> .ch,
			> .n,
			> .tim {
				width: 100%;
				text-align: center;
			}
			> .bal {
				width: 100%;
				> div {
					text-align: center;
					&.g {
						@include themify($themes) {
							color: themed("success");
						}
					}
					&.r {
						@include themify($themes) {
							color: themed("alert");
						}
					}
				}
			}
			> .act {
				text-align: center;
				font-size: 16px;
				background-color: black;
				color: white;
				padding: 3px 0;
			}
			> .bal {
				box-sizing: border-box;
				position: absolute;
				bottom: 18px;
				padding: 3px 0;
				border-top: 1px solid;
				@include themify($themes) {
					border-color: themed("_grey");
				}
			}
			.btnComment {
				flex: unset;
				> b {
					font-size: 11px;
				}
			}
			> .tim {
				position: absolute;
				bottom: 0;
				padding: 3px 0;
				border-top: 1px solid;
				@include themify($themes) {
					border-color: themed("_grey");
				}
				height: 18px;
				box-sizing: border-box;
			}
		}
	}
}
