.calendarDropDown {
	font-family: "Roboto Mono";
	> button.btnHeader {
		height: 48px;
		padding: 0;
		font-family: "Roboto Mono";
		.icon {
			margin: 0;
		}
	}
	.calendarHeaderDropDown {
	}
}
.calendar {
	display: flex;
	flex-direction: column;
	width: 240px;
	border-width: 1px;
	border-style: solid;
	border-color: #333;
	border-radius: 5px;
	padding: 5px;
	font-family: "Roboto Mono";
	> *,
	> * > *,
	> * > * > * {
		font-family: "Roboto Mono";
	}
	@include themify($themes) {
		background-color: themed("containerBackgroundColor");
	}
	> header {
		display: flex;
		align-items: center;
		> label {
			font-size: 13px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			> b {
				display: block;
			}
		}
		.multiSelect .btnDefault {
			font-family: "Roboto Mono";
			padding: 0;
		}
	}
	> main {
		> .row {
			display: flex;
			box-sizing: border-box;
			> * {
				position: relative;
				margin: 0;
				padding: 0;
				min-height: 30px;
				box-sizing: border-box;
				flex: 1;
				outline: none;
				border-width: 1px;
				border-style: solid;
				border-color: #333;
				border-radius: 0px;
				border-bottom: none;
				&:not(:last-child) {
					border-right: none;
				}
				&.weekName {
					@include themify($themes) {
						color: themed("profilePlayerColor");
						background-color: themed("profilePlayerBackgroundColor");
					}
				}
				&.today {
					background-color: cyan;
					font-weight: bold;
					color: black;
				}
				&.selected:after {
					content: "";
					position: absolute;
					z-index: 1;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					box-sizing: border-box;
					border-width: 4px;
					border-style: solid;
					@include themify($themes) {
						border-color: themed("warn");
					}
				}
			}
			&:last-child > * {
				border-bottom: 1px solid;
				border-color: #333;
			}
			> .btnDay {
				&.previous {
					background-color: rgba(0, 0, 0, 0.3);
					color: rgba(255, 255, 255, 0.3);
				}
				&.current:not(.today) {
					@include themify($themes) {
						color: themed("profilePlayerColor");
						background-color: themed("profilePlayerBackgroundColor");
					}
					&:active {
						filter: brightness(60%);
					}
				}
				&.next {
					background-color: rgba(0, 0, 0, 0.3);
					color: rgba(255, 255, 255, 0.3);
				}
				@media (hover: hover) {
					&:hover:not(.disabled) {
						filter: brightness(120%);
					}
				}
			}
		}
	}
}
