button.toggleButton {
	position: relative;
	min-width: 50px;
	height: 39px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding: 0 3px;
	border-radius: 10px;
	> .lbl {
		padding: 0;
		font-weight: bold;
		user-select: none;
		font-family: "Roboto Mono";
		white-space: nowrap;
	}
	&.success {
		justify-content: flex-start;
		> .toggleSlider {
			left: calc(100% - 35px);
			> div {
				@include themify($themes) {
					background-color: themed("success");
				}
			}
		}
		> .lbl {
			margin-right: 38px;
		}
	}
	&.alert {
		justify-content: flex-end;
		> .toggleSlider {
			left: 3px;
			> div {
				@include themify($themes) {
					background-color: themed("alert");
				}
			}
		}
		> .lbl {
			margin-left: 38px;
		}
	}
	> .toggleSlider {
		padding: 0;
		transition: left 0.3s ease;
		display: flex;
		align-items: center;
		justify-content: center;
		> div {
			transition: background-color 0.3s ease;
			width: 12px;
			height: 12px;
			border-radius: 50%;
		}
		position: absolute;
		z-index: 1;
		border-radius: 50%;
		width: 32px;
		height: 32px;

		@include themify($themes) {
			background-color: themed("toggleSliderBackgroundColor");
		}
	}
}
