.password_management {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 280px;
	margin: 0 auto;
	> h1 {
		margin-bottom: 10px;
	}
	> .block {
		margin-bottom: 10px;
		display: flex;
		flex-direction: column;
		position: relative;
		> .eye {
			transition: opacity 0.3s ease-in-out;
			&.on {
				opacity: 1;
			}
			&.off {
				opacity: 0.5;
			}
			position: absolute;
			right: 3px;
			z-index: 1;
			width: 32px;
			height: 32px;
			background-color: transparent;
			top: 3px;
			&:hover,
			&:active {
				background-color: transparent !important;
			}
		}
		> button {
			width: 100%;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 10px;
		}
	}
	padding: 20px 10px;
}
.password_management .validations,
.forgotPass .validations {
	display: flex;
	flex-direction: column;
	margin-top: 5px;
	> label {
		margin-bottom: 5px;
	}
	> div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 13px;
	}
	> .on > *:not(b) {
		@include themify($themes) {
			color: themed("success");
		}
	}
}
