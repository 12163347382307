.pandoraRoll {
	min-width: 300px;
	max-width: 750px;
	min-height: 550px;
	width: calc(100vw - 100px);
	height: calc(100vh - 100px);
	> .wrapper {
		width: 100%;
		height: 100%;
		> iframe {
			border: none;
			width: 100%;
			height: 100%;
		}
	}
}
