.depositBonusMgr {
	padding: 10px;
	> header {
		margin-bottom: 10px;
	}
	> main {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
}
