.myspins {
	padding: 10px;

	>*:not(:last-child),
	.mySpins>*:not(:last-child) {
		margin: 15px 0;
	}

	h2 {
		>b {
			&:first-child {
				margin-right: 10px;
				font-weight: bold;
			}

			&:last-child {
				color: goldenrod;
			}
		}
	}

	>.b_f2 {
		font-size: 11px;
	}

	>.rows {
		display: flex;

		>* {
			width: 100%;

			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}

		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.spinRow {
			&.off {
				opacity: 0.5;
			}

			filter: brightness(100%);
			transition: all 0.3s ease-in-out;

			@media (hover: hover) {
				&:hover {
					filter: brightness(110%);
				}
			}

			margin: 5px;
			position: relative;
			background: linear-gradient(rgba(172, 70, 255, 0.8), #271a61);
			cursor: pointer;
			min-width: 300px;
			min-height: 150px;
			max-width: 420px;
			flex: 1;
			border-radius: 5px;
			padding: 20px;
			box-sizing: border-box;

			.red {
				color: lightcoral;
				font-weight: bold;
				font-size: 18px;
			}

			>.quantity {
				position: absolute;
				left: 20px;
				bottom: 20px;
				display: flex;
				align-items: center;
				justify-content: center;

				>b:first-child {
					font-size: 32px;
					font-weight: bold;
					color: gold;
				}

				>b:last-child {
					margin-left: 5px;
				}
			}

			>.bg {
				position: absolute;
				top: 0;
				bottom: 0;
				background-repeat: no-repeat;
				width: 220px;
				background-size: cover;
				right: 46px;
				clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
				transition: all 0.6s ease-in-out;
				filter: grayscale(0);
				z-index: 0;
			}

			>.name {
				font-weight: bold;
				font-size: 22px;
			}

			>.lbl {
				position: relative;
				z-index: 1;

				&.provider {
					margin-bottom: 10px;
				}

				&.left {
					display: flex;

					>b:last-child {
						font-weight: bold;
						margin-left: 5px;
					}
				}
			}
		}
	}
}