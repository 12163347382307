.playerTiersMgr {
	.blk {
		font-family: "Roboto Mono";
		display: flex;
		flex-direction: column;
		align-items: center;
		> div {
			display: flex;
			align-items: center;
			justify-content: center;
			> b {
				display: block;
				width: 50px;
				margin-right: 5px;
				text-align: right;
			}
		}
	}
	.playerTiers {
		> * {
			margin-bottom: 20px;
		}
		.formGeneral .input > input {
			height: 48px;
		}
		.actionButtons {
			height: 70px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			&.on {
				@include animBlinkInf;
			}
			.btnDefault {
				padding: 0 !important;
				scale: 0.9;
				width: 34px;
				height: 34px;
			}
		}
	}
}
