.mute {
	display: flex;
	// align-items: center;
	padding: 10px;
	box-sizing: border-box;
	justify-content: center;
	> section.wrapper {
		min-height: 450px;
		> h3 {
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			font-size: 22px;
			margin-bottom: 10px;
			height: 32px;
		}
		@include themify($themes) {
			background-color: themed("profileGeoLabelBackgroundColor");
			color: themed("profileGeoLabelColor");
		}
		max-width: 750px;
		> *:not(:last-child) {
			margin-bottom: 10px;
		}
		> main {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			> .multiSelect {
				&:not(:last-child) {
					margin-right: 5px;
				}
			}
			> .block {
				flex: 1;
				display: flex;
				flex-direction: column;
				> label {
					margin-bottom: 5px;
				}
			}
		}
		> footer {
			display: flex;
			flex-wrap: wrap;
			> button {
				flex: 1;
				&:not(:last-child) {
					margin-right: 5px;
				}
			}
		}
	}
}
