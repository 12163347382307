.generalBetInfo {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 280px;
	margin-bottom: 10px;
	.imgGame {
		overflow: hidden;
		border-radius: 10px;
		box-shadow: 0 0 5px black;
		> img {
			object-fit: cover;
		}
	}
	button.btnDefault.btnPlay {
		background-color: rgba(255, 255, 255, 0.1);
	}
	> h2 {
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 22px;
		height: 40px;
	}
	.flex1 {
		flex: 1;
	}
	.between {
		justify-content: space-between;
	}
	@include themify($themes) {
		.green {
			color: themed("success");
		}
		.red {
			color: themed("alert");
		}
	}
	label {
		font-weight: bold;
		font-size: 14px;
	}
	.f10 {
		font-size: 10px;
	}
	.f12 {
		font-size: 12px;
		line-height: 10px;
	}
	> .row {
		box-sizing: border-box;
		@include themify($themes) {
			background-color: themed("shade01");
		}
		padding: 10px 20px;
	}
	.row {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5px;
		&.center {
			align-items: center;
			justify-content: center;
		}
	}
	.col {
		display: flex;
		flex-direction: column;
		gap: 5px;
		&.center {
			align-items: center;
			justify-content: center;
		}
	}
	b {
		font-size: 18px;
		font-family: "Roboto Mono";
		> .z {
			opacity: 0.5;
			@include themify($themes) {
				color: themed("modalColor");
			}
		}
	}
	> .line {
		height: 0;
		position: relative;
		width: 100%;
		> .c {
			position: absolute;
			left: 20px;
			right: 20px;
			height: 0px;
			border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
		}
	}
}
