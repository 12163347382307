.bodyPane > .header {
	position: fixed;
	height: $headerHeight;
	z-index: 10;
	box-sizing: border-box;
	@include themify($themes) {
		&:not(.transp) {
			background-color: themed("selectHeaderBackgroundColor");
		}
		border-bottom: themed("headerBorderBottom");
	}
	&.transp {
		.multiSelect > header {
			background-color: transparent !important;
			.digitScreen > .digit > .gwhite {
				background-color: transparent !important;
			}
		}
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			@include themify($themes) {
				background-color: themed("selectHeaderBackgroundColor");
			}
			opacity: 0.5;
			z-index: -1;
		}
	}
	> .content {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding: 0 2px;
		> .separator {
			width: 1px;
			height: 38px;
			margin: auto 5px auto 0px;
			@include themify($themes) {
				background-color: themed("menuSeparatorBackgroundColor");
			}
		}
		> button {
			margin: auto 2px;
			> img {
				display: block;
			}
		}
		> .space {
			flex: 1;
			height: 38px;
			display: flex;
			flex-wrap: wrap;
			overflow: hidden;
			align-items: center;
			justify-content: flex-end;
			.icon-ca {
				font-size: 16px;
			}
			> * {
				margin: auto 4px;
				&.sep {
					@include themify($themes) {
						background-color: themed("cgold");
					}
					width: 1px;
					height: 30px;
					margin: 0 3px;
				}
			}
			> button {
				position: relative;
				&.on > i {
					@include themify($themes) {
						color: themed("cgold");
					}
				}
				> i {
					transition: transform 0.3s ease;
				}
				> b {
					transition: opacity 0.3s ease;
				}
				@media (hover: hover) {
					&:hover {
						> i {
							transform: scale(1.15);
						}
						> b {
							opacity: 1;
						}
					}
				}
				flex-direction: row;
				&:not(.diceBtn) {
					background-color: transparent;
					&:not(:hover) > b {
						opacity: 0.7;
					}
				}
				&.diceBtn {
					flex: unset;
					padding: 0 5px;
					height: 38px;
					border-radius: 5px;
					margin: 0 5px;
					> i {
						padding-right: 5px;
					}
				}
			}
		}
	}
}
@media (max-width: (500px + $chatPaneWidth)) {
	.chatOn {
		&.bodyPane > .header > .content > .space > * {
			display: none;
		}
	}
}
@media (max-width: 550px) {
	.chatOff {
		&.bodyPane > .header > .content > .space > * {
			display: none;
		}
	}
}

.bodyPane {
	&.chatOn {
		&.right {
			> .header {
				left: $chatPaneWidth;
				right: 0;
			}
		}
		&.left {
			> .header {
				left: 0;
				right: $chatPaneWidth;
			}
		}
	}
	&.chatOff {
		> .header {
			left: 0;
			right: 0;
		}
	}
}
