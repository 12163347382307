.faqMgr {
	font-family: "Roboto Mono" !important;
	white-space: nowrap;
	> *,
	> * > * {
		font-family: "Roboto Mono" !important;
		white-space: nowrap;
		font-size: 15px;
	}
}
.faqMgr,
.faq > .faq_body > .faq_content {
	> *:not(:last-child) {
		margin-bottom: 5px;
	}
	.success {
		@include themify($themes) {
			color: themed("success");
		}
	}
	.alert {
		@include themify($themes) {
			color: themed("alert");
		}
	}
	.warn {
		@include themify($themes) {
			color: themed("warn");
		}
	}
	> header {
		.simpleDropDown {
			.entry {
				width: 100%;
				&:not(:last-child) {
					margin-bottom: 3px;
				}
			}
		}
	}
	h1 {
		font-size: 26px;
		padding: 0px;
		margin: 0px;
		font-weight: bold;
		@include themify($themes) {
			color: themed("cgold");
		}
	}
	h2 {
		font-size: 20px;
		padding: 0px;
		margin: 0px;
		font-weight: bold;
		@include themify($themes) {
			color: themed("_cyan");
		}
	}
	h3 {
		font-size: 18px;
		padding: 0px;
		margin: 0px;
		font-weight: bold;
	}
	h4 {
		font-size: 14px;
		padding: 0px;
		margin: 0px;
		font-weight: bold;
	}
	h5 {
		font-size: 10px;
		padding: 0px;
		margin: 0px;
	}
	h6 {
		font-size: 8px;
		padding: 0px;
		margin: 0px;
	}
}
.faqMgr.fixed .toolbarClassName {
	position: fixed;
	z-index: 2;
	top: $headerHeight;
}

.chatOn {
	&.right {
		.faqMgr.fixed .toolbarClassName {
			left: $chatPaneWidth;
			right: 0;
		}
	}
	&.left {
		.faqMgr.fixed .toolbarClassName {
			right: $chatPaneWidth;
			left: 0;
		}
	}
}
.chatOff {
	.faqMgr.fixed .toolbarClassName {
		left: 0;
		right: 0;
	}
}
