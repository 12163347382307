.chatMessages {
	display: flex;
	flex-direction: column;
	height: calc(100% - #{$headerHeight + $comButtonsHeight + $headlineWrapper}); // flex: 1;    //FIX for FF
	box-sizing: border-box;
	padding: $chatLocationPadding;

	>section {
		box-sizing: border-box;
		padding: $chatLocationPadding;
		overflow: hidden;

		// @include themify($themes) {
		// 	background-color: themed("shade005");
		// }
		>h3 {
			margin-bottom: 5px;
			opacity: 0.6;
		}

		&:not(:last-child) {
			margin-bottom: $chatLocationPadding;
		}

		&.messages {
			position: relative;
			overflow: hidden;
			padding-right: 0px;

			// height: 0;
			// flex: 1;
			&:not(.withFooter) {
				height: 100%;
			}

			&.withFooter {
				&.chatWith {
					height: calc(100% - #{$chatFooterHeight} - 40px);
				}

				&:not(.chatWith) {
					height: calc(100% - #{$chatFooterHeight});
				}
			}

			>.listScroll {}

			>figure.messagesArrived {
				position: absolute;
				z-index: 20;
				bottom: 5px;

				&.reversed {
					bottom: unset;
					top: 5px;
				}

				height: 35px;
				left: 5px;
				right: 13px;
				box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
				border-radius: 5px;
				user-select: none;
				// @include themify($themes) {
				// 	background-color: themed("chatMessagesArrivedBackgroundColor");
				// }
				transition: transform 0.1s ease-in-out;

				&.on {
					transform: translateY(0);
				}

				&.off {
					pointer-events: none;
					transform: translateY(100px);

					&.reversed {
						transform: translateY(-100px);
					}
				}

				display: flex;
				align-items: center;
				justify-content: center;

				>.btnShowUnread {
					flex: 1;
					flex-direction: row;
					height: 100%;
					border-radius: 5px;
					display: flex;
					justify-content: space-around;

					>b.count {
						font-size: 18px;
					}
				}
			}
		}

		&.footer {
			&.on {
				&:not(.chatWith) {
					height: $chatFooterHeight;
				}

				&.chatWith {
					height: calc(#{$chatFooterHeight} + 40px);
				}

				display: flex;
			}

			&.off {
				display: none;
				pointer-events: none;
			}

			flex-direction: column;

			&.loading {
				pointer-events: none;
				opacity: 0.7;
			}

			>.toWrapper {
				display: flex;
				flex-direction: row;
				height: 40px;

				>.btnTo {
					flex: 1;
					display: flex;
					flex-direction: row;

					>img {
						width: 24px;
						height: 24px;
					}
				}

				margin-bottom: 5px;
			}

			>.wrapper {
				height: calc(#{$chatFooterHeight} - 10px);
				border-radius: 5px;

				@include themify($themes) {
					background-color: themed("inputBackgroundColor");
					color: themed("inputColor");
					box-shadow: themed("inputBoxShadow");
					border: themed("inputBorder");
				}

				display: flex;
				flex-direction: column;

				>.type {
					flex: 1;
					position: relative;
					margin-bottom: 5px;

					>textarea {
						height: 100%;
						resize: none;
						box-shadow: none;
						border: none;

						&.editMode {
							@include themify($themes) {
								background-color: themed("chatTexareaEditModeBackgroundColor");
								color: themed("chatTexareaEditModeColor");
							}
						}
					}
				}

				>.tools {
					display: flex;
					padding: 5px;
					justify-content: space-between;

					>.sideButtons {
						flex: 1; //because of tawk button
						display: flex;
						align-items: center;

						>button {
							&:not(:last-child) {
								margin-right: 3px;
							}
						}
					}

					>.btnSend {
						margin-left: 5px;
					}
				}
			}
		}
	}
}

// .chatPane {
// 	&.left {
// 		.chatMessages > section.footer > .wrapper > .tools > .sideButtons {
// 			&.tawkOpened {
// 				margin-left: 75px;
// 			}
// 			&.tawkClosed {
// 				flex: 1;
// 			}
// 		}
// 	}
// 	&.right {
// 		.chatMessages > section.footer > .wrapper > .tools > .sideButtons {
// 			flex: 1;
// 		}
// 	}
// }

.chatMessages>.messages>.listScroll {
	.bscroll-vertical-scrollbar {
		right: 0 !important;
		width: 20px !important;
		pointer-events: auto !important;

		>.bscroll-indicator {
			background-color: lightgreen !important;
		}
	}

	ul>li {
		>.m {
			position: relative;
			box-sizing: border-box;
			border-radius: 5px;
			padding: 5px;
			margin-right: 13px;

			@include themify($themes) {
				background-color: themed("chatMessageBackgroundColor");

				&.newsLocation {
					background-color: themed("chatNewsBackgroundColor");
				}

				&.notificationsLocation {
					background-color: themed("chatNotificationsBackgroundColor");

					>header {
						flex-wrap: wrap;
					}
				}

				&.toLocation.r {
					background-color: themed("chatReceivedPmBackgroundColor");
					color: themed("chatReceivedPmColor");
				}
			}

			display: flex;
			flex-direction: row;
			// new:
			margin-top: 5px;

			&.same:not(.newsLocation):not(.notificationsLocation):not(.pmLocation) {
				margin-top: 5px;

				>.content>header {
					display: none;
				}

				&:not(.toLocation) {
					margin-left: 15px;

					@include themify($themes) {
						border-left: themed("chatMessageSameBorderLeft");
					}

					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}

			&.pmLocation {
				cursor: pointer;
				// @include mBtnDefault;
				@extend .diceBtn;
				@extend .grey;
				flex-direction: row;
				padding: 5px;
			}

			&.toLocation {
				&.r {
					margin-left: 50px;
				}

				&.s {
					margin-right: 63px;
				}
			}

			>.content {
				flex: 1;
				display: flex;
				flex-direction: column;

				>header {
					display: flex;
				}

				>.overlay {
					&.off {
						display: none;
					}

					&.on {
						display: flex;
					}

					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					opacity: 0.92;
					z-index: 1;

					@include themify($themes) {
						background-color: themed("chatMessageBackgroundColor");
					}

					>.btnClose {
						position: absolute;
						top: 5px;
						right: 5px;

						>* {
							padding: 0 !important;
						}

						&:not(:active):not(:hover) {
							background-color: transparent;
						}
					}

					align-items: center;
					justify-content: center;
					margin: 2px;

					>.btnEm {
						display: flex;
						align-items: center;
						justify-content: center;
						width: unset;
						height: unset;
						min-height: unset;
						flex: unset;
						margin: 2px;
						padding: 2px;

						&:not(:active):not(:hover) {
							background-color: transparent;
						}

						>.em {
							width: 24px;
							height: 24px;
							pointer-events: none;
							padding-left: unset;
							padding-right: unset;
						}
					}
				}

				>header,
				>main {
					margin-bottom: 3px;
				}

				>header {
					align-items: center;
					justify-content: space-between;

					>.icon {
						margin-right: 5px;
					}

					>b.title {
						margin-right: 5px;
					}

					>.au {
						display: flex;
						align-items: center;
						justify-content: center;

						>.actions {
							width: unset;

							.btnHeader {
								&:not(:active):not(:hover) {
									background-color: transparent;
								}

								width: 28px;
								height: 28px;
							}

							.content>.buttons {
								display: flex;
								flex-direction: column;
								width: inherit;
								padding: 5px;
								box-sizing: border-box;

								&.noActions {
									word-break: keep-all;
									white-space: nowrap;
								}

								>button {
									flex: 1;
									min-height: unset;
									display: flex;
									align-items: center;
									padding: 5px;

									&:not(:last-child) {
										margin-bottom: 2px;
									}

									>b {
										margin-left: 5px;
										pointer-events: none;
										word-break: keep-all;
										white-space: nowrap;
									}
								}
							}

							margin-right: 5px;
						}

						>.un {
							display: flex;
							position: relative;

							>b {
								display: flex;
								align-items: center;
								margin-right: 5px;
							}

							>button {
								// position: absolute;
								// top: -13px;
								margin-right: 5px;
								border-radius: 50%;

								&:after {
									content: "";
									position: absolute;
									border-radius: 50%;
									box-sizing: border-box;
									border-width: 2px;
									border-style: solid;

									@include themify($themes) {
										border-color: themed("btnIconBackgroundColorHover");
									}

									pointer-events: none;
									width: $chatAvatarSize;
									height: $chatAvatarSize;
								}

								>img {
									border-radius: 50%;
									padding: 0;
									width: $chatAvatarSize;
									height: $chatAvatarSize;
								}

								>.onlineMark {
									position: absolute;
									width: 12px;
									height: 12px;
									box-sizing: border-box;
									border: 1px solid #000;
									top: 0px;
									left: 0px;
									z-index: 1;
									border-radius: 4px;
								}

								&.notificationsLocation {
									min-height: unset;

									&:after {
										width: 32px;
										height: 32px;
									}

									>img {
										width: 32px;
										height: 32px;
									}
								}
							}

							>.h {
								// margin-left: 55px;
								display: flex;
								// align-items: center;
								flex-direction: column;
								justify-content: center;

								>.hh {
									// flex: 1;
									display: flex;
									align-items: center;

									// justify-content: space-between;
									>.friendsIcon {
										margin-right: 5px;
									}

									.un {
										font-weight: bold;
										cursor: pointer;
									}

									// > .t {
									// 	font-size: 10px;
									// 	> .d {
									// 		margin-right: 5px;
									// 	}
									// 	> .t {
									// 		font-style: italic;
									// 	}
									// }
								}

								>.st {
									border-bottom-right-radius: 10px;
									font-weight: 700;
									font-size: 10px;
									padding: 3px;

									@include themify($themes) {
										background-color: themed("diceButtonBackgroundColor");
									}

									&.admin {
										@include themify($themes) {
											background-color: themed("toastBackgroundColorError");
											color: themed("toastColorError");
										}
									}

									&.mod {
										@include themify($themes) {
											background-color: themed("toastBackgroundColorSuccess");
											color: themed("toastColorSuccess");
										}
									}

									&.vip {
										@include themify($themes) {
											background-color: themed("cgold");
											color: themed("dark");
										}
									}
								}
							}
						}
					}

					&.bot {
						>.un {
							position: relative;

							// > .h {
							// 	margin-left: 55px;
							// }
							>b {
								// position: absolute;
								// top: -13px;
								margin-right: 5px;
								cursor: default;
								border-radius: 50%;
								width: $chatAvatarSize;
								height: $chatAvatarSize;
								text-align: center;
								box-sizing: border-box;
								background-color: #3c0254;
								color: #bcff73;
								overflow: hidden;
								border-width: 2px;
								border-style: solid;
								// @include themify($themes) {
								// 	border-color: themed("btnIconBackgroundColorHover");
								// }
								border-color: rgb(216, 112, 147);

								>i {
									line-height: $chatAvatarSize;
									font-size: 32px;

									>i {
										display: flex !important;
										align-items: center;
										justify-content: center;
										height: 100%;
									}
								}
							}
						}
					}

					>.ll {
						display: flex;
						align-items: center;
						justify-content: center;

						>.likeStats {
							margin-left: 5px;
							display: flex;
							align-items: center;
							cursor: pointer;

							>.btnShowPlayers {
								display: flex;
								padding: 5px;
								margin-right: 5px;
								border-radius: 5px;
								border-width: 1px;
								border-style: solid;

								@include themify($themes) {
									border-color: themed("shade03");
								}

								>*:not(:last-child) {
									margin-right: 3px;
								}

								>div {
									pointer-events: none;

									>.em {
										pointer-events: none;
										width: 16px;
										height: 16px;
									}
								}

								>.counter {
									pointer-events: none;
									user-select: none;
									margin-left: 5px;

									@include themify($themes) {
										color: themed("containerColor");
									}
								}
							}

							>.players {
								&.off {
									display: none;
								}

								position: absolute;
								left: 5px;
								right: 85px;
								padding: 10px;
								bottom: 37px;
								box-sizing: border-box;
								border-radius: 5px;
								// opacity: 0.92;
								z-index: 2;
								box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);

								@include themify($themes) {
									background-color: themed("formBackgroundColor");
								}

								>.p {
									pointer-events: none;
									display: flex;
									align-items: center;
									border-radius: 5px;
									margin-bottom: 2px;
									font-size: 12px;
									padding: 2px;

									@include themify($themes) {
										background-color: themed("shade005");
									}

									>img {
										pointer-events: none;
										margin-right: 5px;
										border-radius: 50%;
										width: 16px;
										height: 16px;

										@include themify($themes) {
											background-color: themed("btnIconBackgroundColorHover");
										}
									}

									>.f1 {
										pointer-events: none;
										flex: 1;
									}

									>.em {
										pointer-events: none;
										width: 16px;
										height: 16px;
									}
								}
							}
						}

						>.rank {
							display: flex;
							align-items: center;
							// padding: 3px;
							// width: 80px;
							// align-self: flex-start;
							border-radius: 5px;

							>div.rankInfo {
								display: flex;
								flex-direction: column;
								align-items: flex-end;
								min-width: 25px;

								>b {
									&:first-child {
										font-size: 11px;
									}

									&:last-child {
										font-family: "Roboto Mono";
										font-weight: 700;
										font-size: 20px;
									}
								}
							}

							>img {
								// width: 40px;
								// height: fit-content;
								margin-left: 6px;
							}
						}

						>button.btnLike {
							&:not(:active):not(:hover) {
								background-color: transparent;
							}

							width: unset;
							min-width: unset;
							padding: 0 3px;

							>i {
								padding-right: 0;
								padding-left: 0;
								pointer-events: none;
							}
						}
					}
				}

				>main {
					@include themify($themes) {
						background-color: themed("chatMessageContentBackgroundColor");
					}

					strong {
						font-size: 18px;
						font-weight: 700;
					}

					div.br {
						display: block;
					}

					b.red {
						font-size: 18px;
						font-weight: 700;

						@include themify($themes) {
							color: themed("alert");
						}
					}

					b.green {
						font-size: 18px;
						font-weight: 700;

						@include themify($themes) {
							color: themed("success");
						}
					}

					b.gold {
						font-size: 18px;
						font-weight: 700;

						@include themify($themes) {
							color: themed("cgold");
						}
					}

					b.cyan {
						font-size: 18px;
						font-weight: 700;

						@include themify($themes) {
							color: themed("_cyan");
						}
					}

					b.small {
						font-size: 11.5px;
					}

					div.anim {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						position: relative;

						@include themify($themes) {
							background-color: themed("shade01");
						}

						border-radius: 5px;

						>div {
							overflow-y: hidden;
							backface-visibility: hidden;
							transform-style: preserve-3d;

							>svg,
							>canvas {
								backface-visibility: hidden;
								transform-style: preserve-3d;

								* {
									backface-visibility: hidden;
									transform-style: preserve-3d;
								}
							}

							&.off {

								>svg,
								>canvas {
									opacity: 0;
									pointer-events: none;
								}

								>h3 {
									pointer-events: none;
									position: absolute;
									top: 0;
									left: 0;
									bottom: 0;
									right: 0;
									display: flex;
									align-items: center;
									justify-content: center;
									text-align: center;
									font-size: 18px;
									font-weight: 700;
								}
							}
						}
					}

					&.prev {
						text-decoration: line-through !important;

						@include themify($themes) {
							background-color: themed("chatMessageEditedPrevContentBackgroundColor");
						}

						>* {
							text-decoration: line-through !important;
						}
					}

					&.bot {
						&.jackpotBot {
							display: flex;
							justify-content: space-between;
						}
					}

					border-radius: 5px;
					font-family: "Montserrat",
					"BPG Glaho WEB Caps",
					sans-serif;
					font-weight: 300;
					line-height: 18px;
					// overflow-wrap: break-word;
					word-break: break-word;
					padding: 3px;

					>* {
						display: inline-flex;
						font-family: "Montserrat", "BPG Glaho WEB Caps", sans-serif;
						font-weight: 500;
					}

					>.url {
						word-break: break-all;
						line-height: 18px;
					}

					>.btnYTB {
						flex-direction: row;
						padding: 2px;
						min-height: unset;
						margin: 1px 0;

						>* {
							padding: 0;
							pointer-events: none;
						}

						>img {
							width: 24px;
							height: 24px;
							border-radius: 3px;
						}
					}

					>.btnBet {
						flex-direction: row;
						padding: 2px;
						min-height: unset;
						margin: 1px 0;

						>* {
							padding: 0;
							pointer-events: none;
						}

						>img {
							width: 24px;
							height: 24px;
							border-radius: 3px;
							margin-right: 3px;
						}

						>div {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							font-family: "Roboto Mono";

							>b {
								font-family: "Roboto Mono";
								font-size: 10px;
							}
						}
					}

					>.em {
						width: 24px;
						height: 24px;
					}

					>.jackpot {
						display: inline-flex;
						align-items: center;

						>b {
							font-size: 22px;
						}

						>img {
							width: 36px;
							height: 36px;
							border-radius: 3px;
							margin-left: 3px;
						}
					}
				}

				>main>.tag,
				>.ed>.tag {
					display: inline-flex;
					flex-direction: row;
					align-items: center;
					min-height: unset;

					&.my {
						background-color: crimson;
						padding: 2px;
						border-radius: 5px;
					}

					// margin: 1px 0;
					>.btnTag {
						margin-right: 3px;
						position: relative;
						width: 24px;
						height: 24px;

						>img {
							pointer-events: none;
							box-sizing: border-box;
							border-radius: 50%;
							width: 24px;
							height: 24px;
						}

						&:after {
							content: "";
							position: absolute;
							border-radius: 50%;
							box-sizing: border-box;
							border-width: 2px;
							border-style: solid;
							pointer-events: none;
							left: 0;
							right: 0;
							top: 0;
							bottom: 0;

							@include themify($themes) {
								border-color: themed("btnIconBackgroundColorHover");
							}

							width: 24px;
							height: 24px;
						}
					}

					>a {
						text-decoration: underline;
					}
				}

				>footer {
					display: flex;
					align-items: center;
					padding: 0 5px;

					>.left {
						flex: 1;
						display: flex;
						align-items: center;
						max-height: 10px;

						>b.title {
							font-size: 10px;
						}

						>.read {
							&.on {
								@include themify($themes) {
									color: themed("success");
								}
							}

							&.off {
								opacity: 0.3;

								@include themify($themes) {
									color: themed("containerColor");
								}
							}
						}

						>.actions {
							width: unset;

							.btnHeader {
								&:not(:active):not(:hover) {
									background-color: transparent;
								}

								width: 28px;
								height: 28px;
							}

							.content>.buttons {
								display: flex;
								flex-direction: column;
								width: inherit;
								padding: 5px;
								box-sizing: border-box;

								&.noActions {
									word-break: keep-all;
									white-space: nowrap;
								}

								>button {
									flex: 1;
									min-height: unset;
									display: flex;
									align-items: center;
									padding: 5px;

									&:not(:last-child) {
										margin-bottom: 2px;
									}

									>b {
										margin-left: 5px;
										pointer-events: none;
										word-break: keep-all;
										white-space: nowrap;
									}
								}
							}

							margin-right: 5px;
						}

						>button.btnLike {
							&:not(:active):not(:hover) {
								background-color: transparent;
							}

							width: unset;
							min-width: unset;
							padding: 0 3px;

							>i {
								padding-right: 0;
								padding-left: 0;
								pointer-events: none;
							}
						}

						>.likeStats {
							margin-left: 5px;
							display: flex;
							align-items: center;
							cursor: pointer;

							>.btnShowPlayers {
								display: flex;
								padding: 0 3px;

								>*:not(:last-child) {
									margin-right: 3px;
								}

								>div {
									pointer-events: none;

									>.em {
										pointer-events: none;
										width: 16px;
										height: 16px;
									}
								}

								>.counter {
									pointer-events: none;
									user-select: none;
									margin-left: 5px;

									@include themify($themes) {
										color: themed("containerColor");
									}
								}
							}

							>.players {
								&.off {
									display: none;
								}

								position: absolute;
								left: 5px;
								right: 5px;
								padding: 10px;
								bottom: 40px;
								box-sizing: border-box;
								border-radius: 5px;
								// opacity: 0.92;
								z-index: 2;
								box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);

								@include themify($themes) {
									background-color: themed("formBackgroundColor");
								}

								>.p {
									pointer-events: none;
									display: flex;
									align-items: center;
									border-radius: 5px;
									margin-bottom: 2px;
									font-size: 12px;
									padding: 2px;

									@include themify($themes) {
										background-color: themed("shade005");
									}

									>img {
										pointer-events: none;
										margin-right: 5px;
										border-radius: 50%;
										width: 16px;
										height: 16px;

										@include themify($themes) {
											background-color: themed("btnIconBackgroundColorHover");
										}
									}

									>.f1 {
										pointer-events: none;
										flex: 1;
									}

									>.em {
										pointer-events: none;
										width: 16px;
										height: 16px;
									}
								}
							}
						}
					}

					>.t {

						// position: absolute;
						@include themify($themes) {
							background-color: themed("chatMessageBackgroundColor");
						}

						// bottom: 0px;
						// right: 5px;
						// z-index: 1;
						padding: 0px;
						font-size: 10px;

						>.d {
							margin-right: 5px;
						}

						>.t {
							font-style: italic;
						}
					}
				}

				>.ed {
					display: flex;
					align-items: center;

					@include themify($themes) {
						background-color: themed("chatMessageEditedPrevContentBackgroundColor");
					}

					border-radius: 5px;
					padding: 0 5px;
					margin-top: 3px;

					// @include themify($themes) {
					// 	background-color: themed("chatTexareaEditModeBackgroundColor");
					// 	color: themed("chatTexareaEditModeColor");
					// }
					>b {
						margin-right: 5px;
						font-size: 10px;
					}

					>.t {
						flex: 1;
						text-align: right;
						font-size: 10px;
					}
				}
			}

			>.sideLeft {
				@include themify($themes) {
					background-color: themed("shade01");
				}

				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				padding: 0 5px;
				box-sizing: border-box;
				margin-right: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			>.sideRight {
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				box-sizing: border-box;
				margin-left: 5px;
				display: flex;
				align-items: center;
				justify-content: center;

				>.unreadCount {
					position: absolute;
					top: 10px;
					right: 10px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					user-select: none;
					font-weight: bold;

					>.unread {
						@include themify($themes) {
							color: themed("alert");
						}
					}

					>.sep {
						margin: 0 3px;
					}

					>.count {}
				}
			}
		}
	}
}

.chatMessages>.messages>#rain_holder {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	user-select: none;

	>*,
	>*>* {
		pointer-events: none;
		user-select: none;
	}

	box-sizing: border-box;
	height: 100%;
	margin: 0;
	overflow: hidden;

	>#rain_back_row {
		display: none;
		z-index: 1;
		bottom: 60px;
		opacity: 0.5;
	}

	.drop {
		position: absolute;
		bottom: 100%;
		width: 15px;
		height: 120px;
		pointer-events: none;
		animation: drop 0.5s linear infinite;
	}

	@keyframes drop {
		0% {
			transform: translateY(0vh);
		}

		75% {
			transform: translateY(90vh);
		}

		100% {
			transform: translateY(90vh);
		}
	}

	.stem {
		width: 2px;
		height: 60%;
		margin-left: 7px;
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25));
		animation: stem 0.5s linear infinite;
	}

	@keyframes stem {
		0% {
			opacity: 1;
		}

		65% {
			opacity: 1;
		}

		75% {
			opacity: 0;
		}

		100% {
			opacity: 0;
		}
	}

	&.off {

		.drop,
		.stem {
			animation: none;
			visibility: hidden;
		}
	}
}