.avatarComponent {
	display: flex;
	align-items: center;
	> .onlineMark {
		margin-right: 3px;
		width: 14px;
		height: 14px;
		box-sizing: border-box;
		border-radius: 4px;
		border: 1px solid #000;
	}
	> .btnTag {
		padding: 0;
		margin: unset;
		margin-right: 3px;
		position: relative;
		width: 24px;
		height: 24px;
		@include themify($themes) {
			color: themed("containerColor");
		}
		> img {
			pointer-events: none;
			box-sizing: border-box;
			border-radius: 50%;
			width: 24px;
			height: 24px;
		}
		&:after {
			content: "";
			position: absolute;
			border-radius: 50%;
			box-sizing: border-box;
			border-width: 2px;
			border-style: solid;
			pointer-events: none;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			@include themify($themes) {
				border-color: themed("btnIconBackgroundColorHover");
			}
			width: 24px;
			height: 24px;
		}
	}
	&.s32 {
		> .btnTag,
		> .btnTag > img,
		> .btnTag:after {
			width: 32px;
			height: 32px;
		}
	}
	&.s48 {
		> .btnTag,
		> .btnTag > img,
		> .btnTag:after {
			width: 48px;
			height: 48px;
		}
	}
}
