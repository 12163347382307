.humanDetector {
	&.off {
		pointer-events: none;
		opacity: 0.5;
	}

	width: 100%;
	display: flex;
	flex-direction: column;

	>label {
		margin-bottom: 10px;
	}

	>.box {
		user-select: none;
		pointer-events: none;
		touch-action: none;

		display: flex;
		// background-color: rgb(27, 27, 27);
		border-radius: 5px;
		height: 150px;
		position: relative;
		width: 260px;
		margin: 0 auto;

		>label {
			margin: auto;
			font-weight: bold;
			font-size: 20px;
			font-family: "Roboto Mono";

			@include themify($themes) {
				color: themed("alert");
			}
		}

		>.btnRepeat {
			pointer-events: all;
			touch-action: auto;
			
			position: absolute;
			z-index: 1;
			right: 0;
			top: 0;
			transform: scale(0.5);
		}

		>.innerBox {
			position: absolute;
			left: 25px;
			right: 25px;
			top: 0;
			bottom: 0;
			pointer-events: none;
			touch-action: none;
			user-select: none;

			>.target {
				position: absolute;
				width: 1px;
				height: 125px;
				margin-left: -0.5px;
				background-color: rgba(255, 255, 255, 0.5);

				pointer-events: none;
				touch-action: none;
				user-select: none;

				>.img {
					pointer-events: none;
					touch-action: none;
					user-select: none;

					position: absolute;
					background-position: center;
					background-size: contain;
					width: 32px;
					height: 32px;

					left: -16px;
					bottom: -16px;
					z-index: 1;
				}
			}
		}

		>.arrow {
			pointer-events: none;
			touch-action: none;
			user-select: none;

			position: absolute;
			bottom: 16px;
			left: 60px;
			z-index: 1;
			transform: translate(0%, 0);
			animation: slide 2s infinite;

			&:nth-child(0) {
				animation-delay: 1s;
			}

			&:nth-child(1) {
				animation-delay: 1.1s;
			}

			&:nth-child(2) {
				animation-delay: 1.2s;
			}
		}

		>.arrow>div {
			user-select: none;
			pointer-events: none;
			touch-action: none;
			border: solid rgba(255, 255, 255, 0.5);
			border-width: 0 4px 4px 0;
			display: inline-block;
			padding: 8px;
			transform: rotate(-45deg);
		}

		@keyframes slide {
			0% {
				opacity: 0;
				transform: translate(0%, 0);
			}

			20% {
				opacity: 1;
				transform: translate(40%, 0);
			}

			100% {
				opacity: 0;
				transform: translate(100%, 0);
			}
		}

		/* The slider itself */
		>.slider {
			pointer-events: all;
			touch-action: pan-x;
			position: absolute;
			bottom: 25px;
			left: 0;
			right: 0;

			-webkit-appearance: none;
			/* Override default CSS styles */
			appearance: none;
			width: 100%;
			/* Full-width */
			height: 1px;
			/* Specified height */
			background: #d3d3d3;
			/* Grey background */
			outline: none;
			/* Remove outline */
			// opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
			// -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
			// transition: opacity 0.2s;
			padding: 0;
		}

		/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
		@mixin thumb {
			-webkit-appearance: none;
			/* Override default look */
			appearance: none;
			width: 50px;
			height: 50px;
			cursor: pointer;
			border-radius: 5px;
			background-color: white;
			outline: none;
			border: none;
		}

		>.slider::-webkit-slider-thumb {
			@include thumb();
		}

		>.slider::-moz-range-thumb {
			@include thumb();
		}
	}
}