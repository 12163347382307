.container-lobby {
	position: relative;
	> h1 {
		position: absolute;
		top: 50px;
		left: 0;
	}
	> .lobby {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: -50px;
		> .widgets {
			margin-top: 30px;
		}
	}
}
