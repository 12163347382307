.fortuneWheelMgr {
	> .col {
		display: flex;
		flex-direction: column;
		width: 100%;
		> .row {
			width: 100%;
			display: flex;
			align-items: flex-start;
			> .fortuneWheel {
				margin-right: 10px;
			}
			> .pane {
				> *:not(:last-child) {
					margin-bottom: 10px;
				}
				> .table {
					button {
						padding: 0;
					}
				}
			}
		}
	}
}
@media (max-width: (780px + $chatPaneWidth)) {
	.chatOn {
		.fortuneWheelMgr > .row > .fortuneWheel {
			display: none;
		}
	}
}
@media (max-width: 780px) {
	.chatOff {
		.fortuneWheelMgr > .row > .fortuneWheel {
			display: none;
		}
	}
}
