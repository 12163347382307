.newsSlides {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
	z-index: 0;
	> a {
		flex: 1;
		min-width: 250px;
		max-width: 450px;
		margin: 20px;
		outline: none !important;
		transition: transform 0.3s ease-in-out;
		color: white !important;
		will-change: transform;
		text-decoration: none;
		&:hover {
			transform: scale(1.01);
			color: white;
		}
		&:active {
			color: white;
		}
		> .newsPost {
			animation-duration: 0.5s;
			animation-timing-function: ease-in-out;
			position: relative;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			cursor: pointer;
			box-sizing: border-box;
			padding: 20px;
			> h2 {
				font-weight: bold;
				font-size: 18px;
				margin-bottom: 10px;
				line-height: 22px;
				will-change: transform;
			}
			> div {
				> img {
					height: 170px;
					width: 100%;
					object-fit: cover;
					border-radius: 10px;
					border-image: linear-gradient(90deg, transparent 3%, #ff0053, transparent 93%) 1;
					border-bottom: 1px solid;
					margin-bottom: 10px;
				}
				> .time {
					text-align: right;
					font-size: 14px;
				}
			}
			&:before {
				content: "";
				position: absolute;
				z-index: -2;
				left: 10px;
				top: 10px;
				bottom: -10px;
				right: -10px;
				background-color: #7f51be;
				background-image: linear-gradient(45deg, black, transparent);
				border-top-right-radius: 10px;
			}
			&:after {
				content: "";
				position: absolute;
				z-index: -1;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				background-color: #a783db;
				background-image: linear-gradient(45deg, black, transparent);
				border-top-right-radius: 10px;
			}
		}
	}
}
