.deltasMgr {
  .warn {
    @include themify($themes) {
      color: themed("warn");
    }
  }
  > h3 {
    margin-bottom: 10px;
  }
  > header,
  > main,
  > footer {
    margin-bottom: 10px;
    .player > .wrapper > .btnDefault {
      padding: 0px 0px;
      min-width: 150px;
      width: 100%;
    }
    .player > .wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      > *:not(:last-child) {
        margin-bottom: 3px;
      }
      > div > b:last-child {
        margin-left: 5px;
      }
      > button > img {
        width: 24px;
        height: 24px;
      }
    }
  }
  > header {
    > h2 {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}
