.fortuneWheel {
	min-width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	padding: 20px 15px;
	> header {
		text-align: left;
		font-weight: bold;
		font-size: 22px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		gap: 10px;
	}
	> *:not(:last-child) {
		margin-bottom: 10px;
	}
	> .infoTop {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		width: 100%;
		padding: 10px;
		box-sizing: border-box;
		position: relative;
		> div {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			> span {
				margin-left: 10px;
			}
		}
		> .resetOn {
			font-size: 11px;
		}
		color: #000;
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			border-radius: 5px;
			z-index: -1;
			@include themify($themes) {
				background-color: themed("warn");
			}
		}
	}
	> .bar {
		box-sizing: border-box;
		&.disabled {
			pointer-events: none;
			opacity: 0.5;
		}
		width: 100%;
		@include themify($themes) {
			background-color: themed("shade01");
		}
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding: 3px;
		border-radius: 5px;
		> *:not(:last-child) {
			margin-right: 3px;
		}
		> button {
			border-radius: 5px;
			padding: 0 5px;
			&.on {
				background-image: linear-gradient(90deg, #f128e2 0%, #e39875 100%);
				color: #000;
			}
			&.off {
				filter: grayscale(1);
				background-color: transparent;
			}
		}
	}
	> .sectorsWrapper {
		position: relative;
		border-radius: 50%;
		&::before {
			content: "";
			$w: 3px;
			position: absolute;
			left: -$w;
			top: -$w;
			right: -$w;
			bottom: -$w;
			background-color: rgba(255, 255, 255, 0.1);
			border-radius: 50%;
			z-index: -1;
		}
		> .btnCenterSpin {
			$v: 32px;
			width: $v * 2;
			height: $v * 2;
			background-color: greenyellow;
			background-image: linear-gradient(90deg, #f128e2 0%, #e39875 100%);
			color: white;
			font-weight: bold;
			border-radius: 50%;
			position: absolute;
			z-index: 1;
			left: calc(50% - #{$v});
			top: calc(50% - #{$v});
			box-shadow: rgb(38, 57, 77) 0px 20px 10px -10px;
			> .txt {
				@include mixinYoyoRotate;
			}
			&:disabled {
				opacity: 1 !important;
				background-color: rgb(110, 144, 58);
			}
		}
		> .arrow {
			position: absolute;
			right: -10px;
			top: 50%;
			width: 0px;
			height: 0px;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			> .arrow-left {
				width: 0;
				height: 0;
				border-top: 10px solid transparent;
				border-bottom: 10px solid transparent;
				border-right: 30px solid #e64695;
			}
			> .arrow-left-shadow {
				right: 0;
				top: -3px;
				position: absolute;
				width: 0;
				height: 0;
				border-top: 10px solid transparent;
				border-bottom: 10px solid transparent;
				border-right: 30px solid rgba(1, 17, 46, 0.622);
				z-index: -1;
				filter: blur(3px);
			}
		}
		> .sectorsBorder {
			padding: 10px;
			background-color: #9e8fb2;
			// background-image: linear-gradient(315deg, #9e8fb2 0%, #a7acd9 74%);
			background-image: linear-gradient(315deg, #645dc3 0%, #342165 100%);
			border-radius: 50%;
			animation-delay: 0.3s;
			@include mixinRotateScaleFadeIn;
			> .sectors {
				position: relative;
				border-radius: 50%;
				overflow: hidden;
				box-shadow: 0 0 4px 1px #1e5481;
				background-color: rgba(255, 255, 255, 0.3);
				.sector {
					&::after {
						content: "";
					}
					cursor: pointer;
					> .text {
						// position: relative;
						// line-height: 0;
						// margin-right: 5px;
						font-size: 14px;
						font-weight: normal;
						color: white;
						filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.1)) drop-shadow(0 0 2px rgba(0, 0, 0, 0.3));
						// width: 100px;
						text-align: center;
						user-select: none;
						// > img {
						// 	position: absolute;
						// 	right: 5px;
						// 	top: -12px;
						// }
						flex: 1;
						margin-left: 33px;
						display: flex;
						align-items: center;
						> b {
							flex: 1;
							overflow: hidden;
						}
						> img {
							margin: 0 5px;
						}
					}
					position: absolute;
					transform-origin: 0% 50%;
					clip-path: polygon(0 50%, 100% 100%, 100% 0%);
					color: black;
					display: flex;
					align-items: center;
					// justify-content: flex-end;
					transition: filter 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
					@media (hover: hover) {
						&:hover {
							filter: brightness(120%);
						}
					}
				}
			}
		}
	}
	> .info {
		display: flex;
		flex-direction: column;
		font-weight: normal;
		font-size: 11px;
		max-width: 300px;
		align-items: center;
		justify-content: center;
		> *:not(:last-child) {
			margin-bottom: 5px;
		}
		> div {
			display: flex;
			align-items: center;
			> b.red {
				color: lightcoral;
				font-size: 16px;
				margin-left: 5px;
			}
			> b.yellow {
				color: rgb(255, 241, 112);
				font-size: 13px;
				margin-left: 5px;
			}
			> b.green {
				color: lightgreen;
				font-size: 16px;
				margin-left: 5px;
			}
		}
	}
	> .btnSpin {
		flex: unset;
		font-weight: bold;
		width: 150px;
		height: 50px;
		border-radius: 10px;
		font-size: 15px;
		animation-delay: 0.5s;
		@include mixinScaleFadeIn;
		background: linear-gradient(60deg, rgb(121, 87, 248) 50%, rgb(178, 89, 237) 100%);
	}
}
