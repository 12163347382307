.notFound {
  display: flex;
  min-height: 250px;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  box-sizing: border-box;
  > .c404 {
    max-width: 280px;
    display: flex;
    align-items: center;
    justify-content: center;

    > b {
      font-size: 150px;
      &.c {
        @include themify($themes) {
          color: themed("linkColorHover");
        }
      }
    }
    margin-bottom: 30px;
  }
  .anim {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1 {
    max-width: 280px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  > div {
    max-width: 280px;
  }
  footer {
    max-width: 280px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    > button {
      padding: 0 10px;
    }
  }
}
