.mailManagement {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 280px;
	margin: 0 auto;
	> h1 {
		margin-bottom: 10px;
	}
	> .block {
		margin-bottom: 10px;
		display: flex;
		flex-direction: column;

		padding: 10px;
		@include themify($themes) {
			background-color: themed("profileGeoBackgroundColor");
			color: themed("profileGeoColor");
		}
		margin-bottom: 10px;
		> label {
			margin-bottom: 5px;
		}
		> .inp {
			margin-bottom: 5px;
			display: flex;
			align-items: center;
			> .icon {
				@include themify($themes) {
					&.green {
						color: themed("success");
					}
					&.yellow {
						color: themed("warn");
					}
					&.red {
						color: themed("alert");
					}
				}
				margin-left: 5px;
			}
			> input {
				flex: 1;
				display: block;
				text-align: center;
			}
		}
		> button {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			&.btnResend {
				margin-top: 5px;
			}
		}
	}
	padding: 20px 10px;
}
