@mixin mixinRotateScaleFadeIn {
	animation-name: animRotateScaleFadeIn;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
	opacity: 0;
}
@keyframes animRotateScaleFadeIn {
	0% {
		transform: rotate(-180deg) scale(0);
		opacity: 0;
	}
	100% {
		transform: rotate(0deg) scale(1);
		opacity: 1;
	}
}

@mixin mixinScaleFadeIn {
	animation-name: animScaleFadeIn;
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0, 0.99, 0.41, 1.4);
	opacity: 0;
}
@keyframes animScaleFadeIn {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
.animScaleFadeIn {
	@include mixinScaleFadeIn;
}

@mixin mixinYoyoRotate {
	animation-name: animYoyoRotate;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}
@keyframes animYoyoRotate {
	0% {
		transform: rotate(-25deg) scale(1);
	}
	50% {
		transform: rotate(25deg) scale(1);
	}
	100% {
		transform: rotate(-25deg) scale(1);
	}
}

.animYoyoRotate {
	@include mixinYoyoRotate;
}

@mixin mixinRotateInf {
	animation-name: animRotateInf;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-fill-mode: forwards;
	animation-timing-function: linear;
}
@keyframes animRotateInf {
	0% {
		transform: rotate(-360deg);
	}
	100% {
		transform: rotate(0deg) scale(1);
		opacity: 1;
	}
}
.animRotateInf {
	@include mixinRotateInf;
}

@mixin animPlaceholderShimmerInf {
	animation-duration: 10s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: animPlaceholderShimmerInf;
	animation-timing-function: linear;
	background-color: rgba(255, 255, 255, 0.1);
	background: linear-gradient(45deg, transparent 25%, hsla(262, 100%, 77%, 0.66) 50%, transparent 75%);
	background-size: 600% 300%;
}
@keyframes animPlaceholderShimmerInf {
	0% {
		background-position: -600% 0;
	}
	100% {
		background-position: 600% 0;
	}
}
.animPlaceholderShimmerInf {
	@include animPlaceholderShimmerInf;
}

@mixin animBlinkInf {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: animBlinkInf;
	animation-timing-function: linear;
	background-color: rgba(255, 255, 255, 0.1);
}
@keyframes animBlinkInf {
	0% {
		background-color: hsl(16, 100%, 30%);
	}
	67% {
		background-color: hsl(16, 100%, 10%);
	}
	100% {
		background-color: hsl(16, 100%, 30%);
	}
}
.animBlinkInf {
	@include animBlinkInf;
}

@mixin mixinSlideDownFadeIn {
	animation-name: animSlideDownFadeIn;
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
	animation-timing-function: linear;
	opacity: 0;
}
@keyframes animSlideDownFadeIn {
	0% {
		translate: 0 -100px;
		opacity: 0;
	}
	100% {
		translate: 0 0;
		opacity: 1;
	}
}
.animSlideDownFadeIn {
	@include mixinSlideDownFadeIn;
}
