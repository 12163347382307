.jackpotMgr {
	> header,
	> main,
	> footer {
		margin-bottom: 10px;
	}
	> header {
		> h2 {
			font-weight: bold;
		}
	}
}
