.depositBonus {
	padding: 5px;
	.deposits_made {
		display: flex;
		align-items: center;
		> b:last-child {
			margin-left: 5px;
			font-size: 20px;
			@include themify($themes) {
				color: themed("alert");
			}
		}
	}
	> .wrapper {
		display: flex;
		&:last-of-type() {
			@include themify($themes) {
				background-color: themed("shade01");
			}
		}
		flex-wrap: wrap;
		padding: 5px;
		min-width: 280px;
		max-width: 1300px;
		margin: auto;
		justify-content: center;
		> .block {
			transition: all 0.15s ease-in-out;
			position: relative;
			z-index: 0;
			&.off {
				pointer-events: none;
				opacity: 0.5;
			}
			> .bg {
				transform: scale(1);
				z-index: -1;
				transition: all 0.15s ease-in-out;
				opacity: 0.7;
				position: absolute;
				@include themify($themes) {
					background-color: themed("profilePlayerBackgroundColor");
				}
				background-image: linear-gradient(90deg, #f128e24f 0%, #e39875a8 100%);
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				border-radius: 5px;
			}
			&:not(.myBlock) {
				@media (hover: hover) {
					&:hover {
						transform: scale(1.02);
						> .bg {
							opacity: 1;
						}
					}
				}
			}
			&.myBlock {
				@media (hover: hover) {
					&:hover {
						> .bg {
							opacity: 1;
						}
					}
				}
			}
			@include themify($themes) {
				color: themed("profilePlayerColor");
			}
			> .progress {
				display: flex;
				// justify-content: center;
				// align-items: center;
				font-family: "Roboto Mono";
				> * {
					flex: 1;
				}
				> .wagers {
					flex: 3;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 22px;
				}
			}
			padding: 10px;
			min-width: 250px;
			max-width: 400px;
			flex: 1;
			box-sizing: border-box;
			border-radius: 5px;
			display: flex;
			flex-direction: column;
			margin: 3px;
			> h3 {
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: bold;
				font-size: 22px;
				margin-bottom: 10px;
				&.warn {
					@include themify($themes) {
						color: themed("warn");
					}
				}
			}
			> .timeLeft {
				display: flex;
				align-items: center;
				justify-content: center;
				> b {
					display: block;
					margin-left: 10px;
					@include themify($themes) {
						color: themed("warn");
						font-weight: bold;
					}
				}
				margin-bottom: 10px;
			}
			> .entry {
				&:nth-child(2n) {
					background-color: rgba(0, 0, 0, 0.05);
				}
				box-sizing: border-box;
				padding: 3px;
				&:not(:last-child) {
					margin-bottom: 3px;
				}
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				> b {
					&:first-child {
						font-weight: bold;
					}
					&:last-child {
						font-size: 18px;
					}
				}
			}
			> .buttons {
				margin-top: 10px;
				background-color: rgba(0, 0, 0, 0.1);
				padding: 5px;
				display: flex;
				align-items: flex-end;
				flex: 1;
				> .na {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: row;
					height: 40px;
					flex: 1;
				}
				> button {
					display: flex;
					align-items: center;
					flex-direction: row;
					// flex: 1;
					height: 40px;
					> b {
						margin-left: 5px;
					}
				}
			}
		}
	}
}
