.tabComponent {
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	@include themify($themes) {
		background-color: themed("diceOutline");
	}
	padding: 0.5px;
	box-sizing: border-box;
	flex-wrap: wrap;
	> .tab {
		position: relative;
		overflow-x: hidden;
		display: flex;
		// flex: 1;
		flex-grow: 1;
		> button {
			margin: 0.5px;
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			flex: 1;
			border-radius: 0;
			font-size: 14px;
			height: 35px;
			padding: 0 10px;
			white-space: nowrap;
			user-select: none;
			background-image: linear-gradient(45deg, transparent, #6c28f15c, 33%, transparent 100%);
			> .icon-ca {
				font-size: 22px;
			}
			> :not(:last-child) {
				margin-right: 5px;
				margin-left: 0px;
			}
			&.untoggled {
				opacity: 0.8;
			}
			&.untoggled > i,
			&.untoggled > b {
				transform: scale(0.8);
			}
		}
		> .tabLine {
			position: absolute;
			z-index: 1;
			bottom: 0;
			height: 1px;
			width: 100%;
			@include themify($themes) {
				background-color: themed("cgold");
			}
			background-image: linear-gradient(90deg, black, transparent, black);
			user-select: none;
			pointer-events: none;
			opacity: 0.7;
			transition: all 0.3s ease;
			left: -100%;
		}
		&.toggled {
			> .tabLine {
				left: 0;
			}
		}
	}
	> .line {
		transition: all 0.3s ease;
		position: absolute;
		bottom: 0;
		user-select: none;
		pointer-events: none;
		opacity: 0.7;
		height: 1px;
		@include themify($themes) {
			background-color: themed("cgold");
		}
	}
}
.tabComponent.tabSimple {
	background-color: rgba(255,255,255,0.05);
	padding: 5px;
	width: unset;
	align-self: self-start;
	gap: 10px;
	border-radius: 5px;
	margin: 5px;
	> .tab > button {
		border-bottom: unset;
		border-top: unset;
		margin: unset;
		background-color: transparent;
		background-image: unset;
		height: unset;
		padding: 6px;
		border-radius: 5px;
		&:active {
			border-bottom: unset;
		}
		> b {
			font-size: 14px;
			line-height: 14px;
			user-select: none;
			transform: unset;
		}
		&.untoggled {
			> b {
				@include themify($themes) {
					color: themed("shade05");
				}
			}
		}
		&.toggled {
			border-top: unset;
			@include themify($themes) {
				background-color: themed("shade005");
			}
		}
		@media (hover: hover) {
			&:hover {
				@include themify($themes) {
					background-color: themed("shade005", true);
				}
			}
		}
	}
}
