.bodyPane>.footer {
	position: relative;
	min-height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	box-sizing: border-box;
	font-size: 12px;
	.btnBuyCrypto {
		padding: 0 5px;
	}
	>.bgCityWrapper {
		position: absolute;
		top: -72px;
		height: 75px;
		width: 100%;
		overflow: hidden;

		// background-size: cover;
		// background-position: center;
		>svg {
			height: 100%;
			position: absolute;
			left: 50%;
			margin-left: -500px;
			width: 1000px;

			.bgCity {
				// fill: rgba(255, 255, 255, 0.1);
				stroke: transparent;

				// stroke-miterlimit: 10;
				@include themify($themes) {
					fill: themed("footerBackgroundColor");
				}
			}
		}
	}
	.seals {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
	.row {
		display: flex;
		flex-direction: row;
		gap: 5px;
	}
	figure {
		margin: 2.5px 0;
		text-align: center;

		&.builtCoins {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			>img {
				cursor: pointer;
				margin: 2px;
				width: 32px;
				height: 32px;
				transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

				@media (hover: hover) {
					&:hover {
						transform: scale(1.1);
					}
				}
			}
		}

		&.whoIsOnline {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			>.entry {
				display: flex;
				align-items: center;
				margin: 5px;

				>label {
					&.tag {
						margin-right: 5px;
					}

					&.num {
						padding: 0 2px;
						font-weight: bold;
					}
				}
			}
		}

		>.sep {
			user-select: none;
		}

		>.time {
			font-family: "Roboto Mono";

			@include themify($themes) {
				color: themed("footerTimeColor");
			}
		}

		&.entityInfo {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			>p {
				display: block;
				text-align: center;
				margin: 2.5px 0;
			}
		}

		&.seal {
			>iframe {
				border: none;
				outline: none;
				border-radius: 10px;
			}
		}
	}

	@include themify($themes) {
		background-color: themed("footerBackgroundColor");
		border-top: themed("footerBorderTop");
		color: themed("footerColor");
	}
}

.bodyPane>.footer>.footerMap {
	width: 100%;
	box-sizing: border-box;
	padding: 5px 5px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;

	>.col {
		>*:not(:last-child) {
			margin-bottom: 3px;
		}

		padding: 5px;
		display: flex;
		width: fit-content;
		flex-direction: column;

		>h3 {
			font-weight: bold;
			font-size: 14px;
		}
	}
}