.slideNavigation {
	max-width: 1310px;
	width: calc(100% - 10px);
	position: relative;
	z-index: 1;
	margin-top: -30px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center; // space-around;
	background-color: rgba(0, 0, 0, 0.3);
	$offset: 10px;
	padding: $offset/2;

	@mixin absolute {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}

	@mixin radius {
		border-radius: 20px;
		border-bottom-left-radius: 0;
		border-top-right-radius: 0;
	}

	@include radius();

	a {
		margin: $offset/2;
		outline: none;
		$h: 150px;
		min-width: 130px;
		max-width: 270px;
		flex:1;
		display: flex;
		align-items: center;
		justify-content: center;

		>.slide {
			backface-visibility: hidden;

			// mask-image: linear-gradient(90deg, transparent, white 3%, white 97%, transparent);
			&:after {
				content: "";
				@include absolute;
				@include radius();
				border-bottom: 0.15em solid;
				border-image: linear-gradient(90deg, transparent 3%, silver, transparent 93%) 1;
				z-index: 1;
				box-sizing: border-box;
				user-select: none;
				pointer-events: none;
			}

			transition: all 0.3s ease-in-out;

			>svg.strip {
				position: absolute;
				width: 100%;
				height: 100%;
				fill: rgba(255, 255, 255, 0.5);
				pointer-events: none;
				left: -100%;
				transition: all 0.5s ease-in-out;
				z-index: 1;
			}

			@media (hover: hover) {
				&:hover {
					// transform: translate3d(0, -$offset * 2, 0) scale(1, 1) !important;
					transform: translateY(-$offset * 2) !important;

					>.bg {
						filter: grayscale(100%);
					}

					>svg.strip {
						left: 100%;
					}
				}
			}

			box-sizing: border-box;
			overflow: hidden;

			cursor: pointer;
			position: relative;
			// margin: $offset/2;
			// $h: 150px;
			display: flex;
			flex-direction: column;

			// min-width: 130px;
			// max-width: 270px;

			// height: $h;
			&.chatOpen {
				@media (max-width: (850px + $chatPaneWidth)) {
					// height: 70px;
					min-width: 120px;
					max-width: 200px;

					>.description {
						font-size: 18px;
					}
				}
			}

			&.chatClosed {
				@media (max-width: 850px) {
					// height: 70px;
					min-width: 120px;
					max-width: 200px;

					>.description {
						font-size: 18px;
					}
				}
			}

			flex: 1;
			// min-width: 250px;

			// max-width: 250px;
			@include radius();
			overflow: hidden;

			@include themify($themes) {
				// background-color: themed("profilePlayerBackgroundColor");
				color: themed("profilePlayerColor");
			}

			>.bg {
				backface-visibility: hidden;

				// background-position: center;
				background-repeat: no-repeat;
				// background-size: contain;
				// background-size: cover;
				transition: all 0.5s ease-in-out;
				filter: grayscale(0%);
				height: 0;
				padding-bottom: 72%;

				>div {
					position: absolute;
					left: 0; //15px;
					right: 0; //15px;
					top: 0; //15px;
					bottom: 0; //15px;

					>img {
						backface-visibility: hidden;
						object-fit: cover;
						object-position: center;
						width: 100%;
						height: 100%;
					}
				}
			}

			>.description {
				backface-visibility: hidden;
				@include absolute;
				z-index: 1;
				// left: 10px;
				// right: 10px;
				font-size: 25px;
				font-weight: bold;
				display: flex;
				align-items: flex-end;
				justify-content: center;
				user-select: none;
				pointer-events: none;
				// text-shadow: 0px 0px 5px rgba(0, 0, 0, 1), 0px 0px 25px rgba(0, 0, 0, 0.5);
				text-shadow: 0 3px 1px rgb(100, 100, 110), 0 0 10px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.3);
				// background-image: linear-gradient(0deg, #0e0e4c 20%, rgba(0, 0, 0, 0) 100%);

				// @include themify($themes) {
				// 	background-image: themed("upTransparent");
				// }
				padding: 10px 0;
			}
		}
	}
}