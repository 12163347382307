.diceRollInfo {
	> .generalBetInfo {
		margin-bottom: 6px;
	}
	box-sizing: border-box;
	min-width: 300px;
	min-height: 400px;
	&.tall {
		height: calc(100vh - 100px);
	}
	@media (min-width: 900px) {
		width: 500px;
	}
	padding: 5px;
	display: flex;
	flex-direction: column;
	> header {
		box-sizing: border-box;
		padding: 5px;
		margin-top: 5px;
		margin-bottom: 5px;
		margin-right: 45px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		> .ib {
			display: inline-block;
			.win {
				margin-left: 5px;
				padding: 5px;
				border-radius: 5px;
				font-weight: bold;
				user-select: none;
				color: black;
				display: inline-block;
				@include themify($themes) {
					&.success {
						background-color: themed("success");
					}
					&.alert {
						background-color: themed("alert");
					}
				}
			}
		}
		> .time {
			font-family: "Roboto Mono";
			white-space: nowrap;
			> .d {
				margin-right: 5px;
				color: black;
				font-weight: bold;
				padding: 0 3px;
				border-radius: 3px;
				@include themify($themes) {
					background-color: themed("warn");
				}
			}
		}
	}
	> section {
		.gold {
			color: gold;
		}
		@include themify($themes) {
			.success {
				color: themed("success");
			}
			.alert {
				color: themed("alert");
			}
		}
		margin-bottom: 5px;
		box-sizing: border-box;
		padding: 1px;
		@include themify($themes) {
			background-color: themed("dark");
		}
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		border-radius: 5px;
		&.player > .block {
			height: 48px;
		}
		> .block {
			flex: 1;
			&.photo {
				flex: unset;
			}
			&.num {
				width: 50px;
				flex: unset;
			}
			border-radius: 3px;
			margin: 1px;
			padding: 3px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@include themify($themes) {
				background-color: themed("shade01");
			}
			> label {
				font-size: 13px;
				font-weight: 500;
				@include themify($themes) {
					color: themed("profileGeoLabelColor");
				}
				margin-bottom: 3px;
			}
			> b {
				font-size: 18px;
				font-family: "Roboto Mono";
				> .z {
					opacity: 0.5;
					@include themify($themes) {
						color: themed("modalColor");
					}
				}
			}
			&.trid {
				padding: 0;
				height: 54px;
				> .diceBtn {
					flex-direction: row;
					padding: 0 5px;
					> b {
						margin-left: 5px;
						word-break: break-all;
						font-size: 11px;
					}
				}
			}
		}
		&.seeds {
			> .diceBtn {
				height: 54px;
				> b {
					font-size: 16px;
				}
				&.green {
					flex-direction: row;
				}
			}
		}
		&.filter {
		}
		&.trs {
			flex: 1;
			overflow: auto;
			.roll {
				display: flex;
				height: 70px;
				margin-bottom: 1px;
				> .c {
					display: flex;
					flex: 1;
					&.pa {
						flex: unset;
						width: 80px;
					}
					&.coin {
						flex: unset;
						width: 34px;
					}
					&.shareBtn {
						flex: unset;
						width: 50px;
						> button {
							width: 40px;
							height: 40px;
							flex: unset;
							border-radius: 5px;
						}
					}
					&.bars {
						position: relative;
						min-width: 70px;
						> .strip {
							> label {
								position: absolute;
								bottom: 17px;
								text-align: center;
								width: 100%;
								font-size: 11px;
							}
							background-color: #000;
							width: calc(100% - 10px);
							// border-radius: 5px;
							position: relative;
							padding: 2px;
							.red {
								@include themify($themes) {
									background-color: themed("alert");
								}
							}
							.green {
								@include themify($themes) {
									background-color: themed("success");
								}
							}
							> .stripFill {
								height: 10px;
								// border-radius: 5px;
							}
							> .outcomeWrapper {
								position: absolute;
								width: 1px;
								> .outcome {
									position: relative;
									margin-top: 15px;
									width: 50px;
									margin-left: -25px;
									height: 20px;
									border-radius: 5px;
									user-select: none;
									font-size: 16px;
									font-weight: 700;
									display: flex;
									align-items: center;
									justify-content: center;
									color: #000;
								}
							}
						}
					}
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 0 3px;
					&:not(:last-child) {
						margin-right: 1px;
					}
					@include themify($themes) {
						&:not(button) {
							background-color: themed("shade01");
						}
					}
					> b,
					> div > b {
						font-weight: bold;
						font-family: "Roboto Mono";
						&:not(:last-child) {
							margin-bottom: 3px;
						}
					}
					&.bet {
						> div {
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							&.sep {
								height: 1px;
								margin: 0 3px;
								max-width: 150px;
								@include themify($themes) {
									background-color: themed("modalColor");
								}
							}
							> b {
								&:first-child {
									min-width: 70px;
									text-align: right;
									margin-right: 5px;
								}
								@media (max-width: 560px) {
									&:first-child {
										display: none;
									}
								}
								&:last-child {
									min-width: 90px;
									text-align: left;
								}
								// font-size: 18px;
								> .z {
									opacity: 0.8;
									font-weight: normal;
									@include themify($themes) {
										color: themed("modalColor");
									}
								}
							}
						}
					} //bet
				}
			}
		}
	}
}
