.menuOverlay {
	position: fixed;
	z-index: 20;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	transition: background-color 0.3s ease;
	&.off {
		background-color: rgba(0, 0, 0, 0);
		pointer-events: none;
		.menu {
			width: 0px;
			pointer-events: none;
		}
	}
	&.on {
		.menu {
			width: 300px;
		}
	}
	.menu {
		cursor: default;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		overflow: hidden;
		transition: width 0.3s ease;

		@include themify($themes) {
			background-color: themed("menuBackgroundColor");
			color: themed("menuColor");
		}
		> .content {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 300px;
			> figure {
				position: relative;
				padding: 5px 10px;
				box-sizing: border-box;
				@include themify($themes) {
					border-bottom: themed("menuFigureBorderBottom");
				}
				&.header {
					display: flex;
					align-items: center;
					height: $headerHeight;
					.btnHeader {
						&:not(:hover):not(.selected):not(:active) {
							@include themify($themes) {
								background-color: themed("menuBackgroundColor");
								color: themed("menuColor");
							}
						}
						&:not(.selected):not(:active) {
							@media (hover: hover) {
								@include themify($themes) {
									background-color: themed("menuHeaderBackgroundColorHover");
									color: themed("menuHeaderColorHover");
								}
							}
						}
					}
					.menuDropHeader {
						display: flex;
						align-items: center;
						> .avatar {
							position: relative;
							width: 38px;
							height: 38px;
							background-size: contain;
							margin: auto;
							@include themify($themes) {
								background-color: themed("menuAvatarBackgroundColor");
							}
							border-radius: 5px;
							background-position: center;
							background-repeat: no-repeat;
							> .onlineStatus {
								position: absolute;
								z-index: 2;
								bottom: -8px;
								left: 12px;
								cursor: pointer;
							}
						}
						> label {
							margin-left: 10px;
							flex: 1;
							font-size: 16px;
							font-weight: bold;
						}
						// > .info {
						//   flex: 1;
						//   margin-left: 10px;
						//   display: flex;
						//   flex-direction: column;
						//   > a {
						//     font-size: 12px;
						//     display: block;
						//     margin-top: 5px;
						//   }
						// }
					}
					> .btnClose {
						background-color: transparent;
					}
					.headerContent {
						.sep {
							margin-left: 10px;
							width: calc(100% - 20px);
							height: 1px;
							@include themify($themes) {
								background-color: themed("menuStatusSeparatorColor");
							}
						}
						a {
							outline: none;
							display: flex;
							align-items: center;
							padding: 10px;
							> label {
								margin-left: 10px;
								pointer-events: none;
							}
						}
					}
				}
				&.footer {
					height: $headerHeight;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					> a {
						outline: none;
						cursor: pointer;
						display: flex;
						align-items: center;
						> label {
							margin-left: 10px;
						}
						> i,
						> label {
							pointer-events: none;
						}
					}
				}
			}
			> .scrollable {
				position: relative;
				width: 100%;
				height: calc(100% - #{$headerHeight * 2});
				figure {
					padding: 5px 10px;
					> .blockHead {
						cursor: pointer;
						display: flex;
						align-items: center;
						> h3 {
							flex: 1;
							// font-size: 12px;
						}
						button {
							background-color: transparent;
						}
					}
					> .blockItems {
						overflow-y: hidden;
						transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
						opacity: 1;
						&.off {
							opacity: 0;
							> .content {
								pointer-events: none;
							}
						}
						> .content {
							display: flex;
							flex-direction: column;
							box-sizing: border-box;
							padding: 0 10px;
							> a {
								// outline: none;
								display: flex;
								height: 30px;
								align-items: center;
								text-decoration: none;
								> label {
									margin-left: 10px;
									pointer-events: none;
								}
								> img {
									height: 24px;
								}
							}
						}
					}
					@include themify($themes) {
						border-bottom: themed("menuFigureBorderBottom");
					}
				}
			}
		}
	}
}
