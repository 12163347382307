.betList {
	width: 100%;
	margin-top: 10px;
	> .wrapper {
		width: 100%;
		> ._rolls {
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			> .roll {
				max-width: 769px;
				width: 100%;
				display: flex;
				height: 70px;
				margin-bottom: 1px;
				> .c {
					display: flex;
					flex: 1;
					&.pa {
						flex: unset;
						width: 100px;
					}
					&.coin {
						flex: unset;
						width: 34px;
					}
					&.shareBtn {
						flex: unset;
						width: 50px;
						> button {
							width: 40px;
							height: 40px;
							flex: unset;
							border-radius: 5px;
						}
					}
					&.bars {
						position: relative;

						background-color: rgba(0, 0, 0, 0.3);
						height: 55px;
						display: flex;
						flex-direction: row;
						> .img {
							background-color: gray;
							width: 82px;
							height: 100%;
						}
						> .stripW {
							flex:1;
							display: flex;
							flex-direction: column;
							justify-content: center;
							> .strip {
								> label {
									position: absolute;
									bottom: 17px;
									text-align: center;
									width: 100%;
									font-size: 11px;
									display: flex;
									align-items: center;
									justify-content: space-around;
									> .pa {
										margin-left: 5px;
										font-weight: bold;
										@include themify($themes) {
											color: themed("_cyan");
										}
									}
								}
								background-color: #000;
								width: calc(100% - 40px);
								// border-radius: 5px;
								position: relative;
								padding: 2px;
								.red {
									@include themify($themes) {
										background-color: themed("alert");
									}
								}
								.green {
									@include themify($themes) {
										background-color: themed("success");
									}
								}
								> .stripFill {
									height: 10px;
									// border-radius: 5px;
								}
								> .outcomeWrapper {
									position: absolute;
									width: 1px;
									> .outcome {
										position: relative;
										margin-top: 15px;
										width: 50px;
										margin-left: -25px;
										height: 20px;
										border-radius: 5px;
										user-select: none;
										font-size: 16px;
										font-weight: 700;
										display: flex;
										align-items: center;
										justify-content: center;
										color: #000;
									}
								}
							}
						}
					}
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 0 3px;
					&:not(:last-child) {
						margin-right: 1px;
					}
					@include themify($themes) {
						&:not(button) {
							background-color: themed("shade005");
						}
					}
					> b,
					> div > b {
						font-weight: bold;
						font-family: "Roboto Mono";
						&:not(:last-child) {
							margin-bottom: 3px;
						}
					}
					&.bet {
						> div {
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							&.sep {
								height: 1px;
								margin: 0 3px;
								max-width: 150px;
								@include themify($themes) {
									background-color: themed("modalColor");
								}
							}
							> b {
								&:first-child {
									min-width: 70px;
									text-align: right;
									margin-right: 5px;
								}
								@media (max-width: 560px) {
									&:first-child {
										display: none;
									}
								}
								&:last-child {
									min-width: 90px;
									text-align: left;
								}
								// font-size: 18px;
								> .z {
									opacity: 0.8;
									font-weight: normal;
									@include themify($themes) {
										color: themed("modalColor");
									}
								}
							}
						}
					} //bet
				}
			}
		}
	}
}
.widgets .betList > .wrapper > ._rolls > .roll {
	max-width: 1300px;
}
.chatOn {
	@media (max-width: ($chatPaneWidth + 500px)) {
		.betList > .wrapper > ._rolls > .roll > .c.pa {
			display: none;
		}
		.betList > .wrapper > ._rolls > .roll > .c.bet > div > b:first-child {
			display: none;
		}
	}
}
.chatOff {
	@media (max-width: 500px) {
		.betList > .wrapper > ._rolls > .roll > .c.pa {
			display: none;
		}
		.betList > .wrapper > ._rolls > .roll > .c.bet > div > b:first-child {
			display: none;
		}
	}
}
