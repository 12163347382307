.countryMgr {
  > *:not(:last-child),
  > header > h2 {
    margin-bottom: 10px;
  }
  .countries {
    display: flex;
    flex-wrap: wrap;
  }
  .btnCountry {
    flex: unset;
    padding: 7px;
    padding-right: 25px;
    min-width: 120px;
    margin: 2px;
    > .abs_layer {
      align-items: center;
      justify-content: flex-end;
      padding-right: 3px;
      > button {
        height: 24px;
        width: 24px;
        pointer-events: all;
        padding: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: unset;
        > i {
          padding: unset !important;
        }
      }
    }
  }
}
