.toggleV {
	min-width: 100px;
	flex: 1;
	display: flex;
	align-items: center;
	&.v {
		flex-direction: column;
		> .caption {
			&.active {
				padding: 3px;
			}
		}
	}
	&.h {
		flex-direction: row;
		height: 30px;
		min-height: 30px;
		max-height: 30px;
		> .caption {
			line-height: 0;
		}
	}
	justify-content: center;
	cursor: pointer;
	user-select: none;
	height: 45px;
	margin: 0 2px;
	box-sizing: border-box;
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
	> .caption {
		box-sizing: border-box;
		width: 100%;
		text-align: center;
		cursor: pointer;
		display: inline-block;
		transition: all 0.15s ease;
		&.active {
			font-size: 18px;
		}
		&.pasive {
			font-size: 10px;
		}
	}
}
