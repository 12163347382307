input,
textarea {
	font-family: "Montserrat", "BPG Glaho WEB Caps", sans-serif;
	font-weight: 500;
	touch-action: pan-y;
	position: relative;
	padding: 10px;
	border-radius: 5px;
	width: 100%;
	outline: none;
	box-sizing: border-box;
	@include themify($themes) {
		border: themed("inputBorder");
	}
	&:disabled {
		opacity: 0.5;
		pointer-events: none;
		user-select: none;
	}
	&:not([type="range"]) {
		@include themify($themes) {
			background-color: themed("inputBackgroundColor");
			color: themed("inputColor");
			box-shadow: themed("inputBoxShadow");
		}
	}
	&::selection,
	&-moz::selection,
	&-webkit::selection {
		@include themify($themes) {
			background-color: themed("inputSelectionBackgroundColor");
			color: themed("inputSelectionColor");
		}
	}
}
textarea {
	resize: none;
}
