// @import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:400,700");
// @import url("https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Overpass+Mono&display=swap");
// @import url("/assets/fonts/css/fontelico.css");
// * {
// 	outline: none !important;
// 	&:focus {
// 		outline: none !important;
// 	}
// }
@font-face {
	font-family: "casino";
	src: url("/assets/fonts/casino/font/casino.eot?30068806");
	src: url("/assets/fonts/casino/font/casino.eot?30068806#iefix") format("embedded-opentype"),
		url("/assets/fonts/casino/font/casino.woff?30068806") format("woff"), url("/assets/fonts/casino/font/casino.ttf?30068806") format("truetype"),
		url("/assets/fonts/casino/font/casino.svg?30068806#casino") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
.icon-ca {
	font-size: 24px;
	font-family: "casino";
	font-style: normal;
	font-weight: normal;
	// speak: never;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-decoration: unset;
	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;
	display: inline-block;

	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
html,
body {
	touch-action: pan-y;
	font-family: "Montserrat", "BPG Glaho WEB Caps", sans-serif;
	font-weight: 500;
	top: 0 !important;
	height: 100%;
	margin: 0 0 0 0;
	padding: 0 0 0 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	min-width: 320px;
	scroll-behavior: smooth;
}
.mono {
	font-family: "Roboto Mono";
}
code {
	font-family: "Roboto Mono";
	box-sizing: border-box;
	display: block;
}
#root {
	height: 100%;
	> .theme {
		height: 100%;
	}
}
a,
button.btnLink,
button.btnA {
	user-select: none;
	cursor: pointer;
	transition: color 0.3s ease;
	@include themify($themes) {
		color: themed("linkColor");
		&:visited {
			color: themed("linkColor");
		}
		@media (hover: hover) {
			&:hover {
				color: themed("linkColorHover");
			}
		}

		&:active {
			color: themed("linkColorActive");
		}
		text-decoration: underline;
	}
}
button.btnLink > * {
	pointer-events: none;
}
.grecaptcha-badge {
	box-shadow: none !important;
	width: auto !important;
	display: flex;
	justify-content: center;
	margin-bottom: 5px;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
	/* chromes and blink button */
	cursor: pointer;
}

::-webkit-scrollbar {
	// width: 14px;
	@include themify($themes) {
		background-color: themed("scrollBackgroundColor");
	}
}
::-webkit-scrollbar-thumb {
	@include themify($themes) {
		background-color: themed("thumbBackgroundColor");
	}
	cursor: pointer;
	border-radius: 4px;
}
::-webkit-scrollbar-track {
	width: 14px;
	background-clip: content-box; /* THIS IS IMPORTANT */
}
.toast-msg {
	font-family: "Montserrat", "BPG Glaho WEB Caps", sans-serif;
}
.Toastify__toast {
	border-radius: 6px;
}
.toast-default {
	@include themify($themes) {
		background-color: themed("toastBackgroundColorDefault");
		color: themed("toastColorDefault");
	}
}
.toast-success {
	@include themify($themes) {
		background-color: themed("toastBackgroundColorSuccess");
		color: themed("toastColorSuccess");
	}
}
.toast-info {
	@include themify($themes) {
		background-color: themed("toastBackgroundColorInfo");
		color: themed("toastColorInfo");
	}
}
.toast-error {
	@include themify($themes) {
		background-color: themed("toastBackgroundColorError");
		color: themed("toastColorError");
	}
}
.toast-warning {
	@include themify($themes) {
		background-color: themed("toastBackgroundColorWarning");
		color: themed("toastColorWarning");
	}
}

.ct-chart {
	.ct-grids {
		> .ct-grid {
			@include themify($themes) {
				stroke: themed("warn");
			}
			stroke-dasharray: 5px 5px;
			&:first-child,
			&:last-child {
				opacity: 0;
			}
		}
	}
	.ct-labels {
		font-weight: 500;
	}
	.ct-label {
		font-family: "Roboto Mono";
		@include themify($themes) {
			color: themed("linkColor");
			fill: themed("success");
		}
	}
	// .ct-labels > foreignObject {
	// 	&:first-child,
	// 	&:last-child {
	// 		opacity: 0;
	// 	}
	// }
}
.rdw-dropdown-wrapper,
.rdw-editor-toolbar,
.rdw-image-modal,
.rdw-emoji-modal,
.rdw-dropdown-optionwrapper,
.rdw-dropdownoption-default,
.rdw-dropdownoption-active,
.rdw-dropdownoption-highlighted,
.rdw-embedded-modal:not(.rdw-fontsize-dropdown) {
	@include themify($themes) {
		background: themed("containerBackgroundColor");
		color: themed("containerColor");
	}
	box-shadow: unset;
}
.rdw-dropdownoption-default {
	opacity: 0.7;
}
.rdw-dropdownoption-highlighted {
	opacity: 1;
}
.rdw-dropdownoption-active {
	@include themify($themes) {
		color: themed("warn");
	}
}
._mask {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	box-sizing: border-box;
}
.emoji-mart {
	width: 100%;
	@include themify($themes) {
		background-color: themed("shade005");
		border-color: themed("emojiMartBorderColor");
	}
	// .emoji-mart-bar {
	// }
	.emoji-mart-search {
		> input {
			box-sizing: border-box;
			margin-bottom: 5px;
		}
	}
	.emoji-mart-scroll {
		flex: 1;
		height: auto;
		> .emoji-mart-category {
			> .emoji-mart-category-label {
				> span {
					@include themify($themes) {
						background-color: themed("chatPaneBackgroundColor", true);
						color: themed("containerColor", true);
					}
				}
			}
			> .emoji-mart-category-list {
				> li {
					button.emoji-mart-emoji {
						> span {
							pointer-events: none;
						}
					}
				}
			}
		}
	}
	.emoji-mart-bar {
		@include themify($themes) {
			border-color: themed("emojiMartBorderColor");
		}
		&:last-child {
			display: none;
		}
	}
}
.container {
	padding-bottom: 70px;
}
.abs_layer {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	box-sizing: border-box;
	display: flex;
}
h1 {
	font-weight: bold;
	font-size: 18px;
	padding: 10px;
	z-index: 1;
	position: relative;
	line-height: 10px;
}
.imgCached {
	@keyframes imgCachedAnim {
		from {
			opacity: 0;
			transform: scale(0);
		}
		to {
			opacity: 1;
			transform: scale(1);
		}
	}
	// transition: all 0.3s ease-in;
	&.loading {
		// transition: all 0 ease-in;
		opacity: 0;
		transform: scale(0);
	}
	&.loaded {
		// opacity: 1;
		// transform: scale(1);
		animation: imgCachedAnim 0.15s 1;
		// animation-delay: 0.1s;
	}
}
.flex1 {
	flex: 1;
}
.flexHRight {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}
.flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}
.w100 {
	width: 100%;
}
.p5_left {
	padding-left: 5px;
}
.p5_left_i {
	padding-left: 5px !important;
}

/* common */
.ribbon {
	pointer-events: none;
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: absolute;
	z-index: 1;
	&::before,
	&::after {
		position: absolute;
		z-index: 0;
		pointer-events: none;
		content: "";
		display: block;
		border-width: 5px;
		border-style: solid;
	}
	span {
		position: absolute;
		display: block;
		width: 225px;
		padding: 15px 0;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
		color: #fff;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
		text-transform: uppercase;
		text-align: center;
		font-weight: bold;
    text-shadow: 0 0 10px #000;
	}
	&.blue {
		$c: rgba(40, 127, 184, 0.6);
		&::before,
		&::after {
			border-color: $c;
		}
		span {
			color: #fff;
			background-color: $c;
		}
	}
	&.cyan {
		$c: rgba(0, 217, 255, 0.5);
		&::before,
		&::after {
			border-color: $c;
		}
		span {
			background-color: $c;
		}
	}
	&.limegreen {
		$c: rgba(0, 255, 47, 0.5);
		&::before,
		&::after {
			border-color: $c;
		}
		span {
			background-color: $c;
		}
	}
	&.red {
		$c: rgba(255, 0, 0, 0.5);
		&::before,
		&::after {
			border-color: $c;
		}
		span {
			background-color: $c;
		}
	}
	&.goldenrod {
		$c: rgba(255, 213, 0, 0.5);
		&::before,
		&::after {
			border-color: $c;
		}
		span {
			background-color: $c;
		}
	}
	&.gold {
		$c: rgb(230, 191, 0);
		&::before,
		&::after {
			border-color: $c;
		}
		span {
			background-color: $c;
		}
	}
}

/* top left*/
.ribbon-top-left {
	top: -10px;
	left: -10px;
	&.scale08 {
		scale: 0.8;
		transform-origin: 10px 10px;
	}
}
.ribbon-top-left::before,
.ribbon-top-left::after {
	border-top-color: transparent;
	border-left-color: transparent;
}
.ribbon-top-left::before {
	top: 0;
	right: 0;
	width: 55px;
	border-top-right-radius: 5px;
}
.ribbon-top-left::after {
	bottom: 0;
	left: 0;
	height: 55px;
	border-bottom-left-radius: 5px;
}
.ribbon-top-left span {
	right: -25px;
	top: 30px;
	transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
	top: -10px;
	right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
	border-top-color: transparent;
	border-right-color: transparent;
}
.ribbon-top-right::before {
	top: 0;
	left: 0;
}
.ribbon-top-right::after {
	bottom: 0;
	right: 0;
}
.ribbon-top-right span {
	left: -25px;
	top: 30px;
	transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
	bottom: -10px;
	left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
	border-bottom-color: transparent;
	border-left-color: transparent;
}
.ribbon-bottom-left::before {
	bottom: 0;
	right: 0;
}
.ribbon-bottom-left::after {
	top: 0;
	left: 0;
}
.ribbon-bottom-left span {
	right: -25px;
	bottom: 30px;
	transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
	bottom: -10px;
	right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
	border-bottom-color: transparent;
	border-right-color: transparent;
}
.ribbon-bottom-right::before {
	bottom: 0;
	left: 0;
}
.ribbon-bottom-right::after {
	top: 0;
	right: 0;
}
.ribbon-bottom-right span {
	left: -25px;
	bottom: 30px;
	transform: rotate(-225deg);
}
