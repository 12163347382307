.flappyBet {
	.gamelist {
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
		justify-content: center;

		.counter {
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.4);
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 10;
			height: 20px;
			font-size: 10px;
			padding: 0 5px;
			border-bottom-right-radius: 10px;
			gap: 4px;
		}

		.game {
			cursor: pointer;
			background-color: steelblue;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 130px;
		}
	}
}

.flappyBetGame {
	flex-direction: column;
	display: flex;
	align-items: center;

	.btnFlappyExpand {
		min-height: unset !important;
		width: 24px;
		height: 24px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
	}

	#iframeFlappy {
		&.expanded {
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 50;
			height: 100% !important;
		}
	}
}