.quickNav {
	margin: 5px 0;
	display: flex;
	max-width: 1310px;
	width: calc(100% - 10px);
	align-items: center;
	justify-content: center;
	background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
	padding: 5px;
	border-radius: 5px;
	gap: 20px;

	>button.btnNav {
		position: relative;
		min-width: 80px;
		min-height: 80px;
		background-image: linear-gradient(90deg, #f128e2 0%, #e39875 100%);
		border-radius: 7px;
		transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;

		@media (hover: hover) {
			&:hover {
				filter: brightness(1.2);
				transform: scale(1.05);
			}
		}

		.vipStatus {
			position: absolute;
			padding: 3px;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			color: white;
			z-index: 2;
			background-color: rgba(0, 0, 0, 0.3);
		}
		
		&.btnFortuneWheel {
			>.imgWrapper {
				opacity: 0;
				@include mixinScaleFadeIn;
				animation-delay: 0.3s;
				animation-duration: 1s;
			}

			&.btnRakeback {

				// > .imgWrapper {
				// 	> img.animRotateInf {
				// 		border-radius: 50%;
				// 		background-image: radial-gradient(cyan, cyan, violet,  transparent);
				// 	}
				// }
				>._mask {
					pointer-events: none;

					>div>svg {
						width: inherit !important;
					}
				}

				
			}

			>.arrow {
				position: absolute;
				right: 5px;
				top: 50%;
				width: 0px;
				height: 0px;
				z-index: 1;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				>.arrow-left {
					width: 0;
					height: 0;
					border-top: 5px solid transparent;
					border-bottom: 5px solid transparent;
					border-right: 15px solid #f1ffbb;
				}

				>.arrow-left-shadow {
					right: 0;
					top: -3px;
					position: absolute;
					width: 0;
					height: 0;
					border-top: 5px solid transparent;
					border-bottom: 5px solid transparent;
					border-right: 15px solid rgba(1, 17, 46, 0.622);
					z-index: -1;
					filter: blur(3px);
				}
			}
		}

		&.btnVipRequest {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		>.badge {
			position: absolute;
			right: -12px;
			top: -12px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			width: 24px;
			height: 24px;
			line-height: 0;
			background-color: rgb(27, 185, 66);
			border-radius: 50%;
			border: 2px solid #000;
			color: #fff;
			font-weight: bold;
		}
	}
}