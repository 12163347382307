$pandoraGameOffset: 10px;
.bodyPane.right {
	&.chatOn .pandora > div > .gameFrame:not(.expanded) {
		right: $pandoraGameOffset;
		left: $chatPaneWidth + $pandoraGameOffset;
	}
	&.chatOff .pandora > div > .gameFrame:not(.expanded) {
		right: $pandoraGameOffset;
		left: $pandoraGameOffset;
	}
}
.bodyPane.left {
	&.chatOn .pandora > div > .gameFrame:not(.expanded) {
		left: $pandoraGameOffset;
		right: $chatPaneWidth + $pandoraGameOffset;
	}
	&.chatOff .pandora > div > .gameFrame:not(.expanded) {
		left: $pandoraGameOffset;
		right: $pandoraGameOffset;
	}
}
.pandora {
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
	> div {
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		width: 100%;
		padding: 20px 0;
		position: relative;
		> .gameFrame {
			position: fixed;
			transition: transform 0.3s ease;
			&.off {
				transform: scale(0);
				pointer-events: none;
			}
			&.on {
				transform: scale(1);
				pointer-events: auto;
			}
			&:not(.expanded) {
				top: $headerHeight + $pandoraGameOffset;
				bottom: $pandoraGameOffset;
				z-index: 1;
				padding: 3px;
				border-radius: 5px;
			}
			&.expanded {
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 10;
				padding: 0px;
				border-radius: 0px;
			}
			@include themify($themes) {
				background-color: themed("formBackgroundColor");
				color: themed("formColor");
			}

			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			&.on > .btns {
				display: flex;
			}
			&.off > .btns {
				display: none;
			}
			> .btns {
				position: absolute;
				z-index: 20;
				right: 10px;
				top: 10px;
				button > i {
					padding: 0 !important;
				}
				align-items: center;
				> *:not(:last-child) {
					margin-right: 5px;
				}
			}
			> iframe {
				border: none;
				width: 100%;
				height: 100%;
				&.on {
					pointer-events: auto;
				}
				&.off {
					pointer-events: none;
				}
			}
		}

		> .wrapper {
			width: 100%;
			box-sizing: border-box;
			max-width: 750px;
			> .games {
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				padding: 2.5px;
				> .game {
					transition: transform 0.3s ease;
					&.off {
						transform: scale(0);
					}
					&.on {
						transform: scale(1);
					}
					position: relative;
					box-sizing: border-box;
					flex: 1;
					min-width: 190px;
					max-width: 270px;
					margin: 2.5px;
					overflow: hidden;
					cursor: pointer;
					&:hover {
						> .bg {
							transform: scale(1.3);
							filter: brightness(1.2) contrast(1.1);
						}
					}
					&:not(:hover) > .bg {
						transform: scale(1);
						filter: brightness(1) contrast(1);
					}
					> .bg {
						transform-origin: center;
						transition: transform 0.3s ease, filter 0.3s ease;
						// background-size: cover;
						width: 100%;
						position: relative;
						height: 0;
						padding-bottom: 72%;
						> div {
							pointer-events: none;
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							> img {
								height: 100%;
								width: 100%;
								object-fit: cover;
							}
						}
					}
					> .content {
						position: absolute;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0;
						> h3 {
							box-sizing: border-box;
							position: absolute;
							font-size: 21px;
							font-weight: 600;
							bottom: 0;
							left: 0;
							right: 0;
							padding: 5px;
							text-align: center;
							user-select: none;
						}
					}
				}
			}
		}
	}
}
