.faucetTool {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 280px;
  margin: auto;

  > header {
    display: flex;
    align-items: center;
    > h2 {
      margin-left: 10px;
    }
  }
  > header,
  > main,
  > footer {
    padding: 10px;
    @include themify($themes) {
      background-color: themed("profileGeoBackgroundColor");
      color: themed("profileGeoColor");
    }
    margin-bottom: 10px;
  }
  > main {
		.faucetbalance {
			&.green {
				@include themify($themes) {
					color: themed("success");
				}
			}
			&.red {
				@include themify($themes) {
					color: themed("alert");
				}
			}
		}
    .btnClaim,
    .btnCancel {
      width: 100%;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .errorMessage {
      font-weight: bold;
      font-size: 18px;
      @include themify($themes) {
        color: themed("alert");
      }
    }
    .timeLeft {
      width: 100%;
      padding: 5px;
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      border-radius: 5px;
      @include themify($themes) {
        color: themed("alert");
        background-color: themed("dark");
      }
    }
  }
  > footer {
    display: flex;
    > .amounts {
      display: flex;
      flex-direction: column;
      width: 100%;
      > .limit {
        display: flex;
        align-items: center;
        width: 100%;
        > label:first-child {
          margin-right: 10px;
        }
        margin-bottom: 10px;
      }
      > .amount {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        > button {
          flex: 1;
          margin: 2px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
