.seoMgr {
	> *:not(:last-child) {
		margin-bottom: 10px;
	}
	> header {
		> *:not(:last-child) {
			margin-bottom: 10px;
		}
	}
	> main {
		.formGeneral > main > .item {
			min-width: 55%;
		}
		.formGeneral > main > .item > label {
			white-space: unset;
		}
		table {
			.act {
				.wrapper {
					display: flex;
					align-items: center;
					justify-content: center;
					> *:not(:last-child) {
						margin-right: 3px;
					}
				}
			}
			.btnDefault {
				padding: 0 !important;
				min-height: unset !important;
				> * {
					padding: 0 !important;
				}
			}
		}
	}
}
