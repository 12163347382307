.offline {
	width: 300px;
	> .bg {
		background-position: bottom;
		// &.buildOk {
		// 	background-image: url("/assets/offline.png");
		// }
		background-repeat: no-repeat;
		background-size: contain;
		height: 350px;
		position: relative;
		> .content {
			position: absolute;
			bottom: 50px;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			> .btns {
				display: flex;
				> button {
					&:not(:last-child) {
						margin-right: 5px;
					}
					&.btnReconnect,
					&.btnReload {
						padding: 0 10px;
					}
				}
			}
			> label {
				user-select: none;
			}
			> h2 {
				padding: 15px 0;
				@include themify($themes) {
					color: themed("alert");
				}
				font-size: 16px;
				font-weight: bold;
			}
		}
	}
	&.buildIncorrect {
		label {
			font-size: 30px;
			text-align: center;
			margin: 20px;
			@include themify($themes) {
				color: themed("warn");
			}
		}
		> .bg > .content {
			bottom: 85px;
		}
	}
}
