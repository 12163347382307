.modalOverlay {
	padding: 0;
	box-sizing: border-box;
	background: unset;
	background-color: rgba(0, 0, 0, 0.4);
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.modalBody {
	position: relative;
	animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1)!important;
	box-sizing: border-box;
	background-clip: border-box;
	background-color: transparent;
	border-radius: 10px;
	margin: 0;
	@include themify($themes) {
		color: themed("modalColor");
	}
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		@include themify($themes) {
			background-color: themed("modalBackgroundColor");
		}
		border-radius: 10px;
		opacity: 0.9;
		z-index: -1;
	}

	padding: 2px;
	> div {
		> .container {
			padding-bottom: unset !important;
		}
		> div {
			box-sizing: border-box;
		}
	}
	button.modalClose {
		min-height: auto;
		padding: 0 !important;
		opacity: 0.7;
		z-index: 1;
		> svg {
			padding: 0 !important;
		}
		transition: all 0.2s ease;
		background-color: transparent;
		@media (hover: hover) {
			&:hover {
				scale: 1.1;
				rotate: 180deg;
				background-color: transparent !important;
				opacity: 1;
			}
		}
		&:active {
			background-color: transparent !important;
			opacity: 0.8;
		}
		/* ----------- */
	}
}

.modalContent {
	padding: 10px;
	padding-top: 50px;
}
