.monitorMgr {
	> header,
	> main,
	> footer {
		margin-bottom: 10px;
	}
	> header {
		> h2 {
			font-weight: bold;
		}
	}
	.overDrop {
		width: 50px;
		> .btnHeader {
			min-height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		> .dropList {
			button {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				min-height: unset;
				height: 30px;
				&:not(:last-child) {
					margin-bottom: 3px;
					padding: 0 !important;
				}
			}
		}
	}
	._slot {
		.gid {
			flex: unset;
			margin: 3px;
			min-height: unset;
			height: 25px;
			margin-top: 0;
		}
	}
}
