.freespinsMgr {
	h2 {
		margin-bottom: 10px;
	}
	.btnCreate {
		padding: 0 10px;
		border-radius: 5px;
	}
}
.freespinsInfo {
	margin-bottom: 10px;
	.quantityWrapper {
		display: flex;
		flex-direction: column;
		.quantity {
			display: flex;
			> b {
				font-size: 22px;
				font-weight: bold;
				padding: 0 3px;
			}
		}
		.expired {
			.green {
				@include themify($themes) {
					color: themed("success");
				}
			}
			.red {
				@include themify($themes) {
					color: themed("alert");
				}
			}
		}
	}
	.act > .wrapper {
		display: flex;
		flex-direction: row;
		> * {
			&:not(:last-child) {
				margin-right: 5px;
			}
			padding: unset !important;
		}
	}
	.canceled {
		@include themify($themes) {
			color: themed("alert");
		}
	}
}
.freespinsTemplates {
	margin-bottom: 10px;
	.params {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.image {
		> img {
			width: 100px;
		}
	}
	.act {
		button {
			padding: 0 !important;
		}
	}
}
