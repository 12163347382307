.tip {
	> .wrapper {
		display: flex;
		flex-direction: column;
		padding: 10px 0;
		min-width: 280px;
		max-width: 750px;
		margin: auto;
		> * {
			border-radius: 5px;
		}
		> *:not(:last-child) {
			margin-bottom: 5px;
		}
		&.warning {
			> *:not(:last-child) {
				margin-bottom: 10px;
			}
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			padding: 30px;
			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
			}
			> h3.warn {
				@include themify($themes) {
					color: themed("warn");
				}
			}
			> h3.bonusName {
				@include themify($themes) {
					color: themed("warn");
				}
				font-size: 22px;
				font-weight: bold;
			}
			> button {
				> b {
					font-weight: bold;
					padding: 10px;
				}
			}
		}
		> header {
			display: flex;
			align-items: center;
			> h1 {
				margin-left: 10px;
			}
			padding: 10px;
			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
				color: themed("profileGeoColor");
			}
		}
		> main {
			padding: 10px;
			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
				color: themed("profileGeoColor");
			}
			> *:not(:last-child) {
				margin-bottom: 10px;
			}
			> label {
				display: flex;
				align-items: center;
				> b:not(:last-child) {
					margin-right: 5px;
					font-weight: bold;
					font-size: 18px;
				}
				> img {
					margin-left: 5px;
				}
			}
			> .toggleWrapper {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
			> .sendWrapper {
				> button {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			> .reason {
				display: flex;
				flex-direction: column;
				@include themify($themes) {
					color: themed("alert");
				}
			}
		}
	}
}
