.table {
  width: 100%;
  @mixin tbl {
    > table {
      width: 100%;
      font-size: 13px;
      table-layout: fixed;
      > thead {
        padding: 5px 3px;
        @include themify($themes) {
          background-color: themed("tableBackgroundColor");
        }
        > tr > td {
          vertical-align: middle;
        }
      }
      > tbody {
        tr:nth-child(2n) {
          @include themify($themes) {
            background-color: themed("tableEvenBackgroundColor");
          }
        }
        tr:nth-child(2n + 1) {
          @include themify($themes) {
            background-color: themed("tableOddBackgroundColor");
          }
        }
      }
      > thead > tr,
      > tbody > tr {
        > td {
          box-sizing: border-box;
          @include themify($themes) {
            border-right: themed("tableBorder");
          }
          padding: 5px;
          &.center {
            text-align: center;
            vertical-align: middle;
          }
          &:last-child {
            border-right: none;
          }
          &.green {
            @include themify($themes) {
              color: themed("tableGreenColor");
            }
          }
          &.red {
            @include themify($themes) {
              color: themed("tableRedColor");
            }
          }
        }
      }
    }
  }
  > .rows {
    min-width: 100%;
    overflow-x: scroll;
    @include tbl;
    > .row {
      display: flex;
    }
  }
}
