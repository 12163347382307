@keyframes customEnterOverlayAnimation {
	0% {
		transform: scale(0) translate(0, -100%);
	}
	100% {
		transform: scale(1) translate(0, 0%);
	}
}
@keyframes customLeaveOverlayAnimation {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

@keyframes customEnterModalAnimation {
	0% {
		transform: scale(0.2) translate(0, 0%);
	}
	100% {
		transform: scale(1) translate(0, 0%);
	}
}

@keyframes customLeaveModalAnimation {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(0);
		opacity: 0;
	}
}
