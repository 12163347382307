.affBuy {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 20px;
	box-sizing: border-box;
	h3 {
		font-weight: bold;
	}
	> .wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding-bottom: 10px;
		>.btnBuy1 {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
