.sportegrator {
	> .content {
		width: 100%;
		box-sizing: border-box;
		padding-right: 15px;
	}
}
#bettech {
	#bt-inner-page {
		@include themify($themes) {
			background-color: themed("containerBackgroundColor", true);
			color: themed("containerColor");
		}
	}
	// .bt34,
	// .bt145,
	// .bt360,
	// .bt353 {
	// 	@include themify($themes) {
	// 		background-color: themed("shade005", true);
	// 	}
	// 	border-color: transparent !important;
	// }
}
