.bank {
	> .wrapper {
		display: flex;
		flex-direction: column;
		padding: 10px 0;
		min-width: 280px;
		max-width: 750px;
		margin: auto;
		> * {
			border-radius: 5px;
		}
		> *:not(:last-child) {
			margin-bottom: 5px;
		}
		> main {
			padding: 10px;
			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
				color: themed("profileGeoColor");
			}
			> *:not(:last-child) {
				margin-bottom: 10px;
			}

			> button {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			> label {
				&.top50 {
					margin-top: -50px;
				}
				> b.left10 {
					margin-left: 10px;
				}
				display: flex;
				align-items: center;
				> b:not(:last-child) {
					margin-right: 5px;
					font-weight: bold;
					font-size: 18px;
				}
				> img {
					margin-left: 5px;
				}
				&.warn,
				> b.warn {
					@include themify($themes) {
						color: themed("warn");
					}
				}
			}
			> .sendWrapper {
				> button {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			> .reason {
				display: flex;
				flex-direction: column;
				@include themify($themes) {
					color: themed("alert");
				}
			}
		}
	}
}
