.profile {
	display: flex;
	flex-direction: column;
	padding: 20px;

	> .header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		> h1 {
			margin-bottom: 5px;
		}
		> label {
			font-size: 20px;
			font-weight: bold;
			padding: 5px;
			border-bottom-left-radius: 10px;
			@include themify($themes) {
				background-color: themed("profilePlayerBackgroundColor");
				color: themed("profilePlayerColor");
			}
		}
	}

	> .content {
		display: flex;
		flex-wrap: wrap;
		> .info > .bottomButtons {
			margin-top: 10px;
			display: flex;
			align-items: stretch;
			flex-wrap: wrap;
			> button {
				padding: 5px;
				margin: 5px;
				flex: 1;
				display: block;
				text-align: center;
			}
		}
		> .profilePicture {
			position: relative;
			margin: 10px;
			padding: 10px;
			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
				color: themed("profileGeoColor");
			}
		}
		> .geo,
		> .info {
			margin: 10px;
			padding: 10px;
			min-width: 250px;
			display: flex;
			flex: 1;
			flex-direction: column;
			font-size: 12px;
			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
				color: themed("profileGeoColor");
			}
			> .header {
				margin-bottom: 7px;
				display: flex;
				align-items: center;
				font-size: 14px;
				> h2 {
					margin-left: 7px;
					@include themify($themes) {
						background-color: themed("profileGeoHeaderBackgroundColor");
						color: themed("profileGeoHeaderColor");
					}
				}
			}
			> figure {
				display: flex;
				align-items: center;
				margin-bottom: 3px;
				> div {
					word-break: break-all;
				}
				> label {
					min-width: 120px;
					@include themify($themes) {
						background-color: themed("profileGeoLabelBackgroundColor");
						color: themed("profileGeoLabelColor");
					}
				}
			}
		}
	}
}
