.coins {
	> header {
		display: flex;
		align-items: center;
		> .btnAdd {
			margin-left: 10px;
		}
		margin-bottom: 10px;
	}
	> .frmAdd {
		margin-top: 10px;
	}
}
