.friendActionButton {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 5px;
	.simpleDropDown.actions {
		.btnHeader {
			height: 24px;
		}
		.dropList {
			width: 150px;
			.content {
				button {
					width: 100%;
					&:not(:first-child) {
						margin-top: 3px;
					}
				}
			}
		}
	}
}
