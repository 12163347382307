.affiliation,
.affProCampaigns,
.affProTotals {
	.formGeneral>footer {
		justify-content: flex-start;
	}

	.btnBuy,
	.btnUpd {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 38px;
	}

	position: relative;

	.bonusUsdTotal,
	.bonusUsd {
		>.size2 {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 5px;
		}
	}

	.size2 {
		font-size: 20px;

		@include themify($themes) {
			color: themed("success");

			&.warn {
				color: themed("warn");
			}

			&.white {
				color: white;
			}
		}
	}

	padding: 0 !important;

	td {
		height: 40px;
	}

	.btnAffProClaim {
		padding: 10px;
		font-size: 18px;
	}

	.block[data-name="unclaimed_profit"] {
		background-color: darkolivegreen !important;
	}

	.block[data-name="balance"] {
		border-bottom: 1px dashed white;
	}

	.block[data-name="percent"] {
		border-bottom: 1px dashed white;
	}

	.block[data-name="amount_claimed"] {
		border-bottom: 1px dashed white;
	}
}

.affiliation,
.affProTotals {
	display: flex;
	flex-direction: column;
	padding: 20px;

	@media (max-width: 400px) {
		padding: 20px 0;
	}

	padding-bottom: 70px;
	align-items: center;

	>* {
		box-sizing: border-box;
		max-width: 750px;

		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	>header {
		display: flex;
		align-items: center;

		>h1 {
			margin-left: 10px;
			font-size: 22px;
		}
	}

	>main {
		>h3 {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 10px;
		}

		>.block {
			display: flex;
			align-items: center;
			box-sizing: border-box;

			&:not(:last-child) {
				margin-bottom: 10px;
			}

			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
				color: themed("profileGeoColor");
			}

			padding: 10px;

			>.iconPercent,
			>.iconUsd {
				margin-left: 5px;
			}

			>.flex1 {
				flex: 1;
			}

			&.btn {
				justify-content: center;

				>button {
					padding: 0 25px;
				}
			}

			.btnUrl {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				word-break: break-all;
				font-size: 11px;
				padding: 5px 10px;
			}

			&.banner {
				padding: 10px;
				width: 100%;
				flex-direction: column;

				>* {
					box-sizing: border-box;

					&:not(:last-child) {
						margin-bottom: 5px;
					}
				}

				>h3 {
					margin-bottom: 10px;
				}

				>.bannerWrapper {
					width: 100%;
					padding: 5px;
					background-color: rgba(0, 0, 0, 0.1);
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 0px;

					>img {
						background-color: black;
						max-width: 100%;
						border-radius: 10px;
					}
				}

				>.btnUrl {
					width: 100%;
					padding: 5px 10px;
					font-size: 11px;
					word-break: break-all;
				}
			}
		}
	}

	table {
		.player>.wrapper {
			width: 100%;

			>button {
				width: 100%;
			}

			>.rank {
				text-align: left;
			}

			>.tim {
				text-align: left;
			}

			>.country {
				text-align: left;
			}
		}

		.info {
			>.rank {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: row;

				>* {
					margin-left: 5px;
				}
			}
		}
	}
}

.affiliation {
	&.affPro {
		padding: 0;

		.warn {
			@include themify($themes) {
				color: themed("warn");
			}

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	padding-bottom: 100px;

	main {
		width: 100%;
	}

	table {

		// .wrapper {
		// 	> .rank {
		// 		text-align: left;
		// 	}
		// 	> .tim {
		// 		text-align: left;
		// 	}
		// }
		.info {
			display: flex;
			flex-direction: column;
			justify-content: center;

			>div {
				display: flex;
				align-items: center;
				column-gap: 10px;

				>i {
					display: inline-block;
					flex: 1;
					text-align: right;
				}

				>b {
					flex: 1;
					display: inline-block;
					text-align: left;
				}
			}

			.green {
				@include themify($themes) {
					color: themed("success");
				}
			}

			.red {
				@include themify($themes) {
					color: themed("alert");
				}
			}
		}
	}
}

.afftoggle {
	min-width: 320px;
	padding: 20px;

	>* {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 20px;
		}
	}
}

.affProWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	>.affProTotals {
		width: 100%;
		max-width: 750px;
	}

	>.content {
		max-width: calc(100% - 20px);

		// max-width: 750px;
		>* {
			margin-top: 20px;
		}

		>header {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;

			>h1 {
				margin-left: 10px;
				font-size: 22px;
			}
		}
	}
}

.affProCampaigns {
	.tbWrapper {
		width: 100%;
		overflow-x: scroll;

		.row {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;
		}

		.link,
		.promoreg,
		.promoloy {
			button {
				>b {
					font-size: 12px;
				}

				padding: 5px;
				border-radius: 5px;
				margin: auto;
				width: 100%;
			}
		}
	}
}