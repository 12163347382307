.loader2 {
	position: absolute;
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	left: 0;
	right: 0;
	z-index: 5;
}
.lds-fb {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	transform: scale(0.5, 0.8);
}
.lds-fb div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: #18ffff;
	animation: lds-fb 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-fb div:nth-child(1) {
	left: 8px;
	animation-delay: -0.24s;
}
.lds-fb div:nth-child(2) {
	left: 32px;
	animation-delay: -0.12s;
}
.lds-fb div:nth-child(3) {
	left: 56px;
	animation-delay: 0;
}
@keyframes lds-fb {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}
