@import "./battle";

.battleNotFound {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	> h3 {
		padding: 20px;
		font-size: 18px;
	}
}
.Battles {
	box-sizing: border-box;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 0 100px;
	> * {
		box-sizing: border-box;
		width: 100%;
		max-width: 1350px;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
	> header {
		display: flex;
		justify-content: flex-start;
		h2 {
			font-size: 22px;
		}
		background-color: rgba(0, 0, 0, 0.3);
		padding: 5px 20px;
	}
	
	> main.btnBattle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		.btnCreate {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			flex: unset;
			padding: 10px;
			gap: 10px;
			border-radius: 5px;
			> img {
				height: 24px;
			}
		}
	}
	> main.battle-list {
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 20px;
		padding: 20px;
	}
}
