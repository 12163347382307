.VipRequest {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	padding-bottom: 100px !important;

	header {
		margin-top: 10px;
		color: gold;
	}

	main {
		padding: 0 20px;
	}

	.block {
		gap: 5px;
		display: flex;
		flex-direction: column;
		align-items: flex-start !important;
		justify-content: center;

		&.row {
			flex-direction: row;
			justify-content: space-between;
			align-items: center !important;

			input {
				width: 150px;
				text-align: center;
				font-size: 18px;
				color: gold;
				padding: 3px;
			}
		}
	}

	.btn {
		align-items: center !important;
	}

	.imp {
		font-size: 22px;
		color: red;
		display: inline-block;
		padding: 0 5px;
		line-height: 0;
		transform: translateY(3px);
	}

	::-webkit-calendar-picker-indicator {
		filter: invert(1);
	}

	#iWagerUsd,
	#iWagerReqUsd {
		box-shadow: unset;
		background-color: rgba(255, 255, 255, 0.05);
		pointer-events: none;
		user-select: none;
	}
}