.lobbyGames {
	display: flex;
	max-width: 1310px;
	width: calc(100% - 10px);
	box-sizing: border-box;
	padding-top: 25px;
	min-height: 50px;
	> .slider {
		position: relative;
		> .pages {
			position: absolute;
			right: 50px;
			top: -25px;
			> button {
				border-radius: 5px;
				min-width: 40px;
				width: 40px;
				@include themify($themes) {
					color: themed("profilePlayerColor");
					background-color: themed("profilePlayerBackgroundColor");
				}
				margin-left: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;
			}
		}
		> .caption {
			position: absolute;
			left: 10px;
			height: 20px;
			top: -25px;
			@include themify($themes) {
				color: themed("profilePlayerColor");
				background-color: themed("profilePlayerBackgroundColor");
			}
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			font-weight: bold;
			font-size: 18px;
			z-index: 1;
			padding: 10px;
			padding-bottom: 0;
		}
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		@include themify($themes) {
			color: themed("profilePlayerColor");
			// background-color: themed("profilePlayerBackgroundColor");
			background-image: linear-gradient(180deg, #5779c2, transparent);
		}
		border-radius: 10px;
	}
}
