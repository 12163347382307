.shortcuts {
	position: fixed;
	overflow-x: hidden;
	top: 20%;
	bottom: 0;
	z-index: 10;
	right: 0;
	$wButton: 150px;
	$wRemove: 40px;
	pointer-events: none;
	&.chatOpen.chatRight {
		@media (min-width: ($chatPaneWidth + 400px)) {
			right: $chatPaneWidth;
		}
	}
	> .wrapper {
		pointer-events: auto;
		position: relative;
		right: -$wButton + $wRemove;
		> .btnToggleShortcuts {
			width: 40px;
			height: 20px;
			background-color: blueviolet;
			@media (hover: hover) {
				&:hover {
					background-color: hsl(271, 76%, 63%);
				}
			}
			&:active {
				background-color: hsl(271, 76%, 43%) !important;
			}
		}

		> .shortcut {
			transition: all 0.5s ease-in-out;
			&:first-child {
				position: relative;
				z-index: 1;
			}
			@media (hover: hover) {
				&:hover {
					transform: translate(-$wButton + $wRemove, 0);
				}
			}
			display: flex;
			// background-color: ;
			> .content {
				> .btnShortcut {
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					width: $wButton;
					padding: 5px 5px;
					border-radius: 0;
					> b {
						font-size: 15px;
						white-space: nowrap;
					}
				}
				> .simpleDropDown {
					width: $wButton;
					> .btnHeader {
						padding: 5px 0;
					}
					.imgFlag {
						width: 24px;
						height: 24px;
					}
					.btns {
						display: flex;
						flex-direction: column;
						> * {
							width: 100%;
							&:not(:last-child) {
								margin-bottom: 3px;
							}
						}
					}
				}
			}
			// > .btnShortcutRemove {
			// 	width: $wRemove;
			// }
		}
	}
}
