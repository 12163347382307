.onlineStatus {
	width: 10px;
	height: 10px;
	cursor: pointer;
	margin: 3px;
	border-radius: 3px;
}
.onlineStatus,
.onlineMark {
	&.col_online {
		@include themify($themes) {
			background-color: themed("success");
		}
	}
	&.col_away {
		@include themify($themes) {
			background-color: themed("warn");
		}
	}
	&.col_busy {
		@include themify($themes) {
			background-color: themed("alert");
		}
	}
	&.col_offline {
		@include themify($themes) {
			background-color: themed("_grey");
		}
	}
}
