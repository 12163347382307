.digitScreen {
	display: flex;
	font-family: "Roboto Mono";
}
.digit {
	$w: 12px;
	$h: 24px;
	margin-right: 1px;
	height: $h + 9px;
	width: $w;
	overflow-y: hidden;
	position: relative;
	pointer-events: none;
	user-select: none;
	.all {
		border: none;
		box-sizing: border-box;
		pointer-events: none;
		user-select: none;
		width: 100%;
		margin-top: 3px;
		position: absolute;
		top: 0;
		transition: top 0.2s linear; //, color 0.1s linear;
		background-color: #222;
		color: white;
		&.green {
			animation-name: animGreen;
			animation-duration: 2s;
			// color: rgb(137, 255, 137);
		}
		&.red {
			animation-name: animRed;
			animation-duration: 2s;
			// color: rgb(253, 136, 136);
		}
		&.ggreen {
			animation-name: ganimGreen;
			animation-duration: 2s;
			// color: rgb(137, 255, 137);
		}
		&.gred {
			animation-name: ganimRed;
			animation-duration: 2s;
			// color: rgb(253, 136, 136);
		}
		> div {
			display: flex;
			width: 100%;
			height: $h;
			box-sizing: content-box;
			// border-bottom: 1px solid transparent; //rgba(255, 255, 255, 0.7);
			// margin-top: -1px;
			&:nth-child(1) > b {
				// color: rgba(255, 255, 255, 0.5);
			}
			> b {
				display: block;
				margin: auto;
				// font-size: 17.5px; problem
				line-height: 0;
				font-weight: bold;
				padding-bottom: 1px;
				// color: white;
			}
		}
	}
	&.vMinus > .all {
		top: -$h + 1px;
	}
	&.vPoint > .all {
		top: -$h * 2 + 1px;
	}
	@for $i from 0 to 10 {
		&.v#{$i} > .all {
			top: -($i + 3) * $h + 1px;
		}
	}

	@include themify($themes) {
		@keyframes animRed {
			0% {
				color: themed("alert");
			}
			99% {
				color: white;
			}
		}
		@keyframes animGreen {
			0% {
				color: themed("success");
			}
			99% {
				color: white;
			}
		}
		@keyframes ganimRed {
			0% {
				color: themed("alert");
			}
			99% {
				color: white;
			}
		}
		@keyframes ganimGreen {
			0% {
				color: themed("success");
			}
			99% {
				color: white;
			}
		}
	}
}
