.rotationWidget {
	display: flex;
	flex-direction: column;
	flex: 1;
	box-sizing: border-box;
	// @include themify($themes) {
	// 	background-color: themed("selectBackgroundColor");
	// 	color: themed("selectColor");
	// }
	> * {
		box-sizing: border-box;
		&:not(:last-child) {
			margin-bottom: 5px;
		}
		position: relative;
	}
	> header {
		padding: 5px;
	}
	> main {
		overflow: hidden;
		> .item {
			box-sizing: border-box;
			position: absolute;
			width: 100%;
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				background-color: rgba(255, 255, 255, 0.05);
				height: 65px;
				z-index: -1;
			}
		}
	}
}
.rotationWidget.static {
	> main > div {
		padding: 0;
		height: 70px;
		border-bottom: 2px solid rgba(255,255,255,0.1);
	}
}
