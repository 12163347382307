.fairness {
	width: 300px;
	padding: 10px;
	min-height: 450px;
	display: flex;
	flex-direction: column;
	> h1 {
		font-size: 22px;
		font-weight: bold;
		text-align: center;
		margin: 10px 0;
	}
	> figure {
		display: flex;
		flex-direction: column;
		width: 100%;
		position: relative;
		> input {
			font-family: "Roboto Mono";
		}
		&:not(:last-child) {
			margin-bottom: 5px;
		}
		> h3 {
			font-size: 18px;
			font-weight: bold;
			margin: 10px 0;
		}
		> .inpCsNew {
			margin-bottom: 10px;
		}
		> label {
			font-weight: bold;
			margin-bottom: 5px;
			opacity: 0.7;
		}
		> b {
			word-break: break-all;
			margin-bottom: 5px;
			font-family: "Roboto Mono";
		}
		&.current > b {
			@include themify($themes) {
				color: themed("success");
			}
		}
		&.previous > b {
			@include themify($themes) {
				color: themed("cgold");
			}
		}
		> .btnRenew {
			position: absolute;
			right: 0px;
			top: 20px;
			transform: scale(0.8);
		}
		> .btnRand {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
