.loaderWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(33, 47, 75);
	color: white;
	@include themify($themes) {
		background-color: themed("containerBackgroundColor");
		color: themed("containerColor");
	}
	width: 100%;
	height: 100vh;
}
.loader {
	position: absolute;
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	left: 0;
	right: 0;
	z-index: 5;
}
// .loader > .dot {
// 	width: 24px;
// 	height: 24px;
// 	background: #3ac;
// 	border-radius: 100%;
// 	display: inline-block;
// 	animation: slide 1s infinite;
// }
// .loader > .dot:nth-child(1) {
// 	animation-delay: 0.1s;
// 	background: #32aacc;
// }
// .loader > .dot:nth-child(2) {
// 	animation-delay: 0.2s;
// 	background: #64aacc;
// }
// .loader > .dot:nth-child(3) {
// 	animation-delay: 0.3s;
// 	background: #96aacc;
// }
// .loader > .dot:nth-child(4) {
// 	animation-delay: 0.4s;
// 	background: #c8aacc;
// }
// .loader > .dot:nth-child(5) {
// 	animation-delay: 0.5s;
// 	background: #faaacc;
// }
// @-moz-keyframes slide {
// 	0% {
// 		transform: scale(1);
// 	}
// 	50% {
// 		opacity: 0.3;
// 		transform: scale(2);
// 	}
// 	100% {
// 		transform: scale(1);
// 	}
// }
// @-webkit-keyframes slide {
// 	0% {
// 		transform: scale(1);
// 	}
// 	50% {
// 		opacity: 0.3;
// 		transform: scale(2);
// 	}
// 	100% {
// 		transform: scale(1);
// 	}
// }
// @-o-keyframes slide {
// 	0% {
// 		transform: scale(1);
// 	}
// 	50% {
// 		opacity: 0.3;
// 		transform: scale(2);
// 	}
// 	100% {
// 		transform: scale(1);
// 	}
// }
// @keyframes slide {
// 	0% {
// 		transform: scale(1);
// 	}
// 	50% {
// 		opacity: 0.3;
// 		transform: scale(2);
// 	}
// 	100% {
// 		transform: scale(1);
// 	}
// }
.loader-star {
	position: absolute;
	top: calc(50% - 12px);
}
.loader-2 .loader-star {
	position: static;
	width: 60px;
	height: 60px;
	-webkit-transform: scale(0.7);
	-ms-transform: scale(0.7);
	transform: scale(0.7);
	-webkit-animation: loader-2-star 1s ease alternate infinite;
	animation: loader-2-star 1s ease alternate infinite;
}
.loader-2 .loader-circles {
	width: 8px;
	height: 8px;
	background: #18ffff;
	border-radius: 50%;
	position: absolute;
	left: calc(50% - 4px);
	top: calc(50% - 4px);
	-webkit-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
	-webkit-animation: loader-2-circles 1s ease-in-out alternate infinite;
	animation: loader-2-circles 1s ease-in-out alternate infinite;
}
// @-webkit-keyframes loader-2-circles {
// 	0% {
// 		-webkit-box-shadow: 0 0 0 #18ffff;
// 		box-shadow: 0 0 0 #18ffff;
// 		opacity: 1;
// 		-webkit-transform: rotate(0deg);
// 		transform: rotate(0deg);
// 	}
// 	50% {
// 		-webkit-box-shadow: 24px -22px #18ffff, 30px -15px 0 -3px #18ffff, 31px 0px #18ffff, 29px 9px 0 -3px #18ffff, 24px 23px #18ffff,
// 			17px 30px 0 -3px #18ffff, 0px 33px #18ffff, -10px 28px 0 -3px #18ffff, -24px 22px #18ffff, -29px 14px 0 -3px #18ffff, -31px -3px #18ffff,
// 			-30px -11px 0 -3px #18ffff, -20px -25px #18ffff, -12px -30px 0 -3px #18ffff, 5px -29px #18ffff, 13px -25px 0 -3px #18ffff;
// 		box-shadow: 24px -22px #18ffff, 30px -15px 0 -3px #18ffff, 31px 0px #18ffff, 29px 9px 0 -3px #18ffff, 24px 23px #18ffff,
// 			17px 30px 0 -3px #18ffff, 0px 33px #18ffff, -10px 28px 0 -3px #18ffff, -24px 22px #18ffff, -29px 14px 0 -3px #18ffff, -31px -3px #18ffff,
// 			-30px -11px 0 -3px #18ffff, -20px -25px #18ffff, -12px -30px 0 -3px #18ffff, 5px -29px #18ffff, 13px -25px 0 -3px #18ffff;
// 		-webkit-transform: rotate(180deg);
// 		transform: rotate(180deg);
// 	}
// 	100% {
// 		opacity: 0;
// 		-webkit-transform: rotate(360deg);
// 		transform: rotate(360deg);
// 		-webkit-box-shadow: 25px -22px #18ffff, 15px -22px 0 -3px black, 31px 2px #18ffff, 21px 2px 0 -3px black, 23px 25px #18ffff,
// 			13px 25px 0 -3px black, 0px 33px #18ffff, -10px 33px 0 -3px black, -26px 24px #18ffff, -19px 17px 0 -3px black, -32px 0px #18ffff,
// 			-23px 0px 0 -3px black, -25px -23px #18ffff, -16px -23px 0 -3px black, 0px -31px #18ffff, -2px -23px 0 -3px black;
// 		box-shadow: 25px -22px #18ffff, 15px -22px 0 -3px black, 31px 2px #18ffff, 21px 2px 0 -3px black, 23px 25px #18ffff, 13px 25px 0 -3px black,
// 			0px 33px #18ffff, -10px 33px 0 -3px black, -26px 24px #18ffff, -19px 17px 0 -3px black, -32px 0px #18ffff, -23px 0px 0 -3px black,
// 			-25px -23px #18ffff, -16px -23px 0 -3px black, 0px -31px #18ffff, -2px -23px 0 -3px black;
// 	}
// }

// prettier-ignore
@keyframes loader-2-circles {
	0% {
		// -webkit-box-shadow: 0 0 0 #18ffff;
		box-shadow: 0 0 0 #18ffff;
		opacity: 1;
		// -webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	50% {
		// -webkit-box-shadow: 24px -22px #18ffff, 30px -15px 0 -3px #18ffff, 31px 0px #18ffff, 29px 9px 0 -3px #18ffff, 24px 23px #18ffff,
		// 	17px 30px 0 -3px #18ffff, 0px 33px #18ffff, -10px 28px 0 -3px #18ffff, -24px 22px #18ffff, -29px 14px 0 -3px #18ffff, -31px -3px #e11a2b,
		// 	-30px -11px 0 -3px #18ffff, -20px -25px #18ffff, -12px -30px 0 -3px #18ffff, 5px -29px #18ffff, 13px -25px 0 -3px #18ffff;
		box-shadow: 24px -22px #18ffff, 30px -15px 0 -3px #18ffff, 31px 0px #18ffff, 29px 9px 0 -3px #18ffff, 24px 23px #18ffff, 17px 30px 0 -3px #18ffff, 0px 33px #18ffff, -10px 28px 0 -3px #18ffff, -24px 22px #18ffff, -29px 14px 0 -3px #18ffff, -31px -3px #18ffff, -30px -11px 0 -3px #18ffff, -20px -25px #18ffff, -12px -30px 0 -3px #18ffff, 5px -29px #18ffff, 13px -25px 0 -3px #18ffff;
		// // -webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		opacity: 0;
		// -webkit-transform: rotate(360deg);
		transform: rotate(360deg);
		// -webkit-box-shadow: 25px -22px #18ffff, 15px -22px 0 -3px black, 31px 2px #18ffff, 21px 2px 0 -3px black, 23px 25px #18ffff,
		// 	13px 25px 0 -3px black, 0px 33px #18ffff, -10px 33px 0 -3px black, -26px 24px #18ffff, -19px 17px 0 -3px black, -32px 0px #18ffff,
		// 	-23px 0px 0 -3px black, -25px -23px #18ffff, -16px -23px 0 -3px black, 0px -31px #18ffff, -2px -23px 0 -3px black;
		box-shadow: 25px -22px #18ffff, 15px -22px 0 -3px black, 31px 2px #18ffff, 21px 2px 0 -3px black, 23px 25px #18ffff, 13px 25px 0 -3px black, 0px 33px #18ffff, -10px 33px 0 -3px black, -26px 24px #18ffff, -19px 17px 0 -3px black, -32px 0px #18ffff, -23px 0px 0 -3px black, -25px -23px #18ffff, -16px -23px 0 -3px black, 0px -31px #18ffff, -2px -23px 0 -3px black;
	}
}

// @-webkit-keyframes loader-2-star {
// 	0% {
// 		-webkit-transform: scale(0) rotate(0deg);
// 		transform: scale(0) rotate(0deg);
// 	}
// 	100% {
// 		-webkit-transform: scale(0.7) rotate(360deg);
// 		transform: scale(0.7) rotate(360deg);
// 	}
// }

@keyframes loader-2-star {
	0% {
		-webkit-transform: scale(0) rotate(0deg);
		transform: scale(0) rotate(0deg);
	}
	100% {
		-webkit-transform: scale(0.7) rotate(360deg);
		transform: scale(0.7) rotate(360deg);
	}
}
