.siteSettings {
	display: flex;
	flex-direction: column;
	min-width: 300px;
	min-height: 450px;
	box-sizing: border-box;
	padding: 0 5px;
	> header {
		box-sizing: border-box;
		> h1 {
			font-size: 22px;
			font-weight: 700;
			text-align: center;
			margin: 10px 0;
		}
	}
	> section {
		margin-top: 5px;
		box-sizing: border-box;
		display: flex;
		@include themify($themes) {
			background-color: themed("profileGeoBackgroundColor");
			color: themed("profileGeoColor");
		}
		> .pane {
			box-sizing: border-box;
			padding: 5px;
		}
		> .lPane {
			display: flex;
			flex-direction: column;
			> .menuItem {
				width: 100%;
				display: flex;
				padding: 5px;
				&:not(:last-child) {
					margin-bottom: 3px;
				}
				> label {
					margin-left: 5px;
					padding: 0;
				}
			}
		}
		> .rPane {
			flex: 1;
			display: flex;
			> .formGeneral {
				width: 100%;
			}
		}
	}
}
@media (max-width: 599px) {
	.siteSettings > section.menuSelect {
		display: flex;
	}
	.siteSettings > section > .pane.lPane {
		display: none;
	}
}
@media (min-width: 600px) {
	.siteSettings > section.menuSelect {
		display: none;
	}
	.siteSettings > section > .pane.rPane {
		width: 80vw;
	}
	.siteSettings > section > .pane.lPane {
		display: flex;
	}
}
