.crash {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	> .crashContent {
		display: flex;
		box-sizing: border-box;
		flex-direction: column;
		flex: 1;
		max-width: 769px;
		> .row {
			display: flex;
			flex-direction: row;
			box-sizing: border-box;
			width: 100%;
			&.rowCrashCanvas {
				flex-wrap: wrap;
				> * {
					flex: 1;
					min-width: 300px;
				}
			}
			> .col {
				flex: 1;
				display: flex;
				flex-direction: column;
				box-sizing: border-box;
			}
		}
	}
}
.crashContent > .row > .col {
	.row {
		display: flex;
		flex-direction: row;
		width: 100%;
		box-sizing: border-box;
		@include themify($themes) {
			background-color: themed("diceOutline");
		}
		padding: 1px;
		> .wrapper {
			width: 100%;
			&.wp2 {
				width: unset;
				flex: 2;
			}
			&.wp3 {
				width: unset;
				flex: 3;
			}
			padding: 5px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			@include themify($themes) {
				background-color: themed("diceBackground");
			}
			border-radius: 0px;
			border-top: 4px solid hsla(0, 0%, 100%, 0.04);
			border-bottom: 0 solid hsla(0, 0%, 100%, 0);
			box-sizing: border-box;
			> div {
				width: 100%;
				display: flex;
				align-items: center;
				&.caption {
					> *:not(:last-child) {
						margin-right: 5px;
					}
					height: 25px;
				}
				&.content {
					justify-content: center;
				}
				&:not(:last-child) {
					margin-bottom: 3px;
				}
			}
		}
	}
	.crashCanvasWrapper {
		width: 100%;
		padding: 1px;
		padding-bottom: 0px;
		box-sizing: border-box;
		@include themify($themes) {
			background-color: themed("diceOutline");
		}
		.crashCanvas {
			@include themify($themes) {
				background-color: themed("diceBackground");
			}
			box-sizing: border-box;
			width: 100%;
			position: relative;
			height: 250px;
			> canvas {
				// background-color: hsl(207, 100%, 10%);
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}
}
// $crashCol2Height: 438px;
$crashCol2Height: 377px;
.crashContent > .row > .col2 {
	max-height: $crashCol2Height;
}
.crashContent {
	.hk {
		position: absolute;
		top: 3px;
		right: 3px;
		border-radius: 3px;
		padding: 1px;
		border: 1px solid black;
		color: black;
		pointer-events: none;
		font-size: 8px;
	}
	.tabCrash {
		&.on {
			display: flex;
		}
		&.off {
			display: none;
		}
		// @include themify($themes) {
		// 	background-color: themed("dark");
		// }
		box-sizing: border-box;
		flex-direction: column;
		&.tabPlay {
			> .btnRefresh {
				padding: 0;
				margin: 0;
				outline: none;
				width: 0;
				height: 0;
			}
			> .row {
				&:not(:last-of-type) {
					margin-bottom: -1px;
				}
				&.buttons > .wrapper {
					align-items: center;
					$betItemHeight: 40px;
					> .inputWrapper {
						flex: 1;
						&.betAmount {
							position: relative;
							> img {
								position: absolute;
								right: 5px;
								top: 14px;
							}
						}
						> input {
							height: $betItemHeight;
							font-family: "Roboto Mono";
						}
					}
					> .btns {
						> button {
							position: relative;
							flex: 1;
							font-weight: bold;
							height: $betItemHeight;
							@include themify($themes) {
								&.toggled {
									color: themed("progressGreenBackgroundColor");
								}
								&.untoggled {
									color: themed("progressRedBackgroundColor");
								}
							}
						}
					}
				}
				&.rowBet > .wrapper {
					align-items: center;
					justify-content: center;
					position: relative;
					> .btnSettings {
						position: absolute;
						left: 10px;
					}
					> .btnAuto {
						position: absolute;
						right: 10px;
						font-weight: bold;
						width: auto;
					}
					.btnWrapper {
						@include themify($themes) {
							background-color: themed("diceOutline");
						}
						position: relative;
						padding: 1px;
						box-sizing: border-box;
						max-width: 150px;
						flex: 1;
						> .btnSettings {
							position: absolute;
							left: 5px;
						}
						> .btnAuto {
							position: absolute;
							right: 5px;
						}
						> .btnBet {
							width: 100%;
							font-weight: bold;
							font-size: 22px;
							height: 43px;
							line-height: 16px;
						}
					}
				}
			}
		}
		&.tabGame {
			margin-left: -1px;
			padding: 1px;
			box-sizing: border-box;
			height: calc(#{$crashCol2Height} - 37px);
			@include themify($themes) {
				background-color: themed("diceOutline");
			}
			> .wrapper {
				$tb: 80px;
				box-sizing: border-box;
				padding: 5px;
				overflow: hidden;
				@include themify($themes) {
					background-color: themed("diceBackground");
				}
				> .header {
					display: flex;
					align-items: center;
					margin-bottom: 3px;
					> b {
						font-size: 11px;
						&.game {
							flex: 1;
						}
						&.total_bets {
							width: $tb;
						}
						&.total_profit {
							width: $tb;
							margin-right: 82px;
						}
					}
				}
				li {
					&:nth-child(2n) {
						@include themify($themes) {
							background-color: themed("shade005");
						}
					}
					> .entry {
						height: 35px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-right: 15px;
						padding: 5px;
						box-sizing: border-box;

						> .pa {
							font-size: 20px;
							&.success {
								@include themify($themes) {
									color: themed("success");
								}
							}
							&.grey {
								@include themify($themes) {
									color: themed("_grey");
								}
							}
						}
						> .inf {
							display: flex;
							align-items: center;

							> *:not(:last-child) {
								margin-right: 3px;
							}
							> .totalBet,
							> .totalProfit {
								width: $tb;
							}
							> button {
								width: 55px;
								&.btnInf {
									width: 30px;
								}
								padding: 0 5px;
								flex: unset;
							}
						}
					}
				}
			}
		}
	}
}
.crashContent {
	.tabPlayers {
		margin-left: -1px;
		padding: 1px;
		box-sizing: border-box;
		height: 100%;
		@include themify($themes) {
			background-color: themed("diceOutline");
		}
		> .wrapper {
			box-sizing: border-box;
			padding: 5px;
			height: 100%;
			@include themify($themes) {
				background-color: themed("diceBackground");
			}
			.listScroll {
				.p {
					display: flex;
					align-items: center;
					justify-content: space-between;
					&.me {
						@include themify($themes) {
							background-color: themed("shade01");
						}
					}
					> .bet {
						display: flex;
						align-items: center;
						justify-content: center;
						font-family: "Roboto Mono";
						> *:not(:last-child) {
							margin-right: 5px;
						}
						> .green {
							@include themify($themes) {
								color: themed("success");
							}
						}
						> .red {
							@include themify($themes) {
								color: themed("alert");
							}
						}
					}
				}
			}
		}
	}
}

.crash {
	.stats {
		// overflow: hidden;
		i {
			opacity: 0.7;
			font-weight: 100 !important;
		}
		padding: 1px;
		box-sizing: border-box;
		@include themify($themes) {
			background-color: themed("diceOutline");
		}
		> .wrapper {
			width: unset !important;
			> .header,
			> .footer {
				width: 100%;
				display: flex;
			}
			> .header {
				> .button3d {
					@include themify($themes) {
						background-color: themed("diceOutline");
					}
					padding: 1px;
					width: auto;
					flex: 1;
					&:not(:last-child) {
						margin-right: 2px;
					}
					.diceBtn {
						flex-direction: row;
						padding: 0;
						img {
							width: 22px;
							margin-top: 2px;
							margin-right: 5px;
						}
					}
				}
				margin-bottom: 5px;
			}
			> .footer {
				flex-wrap: wrap;
				// margin-bottom: 5px;
				> figure {
					&.off {
						display: none;
					}
					position: relative;
					flex: 1;
					display: flex;
					flex-direction: column;
					align-items: center;
					min-width: 130px;
					margin: 2px;
					background-color: rgba(255, 255, 255, 0.03);
					padding: 2px;
					overflow: hidden;
					> .btnStatsReset {
						position: absolute;
						height: calc(100% - 8px);
						top: 4px;
						left: 4px;
						width: 34px;
						transition: left 0.3s linear;
						&._off {
							user-select: none;
							left: -40px;
						}
					}
					> label {
						margin-bottom: 5px;
						white-space: nowrap;
						font-weight: 500;
					}
					> .values {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						> b {
							font-weight: bold;
							font-family: "Roboto Mono";
							&:not(:last-child) {
								margin-right: 5px;
							}
							&.success {
								@include themify($themes) {
									color: themed("success");
								}
							}
							&.warn {
								@include themify($themes) {
									color: themed("cgold");
								}
							}
							&.alert {
								@include themify($themes) {
									color: themed("alert");
								}
							}
						}
					}
				}
			}
			// > .chartHeader {
			// 	position: relative;
			// 	margin: 2px;
			// 	padding: 2px;
			// 	display: flex;
			// 	flex-direction: row;
			// 	align-items: center;
			// 	justify-content: flex-start;
			// 	> * {
			// 		margin-right: 5px;
			// 	}
			// 	@include themify($themes) {
			// 		background-color: rgba(255, 255, 255, 0.03);
			// 	}
			// 	> .resetChart {
			// 		width: 34px;
			// 		height: 29px;
			// 		padding: 0;
			// 		margin-left: 2px;
			// 		flex: none;
			// 	}
			// 	> .f1 {
			// 		flex: 1;
			// 	}
			// 	> .leg {
			// 		height: 10px;
			// 		width: 10px;
			// 		&.leg-a {
			// 			@include themify($themes) {
			// 				background-color: themed("chartBalanceStPointColor");
			// 			}
			// 		}
			// 		&.leg-b {
			// 			@include themify($themes) {
			// 				background-color: themed("chartBalanceCurveColor");
			// 			}
			// 		}
			// 	}
			// }
			// > .ct-chart {
			// 	position: relative;
			// 	margin: 2px;
			// 	@include themify($themes) {
			// 		background-color: rgba(255, 255, 255, 0.03);
			// 	}

			// 	.ct-label {
			// 		font-size: 0.65rem;
			// 		@include themify($themes) {
			// 			color: themed("linkColor");
			// 		}
			// 	}
			// 	.ct-series-a > .ct-line {
			// 		@include themify($themes) {
			// 			stroke: themed("chartBalanceStPointColor");
			// 		}
			// 	}
			// 	.ct-series-b > .ct-line {
			// 		@include themify($themes) {
			// 			stroke: themed("chartBalanceCurveColor");
			// 		}
			// 	}
			// }

			width: 100%;
			padding: 5px;
			display: flex;
			flex-direction: column;
			@include themify($themes) {
				background-color: themed("diceBackground");
			}
			border-radius: 0px;
			border-top: 4px solid hsla(0, 0%, 100%, 0.04);
			border-bottom: 0 solid hsla(0, 0%, 100%, 0);
		}
		// transition: max-height 0.3s linear;
		// &.on {
		// 	max-height: 255px;
		// }
		&.off {
			max-height: 0px;
			margin-top: -1px;
		}
	}
}
