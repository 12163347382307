.contest {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	$colW: 180px;
	padding: 20px;

	cursor: pointer;
	min-width: 300px;
	// min-height: 220px;
	flex: 1;
	position: relative;
	box-sizing: border-box;
	background-image: linear-gradient(rgba(62, 152, 44, 0.8), #271a61);
	&.expired {
		opacity: 0.5;
		background-image: linear-gradient(rgba(109, 91, 91, 0.8), #271a61);
	}
	&.pending {
		background-image: linear-gradient(rgba(255, 179, 0, 0.8), #271a61);
	}
	> .byType {
		position: absolute;
		bottom: 0px;
		right: 0px;
		z-index: 1;
		color: white;
		font-weight: bold;
		background-color: rgba(255, 255, 255, 0.3);
		border-top-left-radius: 3px;
		padding: 10px;
	}
	border-radius: 5px;
	overflow-x: hidden;
	> .bg {
		position: absolute;
		top: 0;
		bottom: 0;
		left: $colW;
		clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
		transition: all 0.6s ease-in-out;
		> .bg {
			background-position: center;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
	> .dates {
		padding: 5px;
		display: flex;
		flex-direction: column;
		gap: 5px;
		pointer-events: none;
		user-select: none;
		> * {
			display: flex;
			align-items: center;
			> div {
				pointer-events: none;
				user-select: none;
				> * {
					pointer-events: none;
					user-select: none;
				}
				&:first-child {
					width: 50px;
					margin-right: 5px;
					opacity: 0.7;
				}
				&:last-child {
					font-size: 14px;
				}
				> b:first-child {
					margin-right: 5px;
				}
			}
		}
	}
	> .btnDetails {
		position: absolute;
		right: 90px;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;
		user-select: none;
		> .btnGo {
			background-color: transparent;
			@media (hover: hover) {
				&:hover {
					background-color: transparent;
				}
			}
			&:active {
				background-color: transparent;
			}
		}
		> i {
			animation-duration: 1.8s;
			animation-iteration-count: infinite;
			animation-name: slide;
			position: absolute;
			&:nth-of-type(1) {
				animation-delay: 0.4s;
				left: -15px;
			}
			&:nth-of-type(2) {
				animation-delay: 0.2s;
				left: 0px;
			}
			&:nth-of-type(3) {
				left: 15px;
			}
		}
	}
	> .left {
		padding: 5px;
		user-select: none;
		pointer-events: none;
		user-select: none;
		display: flex;
		flex-direction: column;
		justify-content: center;
		> b {
			&:first-child {
				opacity: 0.7;
			}
			&:last-child {
				font-size: 15px;
				color: cyan;
				margin-top: 5px;
			}
		}
	}
	> .content {
		position: relative;
		padding: 5px;
		display: flex;
		flex-direction: column;
		> .row {
			display: flex;
			flex-direction: column;
			width: 100%;
			> .name {
				font-weight: bold;
				font-size: 25px;
				pointer-events: none;
				user-select: none;
			}
			> .amount {
				display: flex;
				align-items: center;
				gap: 3px;
				font-size: 25px;
				font-weight: bold;
				color: #68ff68;
				text-shadow: 2px 2px 5px #000;
				pointer-events: none;
				user-select: none;
			}
		}
		> .desc {
			pointer-events: none;
			user-select: none;
		}
	}
}
@keyframes slide {
	0% {
		opacity: 0;
		transform: translateX(0px);
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateX(30px);
	}
}
.contestsContainer {
	> .wrapper {
		display: flex;
		flex-direction: column;
		padding: 10px 0;
		min-width: 280px;
		max-width: 750px;
		margin: auto;
		> * {
			border-radius: 5px;
		}
		> *:not(:last-child) {
			margin-bottom: 5px;
		}
		> main {
			padding: 10px;
			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
				color: themed("profileGeoColor");
			}
			> *:not(:last-child) {
				margin-bottom: 10px;
			}
		}
		> .block {
			display: flex;
			flex-direction: column;
			margin-bottom: 10px;
			min-height: 100px;
			> h2 {
				flex: 1;
				font-weight: bold;
				margin-bottom: 10px;
				&.active {
					font-size: 24px;
					@include themify($themes) {
						color: themed("success");
					}
				}
				&.pending {
					@include themify($themes) {
						color: themed("warn");
					}
				}
			}
			> .contests {
				display: flex;
				flex-wrap: wrap;
				flex: 1;
				$colW: 180px;
				> .contest {
					margin: 5px;
				}
			} //contests
		} //block
	}
}

.contestPage {
	> .wrapper {
		display: flex;
		flex-direction: column;
		padding: 10px 0;
		min-width: 280px;
		max-width: 750px;
		margin: auto;
		> .contest {
			margin-bottom: 10px;
		}
		> header {
			margin-bottom: 10px;
			border-radius: 5px;
			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
				color: themed("profileGeoColor");
			}
			display: flex;
			justify-content: space-between;
		}
		> .desc {
			width: 100%;
			margin-bottom: 20px;
		}
		> .myPosition {
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			> .position {
				display: flex;
				align-items: center;
				> b {
					font-size: 14px;
					font-weight: 500;
				}
				> b:last-of-type {
					font-size: 25px;
					font-weight: bold;
					margin-left: 5px;
				}
				user-select: none;
			}
			> .wager {
				display: flex;
				align-items: center;
				margin-left: 10px;
				> b {
					font-size: 14px;
					font-weight: 500;
				}
				> b:last-of-type {
					font-size: 25px;
					font-weight: bold;
					margin-left: 5px;
				}
				user-select: none;
			}
		}
		> .leaderboard {
			display: flex;
			flex-direction: column;
			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
				color: themed("profileGeoColor");
			}
			> .lb-row {
				&:nth-child(2n):not(.me) {
					@include themify($themes) {
						background-color: themed("shade01");
					}
				}
				&.me {
					background-color: darkgoldenrod;
				}
				&.fade {
					opacity: 0.35;
				}
				box-sizing: border-box;
				padding: 5px;
				width: 100%;
				display: flex;
				align-items: center;
				&:not(:last-child) {
					margin-bottom: 5px;
				}
				> .position {
					width: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 22px;
				}
				> .p {
					display: flex;
					align-items: center;
					> button {
						position: relative;
						margin-right: 5px;
						border-radius: 50%;
						&:after {
							content: "";
							position: absolute;
							left: 0;
							border-radius: 50%;
							box-sizing: border-box;
							border-width: 2px;
							border-style: solid;
							@include themify($themes) {
								border-color: themed("btnIconBackgroundColorHover");
							}
							pointer-events: none;
							width: 48px;
							height: 48px;
						}
						> img {
							border-radius: 50%;
							padding: 0;
							width: 48px;
							height: 48px;
						}
					}
					> .info {
						display: flex;
						flex-direction: column;
						justify-content: center;
						> b:first-child {
							margin-bottom: 5px;
						}
					}
				}

				> .amounts {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-end;
					font-size: 16px;
					> * {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						font-size: 16px;
						&:not(:last-child) {
							margin-bottom: 5px;
						}
						&.won {
							font-weight: bold;
							@include themify($themes) {
								color: themed("success");
							}
						}
					}
				}
			}
		}
	}
}
