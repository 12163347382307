.slot_game {
	display: flex;
	flex-direction: column;
	align-items: center;
	> *:not(:first-child) {
		margin-top: 20px;
	}
	> .gameFrame {
		width: 100%;
		transition: transform 0.3s ease;
		&:not(.expanded) {
			height: calc(100vh - 200px);
			> .btns {
				margin-right: 40px;
			}
		}
		&.expanded {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 10;
			padding: 0px;
			border-radius: 0px;
			> .btns {
				z-index: 20;
				position: absolute;
			}
		}
		@include themify($themes) {
			// background-color: themed("formBackgroundColor");
			color: themed("formColor");
		}
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
		box-sizing: border-box;
		> .btns {
			display: flex;
			padding: 2px;
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 5px;
			// position: absolute;
			right: 10px;
			top: 10px;
			button > i {
				padding: 0 !important;
			}
			> .toggleWrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 5px;
				background-color: rgba(255,255,255,0.1);
				border-radius: 5px;
				padding: 0 3px;
				> button {
					height: 28px;
					min-height: unset;
				}
			}
			> button {
				@include themify($themes) {
					color: themed("btnIconColor", true);
					// background-color: themed("btnIconBackgroundColor", true);
					background-color: rgba(255, 255, 255, 0.1);
				}
				height: 28px;
				min-height: unset;
				&.toggleButton {
					width: 75px;
				}
				> .toggleSlider {
					width: 24px;
					height: 24px;
				}
			}
			align-items: center;
			> *:not(:last-child) {
				margin-right: 5px;
			}
		}
		> iframe {
			border: none;
			width: 100%;
			height: 100%;
		}
	}
}
