.BattleView {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding: 20px;
	padding-bottom: 100px;
	> *:not(:last-child) {
		margin-bottom: 5px;
	}
	.mono {
		font-family: "Roboto Mono", monospace;
	}
	.timeLeft {
		background-color: rgba(0, 0, 0, 0.3);
		&.red {
			color: red;
		}
		&.green {
			color: rgb(62, 208, 25);
		}
		padding: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 0;
		height: 10px;
		gap: 10px;
		text-transform: uppercase;
		&.red {
			border: 1px solid rgba(255, 0, 0, 0.5);
		}
		&.green {
			border: 1px solid rgba(62, 208, 25, 0.5);
		}
		> b.c {
			font-size: 12px;
		}
		> b.v {
			font-weight: bold;
		}
	}
	.scores {
		position: relative;
		box-sizing: border-box;
		// padding: 20px;
		// display: flex;
		// flex-direction: column;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.1);
		> .participant {
			transition: top 0.5s linear;
			position: absolute;
			left: 0;
			right: 0;
			max-width: 820px;
			margin: auto;

			&:nth-child(2n) {
				background-color: rgba(255, 255, 255, 0.3);
			}
			&:nth-child(2n + 1) {
				background-color: rgba(255, 255, 255, 0.1);
			}
			&.me {
				font-weight: bold;
				background-color: gold;
				color: black;
				> .avatarComponent > .btnA {
					color: black;
					text-decoration: none;
					font-weight: bold;
					&:hover {
						color: black;
						text-decoration: underline;
					}
				}
			}

			display: flex;
			justify-content: center;
			height: 30px;
			gap: 10px;
			> * {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
			}
		}
	}
	.BattleGame {
		transition: transform 0.3s ease;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		&:not(.expanded) {
			height: calc(100vh - 300px);
		}
		&.expanded {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 10;
			padding: 0px;
			border-radius: 0px;
			> .btns {
				z-index: 20;
			}
		}
		@include themify($themes) {
			background-color: themed("formBackgroundColor");
			color: themed("formColor");
		}
		> iframe {
			border: none;
			width: 100%;
			height: 100%;
		}
	}
	.btns {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		gap: 10px;
		> button {
			flex: unset;
			height: 48px;
			border-radius: 5px;
			padding: 0 10px;
			> b {
				font-size: 16px;
			}
			&.btnInvite {
				> b:last-child {
					font-size: 12px;
				}
			}
		}
		.btnBattles {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding: 10px;
			gap: 10px;
			> img {
				height: 24px;
			}
		}
	}
}
@media (max-width: (500px + $chatPaneWidth)) {
	.chatOn {
		.BattleView {
			padding: 0;
			padding-bottom: 100px;
			> .scores {
				padding: 0;
				> .participant {
					font-size: 13px;
				}
			}
		}
	}
}
@media (max-width: (500px )) {
	.chatOff {
		.BattleView {
			padding: 0;
			padding-bottom: 100px;
			> .scores {
				padding: 0;
				> .participant {
					font-size: 13px;
				}
			}
		}
	}
}
