.deposit {
	.tab {
		flex: 1;
	}

	.coinsel {
		padding: 10px;
		justify-content: center;
		flex-wrap: wrap;

		@include themify($themes) {
			background-color: themed("profileGeoBackgroundColor");
			color: themed("profileGeoColor");
		}

		display: flex;
		gap: 5px;

		.selected {
			border: 1px solid gold;
		}

		a,
		a:hover,
		a:focus,
		a:active {
			text-decoration: none;
			color: inherit;
		}
	}

	>.wrapper {
		display: flex;
		flex-direction: column;
		padding: 10px 0;
		min-width: 280px;
		max-width: 750px;
		margin: auto;

		>* {
			border-radius: 5px;
		}

		>*:not(:last-child) {
			margin-bottom: 5px;
		}

		>main {
			padding: 10px;

			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
				color: themed("profileGeoColor");
			}

			>*:not(:last-child) {
				margin-bottom: 10px;
			}

			>.btnDepB {
				padding: 0px;
				border-radius: 5px;
				flex-direction: row;

				>b {
					font-size: 15px;
				}
			}

			>button {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			>.btns {
				display: flex;
				align-items: center;
				gap: 5px;

				>button {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;

					&:disabled {
						opacity: 1 !important;
					}

					>b {
						word-break: break-all;

						&.addressText {
							user-select: all !important;
						}
					}

					&.btnNetwork:not(:last-child) {
						margin-right: 5px;
					}

					&.btnCopyDep {
						flex: unset;
					}
				}

				>a>button {
					width: 38px;
					margin-left: 5px;
					align-items: center;
					justify-content: center;
				}
			}

			>.info {
				display: flex;
				align-items: center;

				>b:last-child {
					font-weight: bold;
					font-size: 18px;
					margin-left: 5px;

					&:not(.success):not(.alert) {
						@include themify($themes) {
							color: themed("warn");
						}
					}

					&.success {
						@include themify($themes) {
							color: themed("success");
						}
					}

					&.alert {
						@include themify($themes) {
							color: themed("alert");
						}
					}
				}
			}

			>label {
				display: flex;
				align-items: center;

				>b:not(:last-child) {
					margin-right: 5px;
					font-weight: bold;
					font-size: 18px;
				}

				>img {
					margin-left: 5px;
				}

				&.warn {
					@include themify($themes) {
						color: themed("warn");
					}
				}
			}

			>.imgWrapper {
				display: flex;
				align-items: center;
				justify-content: center;

				>img {
					// border-radius: 5px;
					transform: rotate(-5deg);
					padding: 5px 0;
				}
			}
		}
	}
}

.cashier {
	width: 100%;
	// height: 110vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 10px;

	>iframe {
		width: 100%;
		height: 900px;
		border: none;
		box-sizing: border-box;
	}

	.btnDepositCashier {
		border-radius: 5px;
		padding: 20px;
	}
}

.changelly {
	display: flex;
	flex-direction: column;
	gap: 10px;

	.block {
		display: flex;
		flex-direction: column;
		gap: 5px;

		&:not(.btn) {
			min-width: 200px;
		}

		&.btn {
			>b {
				opacity: 0;
				pointer-events: none;
			}

			>button {
				height: 100%;
			}
		}
	}

	.blocks {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		>.block:not(.btn) {
			flex: 1;
		}
	}

	.info {
		display: flex;
		flex-direction: row;
		align-items: center;

		>b:last-child {
			font-weight: bold;
			font-size: 18px;
			margin-left: 5px;

			&:not(.success):not(.alert) {
				@include themify($themes) {
					color: themed("warn");
				}
			}

			&.success {
				@include themify($themes) {
					color: themed("success");
				}
			}

			&.alert {
				@include themify($themes) {
					color: themed("alert");
				}
			}
		}
	}

	.btns {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5px;

		>button {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;

			&:disabled {
				opacity: 1 !important;
			}

			>b {
				word-break: break-all;

				&.addressText {
					user-select: all !important;
				}
			}

			&.btnNetwork:not(:last-child) {
				margin-right: 5px;
			}

			&.btnCopyDep {
				flex: unset;
			}
		}

		>a>button {
			width: 38px;
			margin-left: 5px;
			align-items: center;
			justify-content: center;
		}
	}

	.btnOrder {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
	}

	.center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.view {
		position: fixed;
		width: 350px;
		overflow-x: hidden;
		border-radius: 10px;
		background-color: #333;
		margin: auto;
		height: 80vh;
		z-index: 10;
		left: calc(50% - 175px);
		top: calc(50% - 40vh);
		box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
	}
}