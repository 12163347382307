@import url("https://cdnjs.cloudflare.com/ajax/libs/chartist/0.11.4/chartist.min.css");
.diceGameContainer {
	> video {
		position: absolute;
		height: 100%;
		max-width: unset;
		pointer-events: none;
		user-select: none;
		object-fit: cover;
		width: 100%;
		opacity: 0.4;
	}
}
.diceWrapper {
	width: 100%;
	padding: 5px;
	box-sizing: border-box;
	justify-content: unset;
	align-items: center;
	flex-direction: column;
	// &.autoOn {
	// 	> .diceGame {
	// 		> .inputs,
	// 		> .sliderBox,
	// 		> .myBets {
	// 			display: none;
	// 		}
	// 	}
	// }
	// &.statsOff {
	// 	> .diceGame {
	// 		> .auto {
	// 			display: none;
	// 		}
	// 	}
	// }
}
.diceGame {
	width: 100%;
	min-width: 300px;
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
	box-sizing: border-box;
	background-color: rgba(0, 0, 0, 0.05);
	box-shadow: 0 2px 20px 0px rgba(0, 0, 0, 0.2);
	// padding: 5px 0;
	._height {
		height: 57px;
	}
	&.notReady {
		> .diceTabs,
		> .betButtons,
		> .inputs,
		> .sliderBox,
		> .stats,
		> .jackpot,
		> .myBets,
		> .auto,
		> .limits,
		> .playSection,
		> .helpers {
			display: none;
		}
	}

	> .diceBlock {
		box-sizing: border-box;
		display: flex;
		max-width: 450px;
		width: 100%;
		&:not(:last-of-type) {
			margin-bottom: -1px;
		}
		.inputWrapper {
			flex: 1;
			height: 100%;
			> input {
				height: 100%;
				font-size: 22px;
				text-align: center;
			}
		}
	}
	> .diceTabs {
		position: relative;
		height: 46px;
		> .tabs {
			position: absolute;
			left: 5px;
			right: 5px;
			top: 0;
			bottom: 5px;
			z-index: 1;
			display: flex;
			> button {
				align-items: flex-end;
				border: 1px solid;
				border-bottom: none;
				@include themify($themes) {
					background-color: themed("diceButtonGreyBackgroundColor");
					color: themed("diceButtonGreyColor");
					border-color: themed("diceButtonsOutline");
				}
				cursor: pointer;
				user-select: none;
				transition: all 0.2s ease-in-out;
				flex: 1;
				box-sizing: border-box;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				font-weight: bold;
				text-transform: uppercase;
				&.on {
					margin-top: 0px;
					height: 35px;
					opacity: 1;
					font-size: 20px;
					@include themify($themes) {
						background-color: themed("diceButtonGreenBackgroundColor");
						color: themed("diceButtonGreenColor");
					}
				}
				&.off {
					margin-top: 5px;
					height: 30px;
					font-size: 14px;
				}
				&.btnAuto {
					margin-left: 5px;
				}
			}
		}
		> .strip {
			position: absolute;
			z-index: 0;
			height: 10px;
			left: 0;
			right: 0;
			bottom: 0;
			border: 1px solid;
			@include themify($themes) {
				border-color: themed("diceButtonsOutline");
				background-color: themed("diceButtonGreenBackgroundColor");
			}
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			> .line {
				position: absolute;
				width: calc(50% - 20px);
				transition: all 0.3s linear;
				height: 1px;
				opacity: 0.5;
				@include themify($themes) {
					background-color: themed("success");
				}
				top: 50%;
				&.tab1 {
					left: 10px;
					right: auto;
				}
				&.tab2 {
					left: calc(50% + 10px);
				}
			}
		}
	}
	> .betButtons {
		@include themify($themes) {
			background-color: themed("diceOutline");
		}
		padding: 1px;
		button.btnMaxBet {
			flex-direction: row;
			padding: 0;
			> label:last-child {
				margin-left: 5px;
				@include themify($themes) {
					color: themed("diceButtonMarkColor");
				}
			}
		}
		button.btnProfit > b {
			@include themify($themes) {
				color: themed("success");
			}
		}
		button.btnBetAmount,
		button.btnProfit {
			margin-bottom: 1px;
			> b {
				line-height: 25.5px;
				font-size: 10px;
				&.f1 {
					font-size: 22px;
				}
				&.f2 {
					font-size: 20px;
				}
				&.f3 {
					font-size: 18px;
				}
				&.f4 {
					font-size: 16px;
				}
				&.f5 {
					font-size: 14px;
				}
				&.f6 {
					font-size: 12px;
				}
			}
		}
		> .col2 {
			margin-left: 1px;
		}
		.row,
		.col {
			display: flex;
			flex: 1;
		}
		.col {
			flex-direction: column;
		}
		// .row {
		// }
		.betModifyButtons,
		.rowMax {
			min-height: 30px;
			height: 30px;
			max-height: 30px;
		}
		.betModifyButtons button.diceBtn,
		.btnMax {
			padding: 0;
			> b {
				font-size: 14px;
				font-weight: bold;
				line-height: 0;
			}
		}
		.btnMax {
			width: 50px;
			max-width: 50px;
		}
	}
	> .inputs {
		padding: 1px;
		@include themify($themes) {
			background-color: themed("diceButtonsOutline");
		}
		> button {
			&.btnOver > .icon {
				position: absolute;
				pointer-events: none;
				width: 22px;
				height: 22px;
				left: 10px;
				top: calc(50% - 11px);
				display: flex;
				align-items: center;
				justify-content: center;
				i,
				svg {
					display: flex;
				}
				transition: transform 0.15s linear;
				@include themify($themes) {
					color: themed("success");
				}
				&.over {
					transform: rotate(0deg);
				}
				&.under {
					transform: rotate(180deg);
				}
			}
		}
	}
	> .sliderBox,
	> .stats,
	> .jackpot,
	> .myBets,
	> .auto,
	> .playSection {
		width: 100%;
		padding: 1px;
		box-sizing: border-box;
		@include themify($themes) {
			background-color: themed("diceOutline");
		}
	}
	> .sliderBox {
		position: relative;
		transition: height 0.3s linear;
		overflow-y: hidden;
		&.on {
			height: 106px;
		}
		&.off {
			height: 0px;
			margin-bottom: -2px;
			> div > .diceSlider {
				pointer-events: none;
				user-select: none;
			}
		}
		> div {
			display: flex;
			width: 100%;
			padding: 30px 25px;
			@include themify($themes) {
				background-color: themed("diceBackground");
			}
			border-radius: 0px;
			border-top: 4px solid hsla(0, 0%, 100%, 0.04);
			border-bottom: 0 solid hsla(0, 0%, 100%, 0);
			box-sizing: border-box;
			> .diceSlider {
				position: relative;
				height: 40px;
				flex: 1;
				> .center {
					position: absolute;
					z-index: 2;
					width: 0;
					height: 0;
					top: 50%;
					pointer-events: none;
					touch-action: pan-y;
					&.center1 > .icon {
						position: absolute;
						width: 20px;
						height: 20px;
						left: -10px;
						top: 10px;
						@include themify($themes) {
							color: themed("diceSliderElementsColor");
						}
						display: flex;
						align-items: center;
						justify-content: center;
						transform: rotate(180deg);
						i,
						svg {
							display: flex;
						}
						pointer-events: none;
						touch-action: pan-y;
						user-select: none;
					}
					> figure {
						position: absolute;
						width: 50px;
						height: 25px;
						left: -25px;
						top: 22px;
						display: flex;
						align-items: flex-end;
						justify-content: center;
						pointer-events: none;
						user-select: none;
						font-weight: bold;
						background-color: transparent;
						@include themify($themes) {
							color: themed("diceSliderElementsColor");
						}
						border-radius: 5px;
						box-sizing: border-box;
						padding-bottom: 3px;
					}
					&.center2 {
						z-index: 7;
						> .icon {
							box-sizing: border-box;
							background-color: white;
							border: 2px solid black;
							border-radius: 5px;
							position: absolute;
							width: 40px;
							height: 20px;
							left: -20px;
							bottom: -10px;
							color: black;
							display: flex;
							align-items: center;
							justify-content: center;
							// transform: rotate(90deg);
							i,
							svg {
								display: flex;
							}
							pointer-events: none;
							user-select: none;
						}
					}
				}
				> .outcome {
					left: 0;
					position: absolute;
					top: 50%;
					pointer-events: none;
					z-index: 6;
					width: 0;
					height: 0;
					&.animFast {
						transition: left 0.04s ease-in-out;
					}
					&.anim {
						transition: left 0.06s ease-in-out;
					}
					&.animSlow {
						transition: left 0.1s ease-in-out;
					}
					> .icon {
						position: absolute;
						width: 20px;
						height: 20px;
						left: -10px;
						bottom: 10px;
						@include themify($themes) {
							color: themed("diceSliderElementsColor");
						}
						display: flex;
						align-items: center;
						justify-content: center;
						i,
						svg {
							display: flex;
						}
						pointer-events: none;
						user-select: none;
					}
					> figure {
						position: absolute;
						width: 50px;
						display: flex;
						align-items: center;
						justify-content: center;
						bottom: 25px;
						// background-color: goldenrod;
						font-weight: bold;
						left: -25px;
						border-radius: 5px;
						height: 25px;
						pointer-events: none;
						user-select: none;
						transition: color 0.1s linear;
						@include themify($themes) {
							&.red {
								color: themed("alert");
							}
							&.green {
								color: themed("success");
							}
						}
					}
				}
				> .left,
				> .right {
					position: absolute;
					top: calc(50% - 7px);
					bottom: calc(50% - 7px);
					pointer-events: none;
					touch-action: pan-y;
					z-index: 5;
					@include themify($themes) {
						background-color: themed("sliderBorder");
					}
					&.red {
						@include themify($themes) {
							background-color: themed("alert");
						}
					}
					&.green {
						@include themify($themes) {
							background-color: themed("success");
						}
					}
				}
				> .left {
					left: 0;
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
					border-left: none;
				}
				> .right {
					right: 0;
					border-top-right-radius: 5px;
					border-bottom-right-radius: 5px;
				}
				> .slider {
					position: absolute;
					z-index: 1;
					bottom: 50%;
					left: 0;
					right: 0;
					-webkit-appearance: none;
					appearance: none;
					width: 100%;
					height: 0px;
					outline: none;
					padding: 0;
				}
				/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
				@mixin thumb {
					-webkit-appearance: none; /* Override default look */
					appearance: none;
					width: 50px;
					height: 50px;
					cursor: pointer;
					border-radius: 5px;
					background-color: transparent;
					outline: none;
					border: none;
				}
				> .slider::-webkit-slider-thumb {
					@include thumb();
				}
				> .slider::-moz-range-thumb {
					@include thumb();
				}
			}
		}
	}
	> .stats {
		overflow: hidden;
		// b {
		// 	display: flex;
		// 	font-size: 25.3px;
		// 	line-height: 12px;
		// 	i {
		// 		display: flex;
		// 		transform-origin: center;
		// 		transform: scale(0.9);
		// 		&.n12 {
		// 			transform: scale(0.8333);
		// 		}
		// 		&.n13 {
		// 			transform: scale(0.7692);
		// 		}
		// 		&.n14 {
		// 			transform: scale(0.7143);
		// 		}
		// 		&.n15 {
		// 			transform: scale(0.6667);
		// 		}
		// 		&.n16 {
		// 			transform: scale(0.625);
		// 		}
		// 		&.n17 {
		// 			transform: scale(0.5882);
		// 		}
		// 		&.n18 {
		// 			transform: scale(0.5556);
		// 		}
		// 		&.n19 {
		// 			transform: scale(0.5263);
		// 		}
		// 		&.n20 {
		// 			transform: scale(0.5);
		// 		}
		// 		&.n21 {
		// 			transform: scale(0.4762);
		// 		}
		// 		&.n22 {
		// 			transform: scale(0.4545);
		// 		}
		// 		&.n23 {
		// 			transform: scale(0.4348);
		// 		}
		// 		&.n24 {
		// 			transform: scale(0.4167);
		// 		}
		// 		&.n25 {
		// 			transform: scale(0.4);
		// 		}
		// 		&.n26 {
		// 			transform: scale(0.3846);
		// 		}
		// 		&.n27 {
		// 			transform: scale(0.3704);
		// 		}
		// 		&.n28 {
		// 			transform: scale(0.3571);
		// 		}
		// 		&.n29 {
		// 			transform: scale(0.3448);
		// 		}
		// 		&.n30 {
		// 			transform: scale(0.3333);
		// 		}
		// 	}
		// }
		i {
			opacity: 0.7;
			font-weight: 100 !important;
		}
		> .wrapper {
			> .header,
			> .footer {
				width: 100%;
				display: flex;
			}
			> .header {
				> .button3d {
					@include themify($themes) {
						background-color: themed("diceOutline");
					}
					padding: 1px;
					width: auto;
					flex: 1;
					&:not(:last-child) {
						margin-right: 2px;
					}
					.diceBtn {
						flex-direction: row;
						padding: 0;
						img {
							width: 22px;
							margin-top: 2px;
							margin-right: 5px;
						}
					}
				}
				margin-bottom: 5px;
			}
			> .footer {
				flex-wrap: wrap;
				margin-bottom: 5px;
				> figure {
					&.off {
						display: none;
					}
					position: relative;
					flex: 1;
					display: flex;
					flex-direction: column;
					align-items: center;
					min-width: 130px;
					margin: 2px;
					background-color: rgba(255, 255, 255, 0.03);
					padding: 2px;
					overflow: hidden;
					> .btnStatsReset {
						position: absolute;
						height: calc(100% - 8px);
						top: 4px;
						left: 4px;
						width: 34px;
						transition: left 0.3s linear;
						&._off {
							user-select: none;
							left: -40px;
						}
					}
					> label {
						margin-bottom: 5px;
						white-space: nowrap;
						font-weight: 500;
					}
					> .values {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						> b {
							font-weight: bold;
							font-family: "Roboto Mono";
							&:not(:last-child) {
								margin-right: 5px;
							}
							&.success {
								@include themify($themes) {
									color: themed("success");
								}
							}
							&.warn {
								@include themify($themes) {
									color: themed("cgold");
								}
							}
							&.alert {
								@include themify($themes) {
									color: themed("alert");
								}
							}
						}
					}
				}
			}
			> .chartHeader {
				position: relative;
				margin: 2px;
				padding: 2px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				> * {
					margin-right: 5px;
				}
				@include themify($themes) {
					background-color: rgba(255, 255, 255, 0.03);
				}
				> .resetChart {
					width: 34px;
					height: 29px;
					padding: 0;
					margin-left: 2px;
					flex: none;
				}
				> .f1 {
					flex: 1;
				}
				> .leg {
					height: 10px;
					width: 10px;
					&.leg-a {
						@include themify($themes) {
							background-color: themed("chartBalanceStPointColor");
						}
					}
					&.leg-b {
						@include themify($themes) {
							background-color: themed("chartBalanceCurveColor");
						}
					}
				}
			}
			> .ct-chart {
				position: relative;
				margin: 2px;
				@include themify($themes) {
					background-color: rgba(255, 255, 255, 0.03);
				}

				.ct-label {
					font-size: 0.65rem;
					@include themify($themes) {
						color: themed("linkColor");
					}
				}
				.ct-series-a > .ct-line {
					@include themify($themes) {
						stroke: themed("chartBalanceStPointColor");
					}
				}
				.ct-series-b > .ct-line {
					@include themify($themes) {
						stroke: themed("chartBalanceCurveColor");
					}
				}
			}

			width: 100%;
			padding: 5px;
			display: flex;
			flex-direction: column;
			@include themify($themes) {
				background-color: themed("diceBackground");
			}
			border-radius: 0px;
			border-top: 4px solid hsla(0, 0%, 100%, 0.04);
			border-bottom: 0 solid hsla(0, 0%, 100%, 0);
		}
		// transition: max-height 0.3s linear;
		// &.on {
		// 	max-height: 255px;
		// }
		&.off {
			max-height: 0px;
			margin-top: -1px;
		}
	}
	> .jackpot {
		> .wrapper {
			flex: 1;
			display: flex;
			align-items: center;
			flex-direction: row;
			@include themify($themes) {
				background-color: themed("diceBackground");
			}
			> figure {
				@include themify($themes) {
					color: themed("diceJackColor");
				}
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 20px;
				border-radius: 3px;
				line-height: 0;
				font-weight: bold;
				font-size: 20px;
				user-select: none;
				&.on {
					@include themify($themes) {
						color: themed("diceJackOnColor");
						background-color: themed("diceJackOnBackgroundColor");
					}
				}
				&:not(:last-child) {
					margin-right: 3px;
				}
			}
		}
		> button.luckyNumber {
			flex: none;
			margin-left: 1px;
			height: 100%;
			width: 50px;
			> svg {
				height: 30px;
				.text {
					fill: none;
					stroke-width: 0.5px;
					stroke-linejoin: round;
					stroke-dasharray: 50 150;
					stroke-dashoffset: 0;
					animation: stroke 6s infinite linear;
					$colors: gold, lightgreen, yellowgreen, lawngreen, greenyellow;
					@for $i from 1 through length($colors) {
						&:nth-child(5n + #{$i}) {
							stroke: nth($colors, $i);
							animation-delay: $i * -1.2s;
						}
					}
				}
				@keyframes stroke {
					100% {
						stroke-dashoffset: -400;
					}
				}
			}
		}
		> .x {
			flex: none;
			height: inherit;
			width: 50px;
			display: flex;
			flex-direction: column;
			margin-right: 1px;
			> div {
				font-size: 13px;
				font-weight: bold;
				width: 100%;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: background-color 0.15s ease;
				user-select: none;
				&:not(:last-child) {
					margin-bottom: 1px;
				}
				@include themify($themes) {
					background-color: themed("diceBackground");
				}
				&:not(.on) {
					@include themify($themes) {
						color: themed("diceJackColor");
					}
					font-weight: normal;
				}
				&.on {
					@include themify($themes) {
						color: themed("diceJackOnColor");
						background-color: themed("diceJackOnBackgroundColor");
					}
				}
			}
		}
	}
	> .myBets {
		&.off {
			display: none;
		}
		> .wrapper {
			width: 100%;
			padding: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			@include themify($themes) {
				background-color: themed("diceBackground");
			}
			border-radius: 0px;
			border-top: 4px solid hsla(0, 0%, 100%, 0.04);
			border-bottom: 0 solid hsla(0, 0%, 100%, 0);
			box-sizing: border-box;
			> .bets {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				> button {
					font-size: 16px;
				}
				&.reversed {
					> button:last-child {
						font-size: 20px;
						font-weight: bold;
					}
				}
				&.normal {
					> button:first-child {
						font-size: 20px;
						font-weight: bold;
					}
				}
				> button {
					flex: 1;
					line-height: 20px;
					padding: 0 2px;
					height: 45px;
					margin: 2px;
					border-radius: 5px;
					transition: none;
					> .mb {
						// padding: 1px;
						// padding-bottom: 2px;
						// background-color: rgb(37, 40, 44);
						// border-radius: 3px;
						> i {
							font-size: 13px;
							font-weight: bold;
							line-height: 10px;
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 2px 5px;
							border-radius: 3px;
							color: black;
							@include themify($themes) {
								&.win {
									background-color: themed("diceMyBetsBetAmountWinBackgroundColor");
									color: themed("diceMyBetsBetAmountWinColor");
								}
								&.lose {
									background-color: themed("diceMyBetsBetAmountLoseBackgroundColor");
									color: themed("diceMyBetsBetAmountLoseColor");
								}
							}
							// &.s0 {
							// 	background-color: rgba(0, 255, 255, 0.4);
							// }
							// &.s1 {
							// 	background-color: rgba(173, 255, 47, 0.4);
							// }
							// &.s2 {
							// 	background-color: rgba(255, 165, 0, 0.4);
							// }
							// &.s3 {
							// 	background-color: rgba(0, 110, 255, 0.4);
							// }
							// &.s4 {
							// 	background-color: rgba(216, 130, 238, 0.4);
							// }
							// &.s5 {
							// 	background-color: rgba(207, 60, 60, 0.4);
							// }
						}
					}
					&.j {
						@include themify($themes) {
							border-bottom: themed("diceJackBetBorderBottom");
							> i {
								color: gold; //themed("cgold");
							}
						}
					}
				}
			}
		}
	}
	> .auto {
		flex-direction: column;
		input {
			padding: 0;
			text-align: center;
		}
		&.off {
			display: none;
		}
		> .wrapper {
			&.disabled > * {
				pointer-events: none;
				user-select: none;
				opacity: 0.5;
			}
			width: 100%;
			padding: 5px;
			display: flex;
			align-items: flex-start;
			justify-content: space-evenly;
			@include themify($themes) {
				background-color: themed("diceBackground");
			}
			border-radius: 0px;
			border-top: 4px solid hsla(0, 0%, 100%, 0.04);
			border-bottom: 0 solid hsla(0, 0%, 100%, 0);
			box-sizing: border-box;
			> section {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				padding: 0;
				> h3 {
					font-weight: 500;
				}
				> h3,
				> figure.checks > div > h3,
				> .diceBtn {
					margin-bottom: 3px;
					padding: 0;
				}
				margin: 0;
				&:not(:last-child) {
					margin-right: 5px;
				}
				> * {
					margin: 0;
				}
				> figure {
					display: flex;
					flex: 1;
					&:not(:last-child) {
						margin-bottom: 3px;
					}
					> * {
						margin: 0;
						&:not(:last-child) {
							margin-right: 3px;
						}
					}
					&.off {
						display: none;
						margin: 0;
						padding: 0;
						pointer-events: none;
						user-select: none;
					}
					&.checks {
						> div {
							flex: 1;
							display: flex;
							flex-direction: column;
							> button {
								padding: 0;
								height: 30px;
								min-height: 30px;
								max-height: 30px;
							}
							&.off {
								display: none;
							}
						}
					}
					&:not(.checks):not(.fLimits) {
						button {
							width: 100px;
							flex: none;
						}
					}
				}
				&.autoStopOver {
					flex-direction: row;
					margin-right: 0px;
					width: 100%;
					> figure {
						flex-direction: row;
						button,
						input {
							height: 45px;
						}
						&:not(:last-child) {
							margin-right: 5px;
						}
						> * {
							flex: 1;
							> * {
								width: 100%;
							}
						}
					}
				}

				&.autoL input {
					@include themify($themes) {
						color: themed("alert");
					}
				}
				&.autoW input {
					@include themify($themes) {
						color: themed("success");
					}
				}
			}
			&.numRolls {
				margin-bottom: 1px;
				width: 100%;
				> figure {
					position: relative;
					display: flex;
					flex-direction: row;
					width: 100%;
					> * {
						flex: 1;
						&:not(:last-child) {
							margin-right: 3px;
						}
						> button {
							width: 100%;
						}
					}
					> .lblNumRolls {
						@include themify($themes) {
							background-color: themed("diceBackground");
						}
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 22px;
						&.off {
							display: none;
						}
					}
				}
			}
		}
		&.limits > .wrapper {
			flex-direction: column;
			> .headers {
				margin-bottom: 3px;
				display: flex;
				align-items: center;
				width: 100%;
				> h3 {
					flex: 1;
					font-weight: 500;
				}
			}
			.fLimits > div {
				width: 100px;
				flex: none;
			}
		}
	}
	> .playSection {
		position: relative;
		flex: 1;
		> .expandAuto {
			position: absolute;
			top: 0px;
			right: 10px;
			padding: 1px;
			box-sizing: border-box;
			@include themify($themes) {
				background-color: themed("diceOutline");
			}
			> button {
				width: 34px;
				height: 29px;
				padding: 0;
				i {
					transition: transform 0.3s ease;
				}
				&.down i {
					transform: rotate(180deg);
				}
				&.up i {
					transform: rotate(0deg);
				}
			}
		}
		> .wrapper {
			width: 100%;
			padding: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			@include themify($themes) {
				background-color: themed("diceBackground");
			}
			border-radius: 0px;
			border-top: 4px solid hsla(0, 0%, 100%, 0.04);
			border-bottom: 0 solid hsla(0, 0%, 100%, 0);
			box-sizing: border-box;
			> .btnRollWrapper {
				padding: 1px;
				// max-width: 100px;
				// width: 100px;
				box-sizing: border-box;
				@include themify($themes) {
					background-color: themed("diceOutline");
				}
				.btnRoll {
					width: 100%;
					font-size: 24px;
					font-weight: bold;
					white-space: nowrap;
					padding: 0 5px;
				}
			}
			> .simpleDropDownWrapper {
				display: flex;
				height: 35px;
				position: relative;
				padding: 1px;
				// max-width: 100px;
				// width: 100px;
				box-sizing: border-box;
				margin-right: 3px;
				@include themify($themes) {
					background-color: themed("diceOutline");
				}
				> * {
					&:not(:last-child) {
						margin-right: 5px;
					}
					height: 100%;
					.btnHeader {
						height: 100%;
						> div {
							display: flex;
							align-items: center;
							justify-content: flex-start;
						}
						> .icon {
							margin: 0 0;
						}
					}
					.btnBody {
						width: 100%;
						margin: 2px 0;
					}
				}
			}
		}
	}
	> .helpers {
		padding: 1px;
		@include themify($themes) {
			background-color: themed("diceOutline");
		}
		box-sizing: border-box;
		> button:not(.flex1) {
			max-width: 50px;
			width: 50px;
		}
		> .flex1 {
			flex: 1;
			pointer-events: none;
		}
		button.diceBtn > b {
			font-size: 14px;
			font-weight: 700;
		}
	}
}

@mixin statsReposition {
	.diceWrapper {
		display: flex;
		justify-content: center;
		.diceGame {
			max-width: 769px;
			min-height: 470px;
			align-items: flex-start;
			border-radius: 5px;
			// overflow-x: hidden;
			transition: max-width 0.3s linear;
			.stats {
				position: absolute;
				height: auto;
				right: 0px;
				width: 320px;
				top: 45px;
				bottom: 1px;
				overflow: hidden;
				transition: right 0.3s linear;
				&.off {
					right: -320px;
					display: none;
				}
			}
		}
		&.statsOff {
			.diceGame {
				max-width: 450px;
			}
		}
	}
}
@media (min-width: (780px + $chatPaneWidth)) {
	.chatOn {
		@include statsReposition();
	}
}
@media (min-width: 780px) {
	.chatOff {
		@include statsReposition();
	}
}

@media (min-width: (440px + $chatPaneWidth)) {
	.chatOn {
		.diceGame > .stats > .wrapper > .footer > figure {
			min-width: 140px;
		}
	}
}
@media (min-width: 440px) {
	.chatOff {
		.diceGame > .stats > .wrapper > .footer > figure {
			min-width: 140px;
		}
	}
}
