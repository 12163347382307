.docMgr {
	> *:not(:last-child) {
		margin-bottom: 10px;
	}
	> main {
		> .block {
			display: flex;
			flex-direction: column;
			> *:not(:last-child) {
				margin-bottom: 5px;
			}
		}
	}
	.actionBtnsWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		> .btnDefault {
			padding: 0px!important;
			&:last-child {
				margin-left: 3px;
			}
		}
	}
	> footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		> *:not(:last-child) {
			margin-right: 5px;
		}
		> .buttons {
			display: flex;
			> *:not(:first-child) {
				margin-left: 5px;
			}
		}
	}
}
