.promotion {
	padding: 5px;
	padding-bottom: 70px;
	.freespinsTab {
		> button {
			position: relative;
			._mark {
				position: absolute;
				right: 3px;
				height: 24px;
				width: 32px;
				border-radius: 16px;
				font-size: 14px;
				@include themify($themes) {
					background-color: themed("alert");
					color: white;
				}
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
