.chatPane {
	position: fixed;
	z-index: 5;
	height: 100%;
	width: $chatPaneWidth;
	font-size: 14px;
	@media (max-width: ($chatPaneWidth + 399px)) {
		width: 100%;
	}
	@include themify($themes) {
		background-color: themed("chatPaneBackgroundColor");
		color: themed("containerColor");
	}
	&.left {
		left: 0;
	}
	&.right {
		right: 0;
		@media (min-width: ($chatPaneWidth + 400px)) {
			> .content > .topButtons > .menuButton {
				display: none;
			}
		}
	}
	&.off {
		width: 0px;
		> .content {
			pointer-events: none;
			display: none;
		}
	}
	> .content {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		> .chatOverlay1 {
			position: absolute;
			@include themify($themes) {
				background-color: themed("emojimartBackgroundColor");
				color: themed("containerColor");
			}
			padding: 5px 10px;
			left: 0;
			right: 0;
			top: $headerHeight + $comButtonsHeight;
			bottom: $chatFooterHeight + 10px;
			z-index: 2;
			&.off {
				display: none;
			}
			&.on {
				display: flex;
				flex-direction: column;
			}
			> header {
				height: 35px;
				box-sizing: border-box;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-bottom: 5px;

				> button {
					&:not(:last-child) {
						margin-right: 1px;
					}
					&.btnCloseOverlay {
						> * {
							padding: 0 !important;
						}
					}
				}
			}
			> .chatEmoji {
				margin-bottom: 5px;
			}
			> footer {
				padding: 1px;
				@include themify($themes) {
					// background-color: themed("chatPaneBackgroundColor");
					color: themed("containerColor");
					background-color: themed("diceOutline");
				}
				height: 35px;
				box-sizing: border-box;
				display: flex;
				> button {
					&:not(:last-child) {
						margin-right: 1px;
					}
					flex: 1;
					&.btnEmoji {
						> .em {
							width: 20px;
							height: 20px;
							pointer-events: none;
						}
					}
				}
			}
		}
		> .topButtons {
			> .btnChatClose,
			> .btnChatReposition {
				background-color: transparent;
			}
			@media (max-width: ($chatPaneWidth + 319px)) {
				> .btnChatReposition {
					display: none;
				}
			}
			height: $headerHeight;
			min-height: $headerHeight;
			max-height: $headerHeight;
			position: relative;
			z-index: 1;
			width: 100%;
			box-sizing: border-box;
			padding: 2px;
			display: flex;
			@include themify($themes) {
				background-color: themed("headerBackgroundColor");
				border-bottom: themed("headerBorderBottom");
			}
			> .btnIcon {
				margin: auto 2px;
				> i {
					padding: 0 !important;
				}
			}
			> .space {
				flex: 1;
			}
			> .onlineTotalWrapper {
				margin: auto 2px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				> .circle {
					width: 10px;
					height: 10px;
					background-color: lime;
					margin: 5px;
					border-radius: 50%;
				}
				> .onlineTotal {
					min-width: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 28px;
					padding: 0 5px;
					border-radius: 5px;
					@include themify($themes) {
						background-color: themed("shade01");
					}
					> b {
						font-size: 16px;
						font-weight: bold;
					}
				}
			}
		}
		> .comButtons {
			height: $comButtonsHeight;
			min-height: $comButtonsHeight;
			max-height: $comButtonsHeight;
			position: relative;
			width: 100%;
			box-sizing: border-box;
			padding: 1px;
			display: flex;
			flex-direction: row;
			align-items: center;
			> button {
				height: 100%;
				> .mark {
					@keyframes blinkingText {
						0% {
							opacity: 1;
						}
						15% {
							opacity: 0;
						}
						85% {
							opacity: 1;
						}
					}
					pointer-events: none;
					z-index: 1;
					top: -10px;
					left: unset;
					right: 0;
					height: 24px;
					width: 32px;
					border-radius: 16px;
					font-size: 14px;
					@include themify($themes) {
						&.on {
							background-color: themed("chatComButtonsMarkBackgroundColorOn");
							color: themed("chatComButtonsMarkColorOn");
							// animation: blinkingText 1.2s infinite;
						}
						&.off {
							opacity: 0;
						}
					}
				}
			}
			@include themify($themes) {
				background-color: themed("diceButtonsOutline");
				border-bottom: themed("comButtonsBorderBottom");
			}
		}
		> .headlineWrapper {
			box-sizing: border-box;
			display: flex;
			align-items: flex-end;
			width: 100%;
			padding: 0 10px;
			height: 15px;
			margin-top: 5px;

			> h3.headline {
				display: flex;
				align-items: flex-end;
				position: relative;
				width: 100%;
				box-sizing: border-box;
				font-weight: bold;
				font-size: 14px;
				user-select: none;
			}
			> .onlineRoomCurrentWrapper {
				height: 100%;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				> .circle {
					width: 10px;
					height: 10px;
					background-color: lime;
					border-radius: 50%;
					margin: 5px;
				}
				> .onlineRoomCurrent {
					min-width: 50px;
					@include themify($themes) {
						background-color: themed("shade01");
					}
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100%;
					> b {
						font-size: 16px;
						font-weight: 700;
					}
					padding: 0 5px;
					border-radius: 5px;
				}
			}
		}
	}
}
