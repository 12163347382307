section.docs {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	padding: 5px 20px;
	> .docItem {
		flex: 1;
		// min-width: 200px;
		min-width: 100%;
		@include themify($themes) {
			background-color: themed("shade01");
			background-image: themed("upTransparent");
		}
		box-shadow: inset 0px -2px 10px 1px rgba(255, 255, 255, 0.05), 0px 0px 15px 1px rgba(0, 0, 0, 0.3);
		box-sizing: border-box;
		padding: 20px;
		margin: 5px;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		> *:not(:last-child) {
			margin-bottom: 10px;
		}
		h1 {
			padding: unset;
			margin: 10px 0;
		}
		h2 {
			margin: 10px 0;
		}
		li {
			@include themify($themes) {
				// background-color: themed("shade01");
				color: themed("containerColor", true);
			}
		}
		img {
			border-radius: 10px;
			border: 2px solid rgba(255, 255, 255, 0.1);
			max-width: 99%;
		}
		> h2 {
			font-weight: bold;
			font-size: 18px;
			padding: 5px;
			border-radius: 6px;
			// @include themify($themes) {
			// 	background-color: themed("shade01");
			// }
		}
		> h3 {
			font-size: 12px;
		}
		> .showMore {
			width: 100%;
			> button {
				width: 100%;
				height: 20px;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				> b {
					margin-right: 15px;
					font-size: 11px;
					@include themify($themes) {
						color: themed("toastColorDefault");
					}
				}
				> .arrow {
					border-style: solid;
					border-width: 0 3px 3px 0;
					@include themify($themes) {
						border-color: themed("toastColorDefault");
					}
					display: inline-block;
					padding: 3px;
					transform: rotate(45deg);
					transition: all 0.2s ease-in-out;
				}
				&.down > .arrow {
					transform: rotate(45deg);
				}
				&.up > .arrow {
					transform: rotate(225deg);
				}
			}
		}
		&:not(:last-child) {
			> .showMore {
				display: none;
			}
		}
		> .txt {
			&.off {
				display: none;
			}
			&.preview,
			&.body {
				column-rule: 1px solid rgba(255, 255, 255, 0.3);
				column-gap: 20px;
				column-count: 1;
				line-height: 16px;
			}
		}
	}
	li {
		list-style: circle;
		margin-left: 15px;
	}
}
// @media (min-width: (600px + $chatPaneWidth)) {
// 	.chatOn {
// 		.docItem > .txt {
// 			&.preview,
// 			&.body {
// 				column-count: 2;
// 			}
// 		}
// 	}
// }
// @media (min-width: 600px) {
// 	.chatOff {
// 		.docItem > .txt {
// 			&.preview,
// 			&.body {
// 				column-count: 2;
// 			}
// 		}
// 	}
// }
// @media (min-width: (900px + $chatPaneWidth)) {
// 	.chatOn {
// 		.docItem > .txt {
// 			&.preview,
// 			&.body {
// 				column-count: 3;
// 			}
// 		}
// 	}
// }
// @media (min-width: 900px) {
// 	.chatOff {
// 		.docItem > .txt {
// 			&.preview,
// 			&.body {
// 				column-count: 3;
// 			}
// 		}
// 	}
// }
