.grecaptcha-badge {
	position: absolute;
	z-index: -1;
	pointer-events: none;
	opacity: 0;
}
.registration {
	$w: 280px;
	position: relative;
	display: flex;
	flex-direction: column;
	width: $w + 20px;
	$padding: 10px;
	margin: 0 auto;
	// @include themify($themes) {
	// 	background-color: themed("shade005");
	// }
	border-radius: 5px;
	margin-top: 10px;
	> .regLogo {
		position: absolute;
		left: 6px;
		top: -7px;
	}
	.terms {
		font-size: 14px;
	}
	> .anim {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10px;
	}
	> .header {
		display: flex;
		width: 100%;
		> button {
			flex: 1;
			word-break: keep-all;
			white-space: nowrap;
			border-radius: 0;
			display: block;
			text-align: center;
			position: relative;
			// &::after {
			// 	content: "";
			// 	z-index: 1;
			// 	transition: all 0.3s ease-in-out;
			// 	position: absolute;
			// 	bottom: 0;

			// 	margin: 0 auto;
			// 	height: 1px;
			// 	@include themify($themes) {
			// 		background-color: themed("toolbarButtonLineColor");
			// 	}
			// }
			&.btnHeader1 {
				border-top-left-radius: 5px;
			}
			&.btnHeader2 {
				border-top-right-radius: 5px;
			}
			&.btnHeader1,
			&.btnHeader2 {
				transition: all 0.3s ease-in-out, outline 0;
			}
			&.btnHeader1.selected,
			&.btnHeader2.selected {
				font-size: 17px;
			}
			&.btnHeader1:not(.selected),
			&.btnHeader2:not(.selected) {
				opacity: 0.5;
			}
			&.btnHeader1::after {
				left: 100%;
				right: 0%;
			}
			&.btnHeader2::after {
				left: 0%;
				right: 100%;
			}
			&.btnHeader1.selected::after {
				left: 0%;
				right: 0%;
			}
			&.btnHeader2.selected::after {
				left: 0%;
				right: 0%;
			}
		}
		margin: 10px 0;
	}
	> .block,
	> .sections > .tape > form > .block {
		margin-bottom: 10px;
		display: flex;
		> button {
			width: 140px;
			&#btnForgot {
				width: auto;
			}
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			white-space: nowrap;
		}
	}
	> .sections {
		will-change: transform;
		width: $w;
		min-height: 230px;
		position: relative;
		overflow-x: hidden;
		> .tape {
			will-change: transform;
			position: absolute;
			transition: left 0.3s ease-in-out;
			width: $w * 2 + $padding;
			top: 0;
			&.c0 {
				left: 0;
			}
			&.c1 {
				left: -$w - $padding;
			}
			&.c2 {
				left: -$w * 2 - $padding * 2;
			}
			> form {
				position: absolute;
				width: $w;
				&.sRegistration {
					left: 0;
				}
				&.sLogin {
					left: $w + $padding;
					.socialButtons {
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 100%;
					}
					> .block {
						position: relative;
						> .eye {
							transition: opacity 0.3s ease-in-out;
							&.on {
								opacity: 1;
							}
							&.off {
								opacity: 0.5;
							}
							position: absolute;
							right: 3px;
							z-index: 1;
							width: 32px;
							height: 32px;
							background-color: transparent;
							top: 3px;
							&:hover,
							&:active {
								background-color: transparent !important;
							}
						}
					}
				}
			}
		}
	}
	padding: 20px 10px;
}
