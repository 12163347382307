.crashAuto {
	width: 100%;
	padding-top: 30px;
	display: flex;
	box-sizing: border-box;
	min-width: 300px;
	padding: 5px;
	flex-direction: column;
	> .hd {
		padding: 14px 0;
	}
	> .roll,
	> .autoLogic > .roll {
		&.off {
			display: none;
		}
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		> .btnSettings {
			position: absolute;
			left: 10px;
		}
		> .btnBet {
			margin-right: 0px;
		}
		> .btnBet,
		> .btnRoll {
			font-weight: bold;
			border-radius: 5px;
			padding: 5px;
			max-width: 120px;
			height: 50px;
			// max-width: 120px;
			// height: 50px;
		}
		> .btnToggleAuto {
			flex-direction: row;
			position: absolute;
			right: 10px;
			padding: 5px;
			border-radius: 5px;
		}
	}
	input {
		padding: 0;
		text-align: center;
	}
	&.off {
		display: none;
	}
	> .autoLogic {
		width: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
		> .wrapper {
			&.off {
				display: none;
			}
			&.disabled > * {
				pointer-events: none;
				user-select: none;
				opacity: 0.5;
			}
			&.limits {
				flex-direction: column;
				> .headers {
					flex-direction: row;
					display: flex;
					width: 100%;
					margin-bottom: 3px;
				}
			}
			width: 100%;
			padding: 5px;
			display: flex;
			align-items: flex-start;
			justify-content: space-evenly;
			@include themify($themes) {
				background-color: themed("diceBackground");
			}
			border-radius: 0px;
			border-top: 4px solid hsla(0, 0%, 100%, 0.04);
			border-bottom: 0 solid hsla(0, 0%, 100%, 0);
			box-sizing: border-box;
			> section {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				padding: 0;
				> h3 {
					font-weight: 500;
				}
				> h3,
				> figure.checks > div > h3,
				> .diceBtn {
					margin-bottom: 3px;
					padding: 0;
				}
				margin: 0;
				&:not(:last-child) {
					margin-right: 5px;
				}
				> * {
					margin: 0;
				}
				> figure {
					display: flex;
					flex: 1;
					&:not(:last-child) {
						margin-bottom: 3px;
					}
					> * {
						margin: 0;
						&:not(:last-child) {
							margin-right: 3px;
						}
					}
					&.off {
						display: none;
						margin: 0;
						padding: 0;
						pointer-events: none;
						user-select: none;
					}
					&.checks {
						> div {
							flex: 1;
							display: flex;
							flex-direction: column;
							> button {
								padding: 0;
								height: 30px;
								min-height: 30px;
								max-height: 30px;
							}
							&.off {
								display: none;
							}
						}
					}
					&:not(.checks):not(.fLimits) {
						button {
							width: 100px;
							flex: none;
						}
					}
				}
				&.autoStopOver {
					flex-direction: row;
					margin-right: 0px;
					width: 100%;
					> figure {
						flex-direction: row;
						button,
						input {
							height: 45px;
						}
						&:not(:last-child) {
							margin-right: 5px;
						}
						> * {
							flex: 1;
							> * {
								width: 100%;
							}
						}
					}
				}

				&.autoL input {
					@include themify($themes) {
						color: themed("alert");
					}
				}
				&.autoW input {
					@include themify($themes) {
						color: themed("success");
					}
				}
			}
			&.crashInfo {
				display: flex;
				> div {
					> b:first-child {
						margin-right: 5px;
					}
				}
			}
			&.numRolls {
				margin-bottom: 1px;
				width: 100%;
				> figure {
					position: relative;
					display: flex;
					flex-direction: row;
					width: 100%;
					> * {
						flex: 1;
						&:not(:last-child) {
							margin-right: 3px;
						}
						> button {
							width: 100%;
						}
					}
					> .lblNumRolls {
						@include themify($themes) {
							background-color: themed("diceBackground");
						}
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 22px;
						&.off {
							display: none;
						}
					}
				}
			}
		}
		> .wrapper.limits {
			flex-direction: column;
			> .headers {
				margin-bottom: 3px;
				display: flex;
				align-items: center;
				width: 100%;
				> h3 {
					flex: 1;
					font-weight: 500;
				}
			}
			.fLimits > div {
				width: 100px;
				flex: none;
			}
		}
		> .btnToggleAutoWrapper {
			z-index: 1;
			position: absolute;
			right: 0;
			bottom: 0;
			height: 0;
			> .btnToggleAuto {
				width: 50px;
				position: absolute;
				top: 0;
				right: 8px;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
			}
		}
	}
}
