.chatFriends {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: $chatLocationPadding;
	> section {
		padding: $chatLocationPadding;
		height: 0; //without this overflows
		overflow: hidden;
		@include themify($themes) {
			background-color: themed("shade005");
		}
		flex: 1;
		> h3 {
			margin-bottom: 5px;
			opacity: 0.6;
		}
		&:not(:last-child) {
			margin-bottom: $chatLocationPadding;
		}
		> .listScroll {
			.friend {
				width: calc(100% - 30px);
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				box-sizing: border-box;
				padding: 5px;
				margin-bottom: 3px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				> .actions {
					width: unset;
					> button {
						width: 28px;
						height: 28px;
					}
					margin-right: 5px;
					.content {
						min-height: unset;
						> .buttons > button {
							min-width: 200px;
							&:not(:last-child) {
								margin-bottom: 3px;
							}
						}
					}
				}
				> b {
					flex: 1;
				}
				> .btnAccept,
				> .btnReject {
					min-height: unset;
					width: 28px;
					height: 28px;
				}
				> .btnReject {
					margin-left: 5px;
				}
			}
			// 	button {
			// 		width: 100%;
			// 		padding: 5px;
			// 		margin-bottom: 3px;
			// 		display: flex;
			// 		flex-direction: row;
			// 		align-items: center;
			// 		> .entry {
			// 			flex: 1;
			// 			display: flex;
			// 			> * {
			// 				&:not(:last-child) {
			// 					margin-right: 10px;
			// 				}
			// 			}
			// 		}
			// 		> .roomOnline {
			// 			margin-right: 10px;
			// 			font-family: "Roboto Mono";
			// 		}
			// 	}
			// }
		}
	}
}
