.withdrawtabs {
	display: flex;
	flex-direction: column;
	padding: 10px 0;
	min-width: 280px;
	max-width: 750px;
	margin: auto;
}

.withdraw {
	>.wrapper {
		display: flex;
		flex-direction: column;
		padding: 10px 0;
		min-width: 280px;
		max-width: 750px;
		margin: auto;

		>* {
			border-radius: 5px;
		}

		>*:not(:last-child) {
			margin-bottom: 5px;
		}

		&.warning {
			>*:not(:last-child) {
				margin-bottom: 10px;
			}

			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			padding: 30px;

			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
			}

			>h3.warn {
				@include themify($themes) {
					color: themed("warn");
				}
			}

			>h3.bonusName {
				@include themify($themes) {
					color: themed("warn");
				}

				font-size: 22px;
				font-weight: bold;
			}

			>button {
				>b {
					font-weight: bold;
					padding: 10px;
				}
			}
		}

		>main {
			padding: 10px;

			@include themify($themes) {
				background-color: themed("profileGeoBackgroundColor");
				color: themed("profileGeoColor");
			}

			>*:not(:last-child) {
				margin-bottom: 10px;
			}

			>.btns {
				display: flex;
				align-items: center;

				>button {
					&:disabled {
						opacity: 1 !important;
					}

					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;

					>b {
						word-break: break-all;
					}

					&.btnNetwork:not(:last-child) {
						margin-right: 5px;
					}
				}

				>a>button {
					width: 38px;
					margin-left: 5px;
					align-items: center;
					justify-content: center;
				}
			}

			>button {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			>.info {
				display: flex;
				align-items: center;

				>.btnLink {
					margin: 0 5px;
				}

				>b:last-child {
					font-weight: bold;
					font-size: 18px;
					margin-left: 5px;

					&:not(.success):not(.alert) {
						@include themify($themes) {
							color: themed("warn");
						}
					}

					&.success {
						@include themify($themes) {
							color: themed("success");
						}
					}

					&.alert {
						@include themify($themes) {
							color: themed("alert");
						}
					}
				}
			}

			>label,
			>.humanDetector>label {
				display: flex;
				align-items: center;

				>b:not(:last-child) {
					margin-right: 5px;
					font-weight: bold;
					font-size: 18px;
				}

				>img {
					margin-left: 5px;
				}

				&.warn {
					@include themify($themes) {
						color: themed("warn");
					}
				}

				&.alert {
					@include themify($themes) {
						color: themed("alert");
					}
				}
			}
		}
	}
}