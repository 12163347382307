@media (min-width: 550px) {
	.slotTransaction {
		min-width: 500px;
	}
}
.slotTransaction {
	margin-top: 5px;
	display: flex;
	flex-direction: column;
	padding: 10px;
	gap: 10px;
	width: 100%;
	min-height: 300px !important;
	overflow-x: hidden;
	position: relative;
	> .loading {
		height: 300px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	@include themify($themes) {
		background-color: themed("formBackgroundColor");
		color: themed("formColor");
	}

	> img {
		border-radius: 5px;
	}
	> h2 {
		font-size: 22px;
		font-weight: bold;
		margin: 0 10px;
		text-align: center;
		height: 30px;
		justify-content: center;
		display: flex;
		align-items: center;
	}
	> .sharing {
		> .betid {
			display: flex;
			gap: 5px;
			align-items: center;
			justify-content: center;
		}
		flex: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	> div {
		flex: 1;
		display: flex;
		flex-direction: column;
		> .info {
			box-sizing: border-box;
			flex: 1;
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 5px;
			background-color: rgba(255, 255, 255, 0.05);
			padding: 10px;
			border-radius: 5px;

			> .row {
				gap: 5px;
				> .col {
					flex: 1;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 5px;
					background-color: rgba(255, 255, 255, 0.07);
					padding: 5px;
					.success {
						@include themify($themes) {
							color: themed("success");
						}
					}
				}
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				&:not(.c) {
					background-color: rgba(255, 255, 255, 0.07);
					padding: 5px;
				}
				flex: 1;
				border-radius: 5px;
				font-size: 15px;
				> b:last-child:not(.time) {
					font-weight: bold;
					font-size: 17px;
					@include themify($themes) {
						color: themed("success");
					}
					&.provider {
						@include themify($themes) {
							color: themed("warn");
						}
					}
					&.small {
						font-size: 14px;
						color: whitesmoke;
					}
				}
				&.time {
					justify-content: flex-end;
					display: flex;
					gap: 10px;
					> * {
						font-size: 12px !important;
						color: white !important;
					}
				}
			}
		}
	}
}
.slotSlide {
	$slotsH3Height2: 95px;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	@media (hover: hover) {
		&:hover {
			> .bg {
				transform: scale(1.1);
				// filter: brightness(1.2) contrast(1.1);
				filter: saturate(50%) brightness(0.5);
			}
			> .content > .buttons {
				pointer-events: auto;
			}
		}
		&:not(:hover) {
			> .bg {
				transform: scale(1);
				// filter: brightness(1) contrast(1);
				filter: saturate(100%) brightness(1);
			}
			> .content > .buttons {
				pointer-events: none;
				> .btnPlaySvg {
					opacity: 0;
					transform: scale(1.75);
				}
			}
		}
	}
	> .bg {
		transform-origin: center;
		transition: transform 0.3s ease, filter 0.3s ease;
		width: 100%;
		position: relative;
		padding-bottom: calc(72% + #{$slotsH3Height2});
		> .imgWrapper {
			transition: all 0.3s ease;
			width: 100%;
			height: calc(100% - #{$slotsH3Height2});
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			&:not(.loaded) {
				@include animPlaceholderShimmerInf;
			}
			> img {
				width: 100%;
				height: 100%;
				opacity: 0;
				transition: opacity 1s ease-in-out;
				&.loaded {
					opacity: 1;
				}
			}
		}
	}
	> .content {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		> .buttons {
			transition: all 0.2s ease-in-out;
			position: absolute;
			bottom: $slotsH3Height;
			left: 0;
			top: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			> .btnPlaySvg {
				fill: rgba(245, 246, 247, 0.9);
				width: 3rem;
				height: 3rem;
				position: absolute;
				left: 50%;
				top: 50%;
				margin-left: -1.5rem;
				margin-top: -1.5rem;
				transform: scale(1.15);
				transition: transform 0.5s, opacity 1s;
			}
			> .btnPlay {
				&.real {
					margin-bottom: 5px;
				}
				transition: all 0.2s ease-in-out;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgba(0, 0, 0, 0.8);
				border-radius: 20px;
				color: white;
				font-size: 18px;
				height: 40px;
				width: 150px;
				border: 3px solid rgba(255, 255, 255, 0.5);
				user-select: none;
				cursor: pointer;
				@media (hover: hover) {
					&:hover {
						color: rgba(255, 255, 255, 1);
					}
					&:not(:hover) {
						color: rgba(255, 255, 255, 0.7);
					}
				}
			}
		}
		> .info {
			box-sizing: border-box;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: $slotsH3Height2;
			display: flex;
			flex-direction: column;
			align-items: center;
			@include themify($themes) {
				background-color: themed("inputBackgroundColor");
			}
			> b {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				@include themify($themes) {
					color: themed("inputColor");
				}
				line-height: 15px;
				font-weight: 500;
				font-size: 13.5px;
				text-align: center;
				user-select: none;
			}
			> .row {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 14px;
				padding: 7px;
				box-sizing: border-box;
				margin: -3px 0;
				> b:first-child {
					font-size: 13px;
					line-height: 9px;
				}
				> b:last-child {
					line-height: 9px;
					font-weight: bold;
					@include themify($themes) {
						color: themed("success");
					}
					&.small {
						font-size: 11px;
						color: rgba(245, 245, 245, 0.7);
					}
				}
			}
		}
		> .providerCap {
			position: absolute;
			top: 0;
			right: 0;
			height: 20px;
			background-color: rgba(0, 0, 0, 0.4);
			display: flex;
			align-items: center;
			font-size: 10px;
			padding: 0 5px;
			border-bottom-left-radius: 10px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			gap:3px;
			.red {
				color: red;
				font-weight: bold;
			}

			.green {
				color: lime;
				font-weight: bold;
			}
		}
	}
}
