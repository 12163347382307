$headerHeight: 50px;
$comButtonsHeight: 35px;
$headlineWrapper: 20px;
$chatPaneWidth: 400px;
$chatLocationPadding: 5px;
$chatFooterHeight: 120px;
$chatAvatarSize: 40px;
$themes: (
	light: (
		cgold: #ceaf00,
		success: #6672fb,
		warn: rgb(236, 209, 117),
		alert: #ff3d55,
		dark: #e2e3e7,
		_grey: grey,
		_cyan: rgb(10, 95, 95),
		shade01: white,
		shade03: rgba(255, 255, 255, 1),
		shade05: rgba(255, 255, 255, 0.5),
		shade005: white,
		upTransparent: linear-gradient(0deg, #6672fb 20%, transparent 40%),
		//input:
		inputSelectionBackgroundColor: #2f3cce,
		inputSelectionColor: white,
		inputBackgroundColor: white,
		inputColor: rgb(11, 39, 77),
		inputBoxShadow: (),
		inputBorder: (
			1px solid #cbcbcb,
		),
		emojiMartBorderColor: #cbcbcb,
		emojimartBackgroundColor: #fff,
		//toast:
		toastBackgroundColorDefault: rgb(73, 88, 107),
		toastColorDefault: white,
		toastBackgroundColorSuccess: green,
		toastColorSuccess: white,
		toastBackgroundColorInfo: rgb(46, 105, 194),
		toastColorInfo: rgb(255, 255, 255),
		toastBackgroundColorError: rgb(201, 13, 13),
		toastColorError: white,
		toastBackgroundColorWarning: rgb(196, 143, 0),
		toastColorWarning: rgb(0, 0, 0),
		//container:
		containerBackgroundColor: #f5f6f9,
		containerColor: #222222,
		//rgb(0, 26, 78),
		fbiBackgroundColor: white,
		//chat:
		chatPaneBackgroundColor: #fff,
		chatMessageBackgroundColor: #f5f6f9,
		chatNewsBackgroundColor: #ffe7e7,
		chatNotificationsBackgroundColor: #f7f7dd,
		chatMessageContentBackgroundColor: #6571fb1a,
		chatReceivedPmBackgroundColor: #6672fb,
		chatReceivedPmColor: white,
		chatMessagesArrivedBackgroundColor: rgba(255, 255, 255, 0.9),
		chatMessageSameBorderLeft: 1px solid #cbcbcb,
		chatComButtonsMarkBackgroundColorOn: red,
		chatComButtonsMarkColorOn: #ccc,
		chatTexareaEditModeBackgroundColor: gold,
		chatTexareaEditModeColor: black,
		chatMessageEditedPrevContentBackgroundColor: #fff1a230,
		//scroll:
		scrollBackgroundColor: rgb(255, 255, 255),
		thumbBackgroundColor: #6672fb,
		//modal:
		modalBackgroundColor: white,
		modalColor: #222,
		//form:
		formBackgroundColor: rgb(255, 255, 255),
		formFooterBackgroundColor: aliceblue,
		formColor: #fff,
		formEditBackgroundColor: hsl(235, 95%, 75%),
		formEditColor: #fff,
		//toggle:
		toggleSliderBackgroundColor: rgba(8, 31, 53, 0.3),
		//link:
		linkColorHover: hsl(235, 25%, 40%),
		linkColor: hsl(235, 25%, 20%),
		linkColorActive: hsl(235, 25%, 10%),
		//btnIcon:
		btnIconBackgroundColor: hsl(228, 33%, 97%),
		btnIconColor: hsl(235, 95%, 69%),
		btnIconBoxShadow: (),
		btnIconBackgroundColorHover: hsl(228, 33%, 100%),
		btnIconColorHover: hsl(235, 95%, 89%),
		btnIconBackgroundColorActive: hsl(228, 33%, 92%),
		btnIconColorActive: hsl(235, 95%, 59%),
		//btnIconAlert:
		btnIconAlertBoxShadow: (),
		btnIconAlertColor: hsl(0, 0%, 100%),
		btnIconAlertBackgroundColor: hsl(353, 100%, 62%),
		btnIconAlertColorHover: hsl(0, 0%, 100%),
		btnIconAlertBackgroundColorHover: hsl(353, 100%, 67%),
		btnIconAlertColorActive: hsl(0, 0%, 100%),
		btnIconAlertBackgroundColorActive: hsl(353, 100%, 57%),
		//btnIconSuccess:
		// 	btnIconSuccessBackgroundColor: hsl(228, 33%, 97%),
		// btnIconSuccessColor: hsl(235, 95%, 69%),
		// btnIconSuccessBoxShadow: (),
		// btnIconSuccessBackgroundColorHover: hsl(228, 33%, 100%),
		// btnIconSuccessColorHover: hsl(235, 95%, 89%),
		// btnIconSuccessBackgroundColorActive: hsl(228, 33%, 92%),
		// btnIconSuccessColorActive: hsl(235, 95%, 59%),
		btnIconSuccessBackgroundColor: hsl(235, 95%, 69%),
		btnIconSuccessColor: hsl(228, 33%, 97%),
		btnIconSuccessBoxShadow: (),
		btnIconSuccessBackgroundColorHover: hsl(235, 95%, 49%),
		btnIconSuccessColorHover: hsl(228, 33%, 77%),
		btnIconSuccessBackgroundColorActive: hsl(235, 95%, 59%),
		btnIconSuccessColorActive: hsl(228, 33%, 92%),
		//btnIconWarn:
		btnIconWarnBoxShadow: (),
		btnIconWarnColor: hsl(0, 0%, 13%),
		btnIconWarnBackgroundColor: hsl(57, 90%, 70%),
		btnIconWarnColorHover: hsl(0, 0%, 33%),
		btnIconWarnBackgroundColorHover: hsl(57, 90%, 75%),
		btnIconWarnColorActive: hsl(0, 0%, 3%),
		btnIconWarnBackgroundColorActive: hsl(57, 90%, 65%),
		//header:
		headerBackgroundColor: #fff,
		headerBorderBottom: (),
		//1px solid #e0e2e6,
		comButtonsBorderBottom: (),
		//0px solid transparent,
		toolbarButtonLineColor: rgb(255, 187, 0),
		//rgb(28, 43, 117),
		//menu separator:
		menuSeparatorBackgroundColor: rgba(68, 121, 170, 0.5),
		//footer:
		footerBackgroundColor: hsl(225, 25%, 90%),
		footerColor: #222,
		footerBorderTop: 1px solid #e2e3e7,
		footerTimeColor: rgb(32, 39, 34),
		//menu:
		menuButtonBackgroundColor: rgb(223, 241, 248),
		menuButtonColor: rgb(11, 39, 77),
		menuButtonBackgroundColorHover: rgb(255, 255, 255),
		menuButtonColorHover: rgb(1, 15, 27),
		menuBackgroundColor: white,
		menuColor: rgb(0, 0, 0),
		menuFigureBorderBottom: 1px solid #e2e3e7,
		menuAvatarBackgroundColor: rgb(28, 67, 104),
		menuHeaderBackgroundColorHover: rgba(255, 255, 255, 0.1),
		menuHeaderColorHover: rgb(11, 39, 77),
		menuStatusSeparatorColor: rgba(255, 255, 255, 0.25),
		//profile:
		profilePlayerBackgroundColor: rgb(255, 255, 255),
		profilePlayerColor: #222,
		profileGeoBackgroundColor: rgba(190, 190, 190, 0.1),
		profileGeoColor: #222,
		profileGeoHeaderBackgroundColor: #f5f6f9,
		profileGeoHeaderColor: #222,
		profileGeoLabelBackgroundColor: transparent,
		profileGeoLabelColor: rgb(60, 67, 70),
		//select:
		selectBackgroundColor: #f2f3ff,
		selectArrowColor: transparent transparent rgb(54, 71, 107) transparent,
		selectColor: #222222,
		selectHeaderBackgroundColor: #f5f6f9,
		selectHeaderColor: #222222,
		selectItemBackgroundColor: rgba(195, 217, 236, 0.5),
		selectItemColor: #222222,
		selectFoundLettersBackgroundColor: #222222,
		selectFoundLettersColor: lime,
		//table:
		tableBackgroundColor: #e4e6ff,
		tableEvenBackgroundColor: rgba(255, 255, 255, 0.02),
		tableOddBackgroundColor: rgba(0, 0, 0, 0.02),
		tableBorder: 1px solid #e2e3e7,
		tableGreenColor: #6672fb,
		tableRedColor: #ff3d55,
		//progress:
		progressGreenBackgroundColor: #6672fb,
		progressGreenColor: black,
		progressRedBackgroundColor: #ff3d55,
		progressRedColor: black,
		//dice:
		diceOutline: #e2e3e7,
		chartBalanceStPointColor: #94cd2d,
		chartBalanceCurveColor: #949494,
		diceButtonInfoBackgroundColor: #fff,
		diceButtonBottomBorder: (
			4px solid rgba(0, 0, 0, 0.04),
		),
		diceButtonBottomBorderToggled: (
			2px solid rgba(0, 0, 0, 0.04),
		),
		diceButtonsOutline: #e2e3e7,
		diceButtonMarkColor: #000,
		diceBackground: white,
		diceJackColor: (),
		diceJackOnColor: #222,
		diceJackOnBackgroundColor: gold,
		diceJackBetBorderBottom: 4px solid gold,
		diceSliderElementsColor: (),
		sliderBorder: 2px solid black,
		//dice mybets
		diceMyBetsBetAmountWinBackgroundColor: #fff,
		diceMyBetsBetAmountWinColor: #6672fb,
		diceMyBetsBetAmountLoseBackgroundColor: #fff,
		diceMyBetsBetAmountLoseColor: #ff3d55,
		//diceBtn default:
		diceButtonColor: hsl(0, 0%, 13%),
		diceButtonBackgroundColor: hsl(228, 33%, 97%),
		diceButtonColorHover: hsl(0, 0%, 33%),
		diceButtonBackgroundColorHover: hsl(228, 33%, 100%),
		diceButtonColorActive: hsl(0, 0%, 3%),
		diceButtonBackgroundColorActive: hsl(228, 33%, 92%),
		//diceBtn red:
		diceButtonRedColor: hsl(0, 0%, 100%),
		diceButtonRedBackgroundColor: hsl(353, 100%, 62%),
		diceButtonRedColorHover: hsl(0, 0%, 100%),
		diceButtonRedBackgroundColorHover: hsl(353, 100%, 67%),
		diceButtonRedColorActive: hsl(0, 0%, 100%),
		diceButtonRedBackgroundColorActive: hsl(353, 100%, 57%),
		//diceBtn yellow:
		diceButtonYellowColor: hsl(0, 0%, 100%),
		diceButtonYellowBackgroundColor: hsl(235, 95%, 69%),
		diceButtonYellowColorHover: hsl(0, 0%, 100%),
		diceButtonYellowBackgroundColorHover: hsl(235, 95%, 74%),
		diceButtonYellowColorActive: hsl(0, 0%, 100%),
		diceButtonYellowBackgroundColorActive: hsl(235, 95%, 64%),
		//diceBtn grey:
		diceButtonGreyColor: hsl(235, 74%, 49%),
		diceButtonGreyBackgroundColor: hsl(228, 33%, 97%),
		diceButtonGreyColorHover: hsl(235, 95%, 69%),
		diceButtonGreyBackgroundColorHover: hsl(228, 33%, 100%),
		diceButtonGreyColorActive: hsl(235, 95%, 39%),
		diceButtonGreyBackgroundColorActive: hsl(228, 33%, 92%),
		//diceBtn Dark:
		diceButtonDarkColor: hsl(0, 0%, 100%),
		diceButtonDarkBackgroundColor: hsl(235, 95%, 59%),
		diceButtonDarkColorHover: hsl(0, 0%, 100%),
		diceButtonDarkBackgroundColorHover: hsl(235, 95%, 64%),
		diceButtonDarkColorActive: hsl(0, 0%, 100%),
		diceButtonDarkBackgroundColorActive: hsl(235, 95%, 54%),
		//diceBtn lightgrey:
		diceButtonLightgreyColor: hsl(235, 95%, 69%),
		diceButtonLightgreyBackgroundColor: hsl(228, 33%, 97%),
		diceButtonLightgreyColorHover: hsl(235, 95%, 89%),
		diceButtonLightgreyBackgroundColorHover: hsl(228, 33%, 100%),
		diceButtonLightgreyColorActive: hsl(235, 95%, 59%),
		diceButtonLightgreyBackgroundColorActive: hsl(228, 33%, 92%),
		//diceBtn green:
		diceButtonGreenColor: hsl(0, 0%, 100%),
		diceButtonGreenBackgroundColor: hsl(235, 95%, 69%),
		diceButtonGreenColorHover: hsl(0, 0%, 100%),
		diceButtonGreenBackgroundColorHover: hsl(235, 95%, 74%),
		diceButtonGreenColorActive: hsl(0, 0%, 100%),
		diceButtonGreenBackgroundColorActive: hsl(235, 95%, 64%),
	),
	dark: (
		cgold: gold,
		success: rgb(61, 255, 71),
		warn: rgb(255, 196, 0),
		alert: #ff3d55,
		dark: rgb(1, 10, 19),
		_grey: grey,
		_cyan: cyan,
		shade01: rgba(255, 255, 255, 0.1),
		shade03: rgba(255, 255, 255, 0.3),
		shade05: rgba(255, 255, 255, 0.5),
		shade005: rgba(255, 255, 255, 0.05),
		upTransparent: linear-gradient(0deg, #0d092f 20%, transparent 40%),
		//input:
		inputSelectionBackgroundColor: rgb(17, 107, 182),
		inputSelectionColor: white,
		inputBorder: none,
		inputBackgroundColor: rgb(21, 21, 39),
		inputColor: rgb(183, 199, 206),
		inputBoxShadow: (
			inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5),
			0 2px 2px 0.5px rgba(46, 53, 70, 0.514),
		),
		emojiMartBorderColor: rgba(46, 105, 194, 0.322),
		emojimartBackgroundColor: rgb(21, 21, 39),
		//toast:
		toastBackgroundColorDefault: rgb(73, 88, 107),
		toastColorDefault: white,
		toastBackgroundColorSuccess: green,
		toastColorSuccess: white,
		toastBackgroundColorInfo: rgb(46, 105, 194),
		toastColorInfo: rgb(255, 255, 255),
		toastBackgroundColorError: rgb(201, 13, 13),
		toastColorError: white,
		toastBackgroundColorWarning: rgb(196, 143, 0),
		toastColorWarning: rgb(0, 0, 0),
		//progress:
		progressGreenBackgroundColor: rgb(150, 247, 150),
		progressGreenColor: black,
		progressRedBackgroundColor: rgb(247, 150, 150),
		progressRedColor: black,
		//container:
		containerBackgroundColor: #0d092f,
		// rgb(33, 47, 75),
		containerColor: rgb(255, 255, 255),
		fbiBackgroundColor: rgba(0, 0, 0, 0.5),
		//chat:
		chatPaneBackgroundColor: rgb(0, 5, 27),
		chatMessageBackgroundColor: hsl(222, 81%, 12%),
		chatNewsBackgroundColor: rgb(47, 12, 60),
		chatNotificationsBackgroundColor: rgb(34, 31, 9),
		chatMessageContentBackgroundColor: rgba(255, 255, 255, 0.05),
		chatMessagesArrivedBackgroundColor: rgba(24, 43, 80, 0.9),
		chatReceivedPmBackgroundColor: rgba(68, 121, 170, 0.5),
		chatReceivedPmColor: (),
		chatMessageSameBorderLeft: 1px solid rgba(255, 255, 255, 0.1),
		chatComButtonsMarkBackgroundColorOn: rgba(255, 80, 80, 0.6),
		chatComButtonsMarkColorOn: white,
		chatTexareaEditModeBackgroundColor: rgb(196, 143, 0),
		chatTexareaEditModeColor: black,
		chatMessageEditedPrevContentBackgroundColor: #ffd70030,
		//scroll:
		scrollBackgroundColor: rgb(24, 43, 80),
		thumbBackgroundColor: rgb(52, 78, 131),
		//modal:
		modalBackgroundColor: #1b1835,
		// rgb(33, 47, 75),
		modalColor: rgb(255, 255, 255),
		//form:
		formBackgroundColor: rgb(53, 74, 116),
		formFooterBackgroundColor: rgb(29, 46, 80),
		formColor: rgb(255, 255, 255),
		formEditBackgroundColor: rgb(0, 24, 44),
		formEditColor: rgb(252, 240, 190),
		//toggle:
		toggleSliderBackgroundColor: rgba(8, 31, 53, 0.3),
		//link:
		linkColorHover: rgb(0, 195, 255),
		linkColor: rgb(205, 242, 247),
		linkColorActive: rgb(0, 89, 141),
		//btnIcon:
		// btnIconBackgroundColor: rgb(68, 121, 170),
		// btnIconColor: white,
		// btnIconBoxShadow: (),
		// btnIconBackgroundColorHover: rgba(255, 255, 255, 1),
		// btnIconColorHover: rgb(9, 37, 65),
		// btnIconBackgroundColorActive: rgb(28, 67, 104),
		// btnIconColorActive: rgb(147, 189, 228),
		btnIconBoxShadow: (),
		btnIconColor: white,
		btnIconBackgroundColor: hsl(215, 99%, 30%),
		btnIconColorHover: white,
		btnIconBackgroundColorHover: hsl(215, 99%, 35%),
		btnIconColorActive: white,
		btnIconBackgroundColorActive: hsl(215, 99%, 25%),
		//btnIconAlert:
		// btnIconAlertBackgroundColor: rgb(170, 68, 68),
		// btnIconAlertColor: white,
		// btnIconAlertBoxShadow: (),
		// btnIconAlertBackgroundColorHover: rgba(255, 255, 255, 1),
		// btnIconAlertColorHover: rgb(65, 9, 9),
		// btnIconAlertBackgroundColorActive: rgb(104, 28, 28),
		// btnIconAlertColorActive: rgb(228, 147, 147),
		btnIconAlertBoxShadow: (),
		btnIconAlertColor: white,

		btnIconAlertBackgroundColor: hsl(0, 99%, 30%),
		btnIconAlertColorHover: white,
		btnIconAlertBackgroundColorHover: hsl(0, 99%, 35%),
		btnIconAlertColorActive: white,
		btnIconAlertBackgroundColorActive: hsl(0, 99%, 25%),
		//btnIconSuccess:
		// btnIconSuccessBackgroundColor: rgb(68, 170, 107),
		// btnIconSuccessColor: white,
		// btnIconSuccessBoxShadow: (),
		// btnIconSuccessBackgroundColorHover: rgba(255, 255, 255, 1),
		// btnIconSuccessColorHover: rgb(9, 65, 37),
		// btnIconSuccessBackgroundColorActive: rgb(28, 104, 72),
		// btnIconSuccessColorActive: rgb(147, 228, 188),
		btnIconSuccessBoxShadow: (),
		// btnIconSuccessBackgroundColor: hsl(123, 100%, 80%),
		// btnIconSuccessColorHover: hsl(0, 0%, 0%),
		// btnIconSuccessBackgroundColorHover: hsl(123, 100%, 85%),
		// btnIconSuccessColorActive: hsl(0, 0%, 0%),
		// btnIconSuccessBackgroundColorActive: hsl(123, 100%, 75%),
		btnIconSuccessColor: white,
		btnIconSuccessBackgroundColor: hsl(148, 99%, 30%),
		btnIconSuccessColorHover: hsl(0, 0%, 100%),
		btnIconSuccessBackgroundColorHover: hsl(148, 99%, 35%),
		btnIconSuccessColorActive: hsl(0, 0%, 100%),
		btnIconSuccessBackgroundColorActive: hsl(148, 99%, 25%),
		//btnIconWarn:
		// btnIconWarnBackgroundColor: rgb(255, 196, 0),
		// btnIconWarnColor: rgb(0, 0, 0),
		// btnIconWarnBoxShadow: (),
		// btnIconWarnBackgroundColorHover: rgba(255, 255, 255, 1),
		// btnIconWarnColorHover: rgb(163, 125, 0),
		// btnIconWarnBackgroundColorActive: rgb(121, 98, 22),
		// btnIconWarnColorActive: rgb(250, 227, 152),
		btnIconWarnBoxShadow: (),
		// btnIconWarnColor: white,
		// btnIconWarnBackgroundColor: hsl(55, 99%, 30%),
		// btnIconWarnColorHover: white,
		// btnIconWarnBackgroundColorHover: hsl(55, 99%, 35%),
		// btnIconWarnColorActive: white,
		// btnIconWarnBackgroundColorActive: hsl(55, 99%, 25%),	
		
		btnIconWarnColor: black,
		btnIconWarnBackgroundColor: hsl(49, 100%, 50%),
		btnIconWarnColorHover: black,
		btnIconWarnBackgroundColorHover: hsl(49, 100%, 55%),
		btnIconWarnColorActive: black,
		btnIconWarnBackgroundColorActive: hsl(49, 100%, 45%),
		//header:
		headerBackgroundColor: rgb(1, 7, 41),
		headerBorderBottom: 1px solid rgba(68, 121, 170, 0),
		comButtonsBorderBottom: 0px solid transparent,
		toolbarButtonLineColor: rgb(255, 187, 0),
		//rgb(28, 43, 117),
		//menu separator:
		menuSeparatorBackgroundColor: rgba(68, 121, 170, 0.5),
		//footer:
		footerBackgroundColor: #241e61,
		//rgb(37, 46, 65),
		footerColor: rgb(255, 255, 255),
		footerBorderTop: 1px solid rgba(0, 23, 36, 0.5),
		footerTimeColor: rgb(119, 230, 147),
		//menu:
		menuButtonBackgroundColor: rgb(223, 241, 248),
		menuButtonColor: rgb(8, 31, 53),
		menuButtonBackgroundColorHover: rgb(255, 255, 255),
		menuButtonColorHover: rgb(1, 15, 27),
		menuBackgroundColor: #0c1839,
		//rgb(105, 0, 255),
		menuColor: rgb(255, 255, 255),
		menuFigureBorderBottom: 1px solid rgb(46, 74, 134),
		menuAvatarBackgroundColor: rgb(28, 67, 104),
		menuHeaderBackgroundColorHover: rgba(255, 255, 255, 0.1),
		menuHeaderColorHover: rgb(8, 31, 53),
		menuStatusSeparatorColor: rgba(255, 255, 255, 0.25),
		//profile:
		profilePlayerBackgroundColor: rgb(87, 121, 194),
		profilePlayerColor: white,
		profileGeoBackgroundColor: rgba(87, 121, 194, 0.1),
		profileGeoColor: white,
		profileGeoHeaderBackgroundColor: transparent,
		profileGeoHeaderColor: white,
		profileGeoLabelBackgroundColor: transparent,
		profileGeoLabelColor: rgb(183, 199, 206),
		//select:
		selectBackgroundColor: hsl(224, 100%, 24%),
		selectArrowColor: transparent transparent hsl(224, 100%, 24%) transparent,
		selectColor: white,
		selectHeaderBackgroundColor: hsl(224, 100%, 14%),
		selectHeaderColor: white,
		selectItemBackgroundColor: rgba(255, 255, 255, 0.1),
		selectItemColor: white,
		selectFoundLettersBackgroundColor: black,
		selectFoundLettersColor: lime,
		//table:
		tableBackgroundColor: steelblue,
		tableEvenBackgroundColor: rgba(255, 255, 255, 0.1),
		tableOddBackgroundColor: rgba(14, 33, 61, 0.1),
		tableBorder: 1px solid rgba(70, 131, 180, 0.5),
		tableGreenColor: rgb(160, 241, 160),
		tableRedColor: rgb(241, 143, 143),
		//dice:
		diceOutline: rgb(0, 0, 0),
		chartBalanceStPointColor: lime,
		chartBalanceCurveColor: rgb(0, 195, 255),
		diceButtonInfoBackgroundColor: (),
		diceButtonBottomBorder: (
			4px solid rgba(0, 0, 0, 0.2),
		),
		diceButtonBottomBorderToggled: (
			2px solid rgba(0, 0, 0, 0.2),
		),
		diceButtonsOutline: black,
		diceButtonMarkColor: gold,
		diceBackground: #061538,
		//#171d35,
		//hsl(227, 19%, 15%),
		diceJackColor: rgba(255, 255, 255, 0.2),
		diceJackOnColor: gold,
		diceJackOnBackgroundColor: (),
		diceJackBetBorderBottom: 4px solid gold,
		diceSliderElementsColor: white,
		sliderBorder: (),
		//dice mybets
		diceMyBetsBetAmountWinBackgroundColor: #000,
		diceMyBetsBetAmountWinColor: lime,
		diceMyBetsBetAmountLoseBackgroundColor: #000,
		diceMyBetsBetAmountLoseColor: red,
		//diceBtn default:
		// diceButtonColor: white,
		// diceButtonBackgroundColor: hsl(207, 53%, 20%),
		// diceButtonColorHover: white,
		// diceButtonBackgroundColorHover: hsl(207, 53%, 25%),
		// diceButtonColorActive: white,
		// diceButtonBackgroundColorActive: hsl(207, 53%, 15%),
		diceButtonColor: white,
		diceButtonBackgroundColor: hsl(224, 64%, 20%),
		diceButtonColorHover: white,
		diceButtonBackgroundColorHover: hsl(224, 64%, 25%),
		diceButtonColorActive: white,
		diceButtonBackgroundColorActive: hsl(224, 64%, 15%),
		//diceBtn red:
		diceButtonRedColor: white,
		diceButtonRedBackgroundColor: hsl(353, 100%, 60%),
		diceButtonRedColorHover: white,
		diceButtonRedBackgroundColorHover: hsl(353, 100%, 65%),
		diceButtonRedColorActive: white,
		diceButtonRedBackgroundColorActive: hsl(353, 100%, 55%),
		//diceBtn yellow:
		diceButtonYellowColor: black,
		diceButtonYellowBackgroundColor: hsl(49, 100%, 50%),
		diceButtonYellowColorHover: black,
		diceButtonYellowBackgroundColorHover: hsl(49, 100%, 55%),
		diceButtonYellowColorActive: black,
		diceButtonYellowBackgroundColorActive: hsl(49, 100%, 45%),
		//diceBtn grey:
		// diceButtonGreyColor: hsl(0, 0%, 80%),
		// diceButtonGreyBackgroundColor: hsl(226, 41%, 12%),
		// diceButtonGreyColorHover: hsl(0, 0%, 100%),
		// diceButtonGreyBackgroundColorHover: hsl(226, 41%, 17%),
		// diceButtonGreyColorActive: hsl(194, 91%, 70%),
		// diceButtonGreyBackgroundColorActive: hsl(226, 41%, 7%),
		diceButtonGreyColor: white,
		diceButtonGreyBackgroundColor: hsl(224, 64%, 20%),
		diceButtonGreyColorHover: white,
		diceButtonGreyBackgroundColorHover: hsl(224, 64%, 25%),
		diceButtonGreyColorActive: white,
		diceButtonGreyBackgroundColorActive: hsl(224, 64%, 15%),
		//diceBtn Dark:
		// diceButtonDarkColor: hsl(0, 0%, 80%),
		// diceButtonDarkBackgroundColor: hsl(227, 19%, 15%),
		// diceButtonDarkColorHover: hsl(0, 0%, 100%),
		// diceButtonDarkBackgroundColorHover: hsl(227, 19%, 20%),
		// diceButtonDarkColorActive: hsl(194, 91%, 70%),
		// diceButtonDarkBackgroundColorActive: hsl(227, 19%, 10%),
		diceButtonDarkColor: white,
		diceButtonDarkBackgroundColor: hsl(224, 64%, 20%),
		diceButtonDarkColorHover: white,
		diceButtonDarkBackgroundColorHover: hsl(224, 64%, 25%),
		diceButtonDarkColorActive: white,
		diceButtonDarkBackgroundColorActive: hsl(224, 64%, 15%),
		//diceBtn lightgrey:
		diceButtonLightgreyColor: hsl(0, 0%, 80%),
		diceButtonLightgreyBackgroundColor: hsl(227, 13%, 25%),
		diceButtonLightgreyColorHover: hsl(0, 0%, 100%),
		diceButtonLightgreyBackgroundColorHover: hsl(227, 13%, 30%),
		diceButtonLightgreyColorActive: hsl(194, 91%, 70%),
		diceButtonLightgreyBackgroundColorActive: hsl(227, 13%, 20%),
		//diceBtn green:
		diceButtonGreenColor: hsl(0, 0%, 0%),
		diceButtonGreenBackgroundColor: hsl(123, 60%, 60%),
		diceButtonGreenColorHover: hsl(0, 0%, 0%),
		diceButtonGreenBackgroundColorHover: hsl(123, 60%, 65%),
		diceButtonGreenColorActive: hsl(0, 0%, 0%),
		diceButtonGreenBackgroundColorActive: hsl(123, 60%, 55%),
	),
);

@mixin themify($themes) {
	@each $theme, $map in $themes {
		.theme-#{$theme} & {
			$theme-map: () !global;
			@each $key, $submap in $map {
				$value: map-get(map-get($themes, $theme), "#{$key}");
				$theme-map: map-merge(
					$theme-map,
					(
						$key: $value,
					)
				) !global;
			}
			@content;
			$theme-map: null !global;
		}
	}
}
@function themed($key, $important: false) {
	$imp: "";
	@if ($important) {
		$imp: "!important";
	}
	// @return map-get($theme-map, $key);
	@return #{map-get($theme-map, $key)}#{$imp};
}
// usage example:
// @include themify($themes) {
// 	color: themed('textColor');
// 	background-color: themed('backgroundColor');
// }
