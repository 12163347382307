.bannerMgr,
.slideMgr {
	> header {
		margin-bottom: 10px;
	}
	> main {
		&.isSlide {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
		padding: 5px;
		> .banner {
			display: flex;
			flex-direction: column;
			width: 100%;
			&.isSlide {
				width: 250px;
				margin: 2px;
			}
			&:not(:last-child) {
				margin-bottom: 10px;
			}
			> .bannerImg {
				width: 100%;
				position: relative;

				> .uploader {
					width: 100% !important;
					> .preview {
						background-size: cover;
					}
					&.upSlide > .preview {
						background-size: contain;
					}
					> .preview {
						background-repeat: no-repeat;
						width: unset !important;
						left: 0;
						right: 0;
					}
				}
			}
			> * {
				&:not(:last-child):not(input) {
					margin-bottom: 5px;
				}
			}
			> input {
				margin-bottom: 10px;
			}
			> .buttons {
				display: flex;
				align-items: center;
				justify-content: space-between;
				> .btnDelete {
				}
				> .btnSubmit {
				}
			}
		}
	}
}
