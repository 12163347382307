.forgotPass {
	box-sizing: border-box;
	padding: 10px;
	min-width: 300px;
	width: 300px;
	> .wrapper {
		padding: 10px;
		box-sizing: border-box;
		width: 100%;
		display: flex;
		flex-direction: column;
		> header {
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 10px;
			> h3 {
				font-size: 22px;
				font-weight: bold;
			}
		}
		> main {
			> .btns {
				display: flex;
				align-items: center;
				margin-top: 10px;
				> * {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					&:not(:last-child) {
						margin-right: 5px;
					}
				}
			}
			> .block {
				margin-bottom: 10px;
				position: relative;
				> .eye {
					transition: opacity 0.3s ease-in-out;
					&.on {
						opacity: 1;
					}
					&.off {
						opacity: 0.5;
					}
					position: absolute;
					right: 3px;
					z-index: 1;
					width: 32px;
					height: 32px;
					background-color: transparent;
					top: 3px;
					&:hover,
					&:active {
						background-color: transparent !important;
					}
				}
				> label {
					display: block;
					user-select: none;
				}
				> label:not(:last-child) {
					margin: 5px 0;
				}
				> .code {
					height: 80px;
					@include themify($themes) {
						color: themed("warn");
					}
				}
				> input:not(:last-child) {
					margin-bottom: 5px;
				}
				&.btns {
					display: flex;
					align-items: center;
					margin-top: 10px;
					> * {
						flex: 1;
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						&:not(:last-child) {
							margin-right: 5px;
						}
					}
				}
			}
		}
	}
}
