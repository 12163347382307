.systemMgr {
	> header,
	> main,
	> footer,
	> .formGeneral {
		margin-bottom: 10px;
	}
	> header {
		> h2 {
			font-weight: bold;
			margin-bottom: 5px;
		}
	}
	> .blocks {
		> .block {
			display: flex;
			flex-direction: column;
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
}
