.chatEmoji {
	width: 100%;
	flex: 1;
	overflow: hidden;
	> header {
		display: flex;
		padding: 1px;
		@include themify($themes) {
			background-color: themed("diceOutline");
		}
		margin-bottom: 5px;
		> button {
			flex: 1;
			> .em {
				width: 20px;
				height: 20px;
			}
		}
	}
	.cat {
		display: flex;
		flex-direction: column;
		> label {
			margin: 10px 0;
			padding: 5px;
			width: 100%;
			user-select: none;
		}
		> .emojis {
			width: 100%;
			> button.em {
				width: 28px;
				height: 28px;
				margin: 3px;
				transition: transform 0.3s ease;
				@media (hover: hover) {
					&:hover {
						transform: scale(1.1);
					}
				}
				&:active {
					transform: scale(0.7);
				}
			}
		}
	}
}
// .em {
// 	background-image: url("/assets/twitter32.png");
// }
