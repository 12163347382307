.history {
	padding: 10px 10px 80px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	> *:not(:last-child) {
		margin-bottom: 10px;
	}
	> .multiSelect {
		width: 100%;
		min-width: 280px;
		max-width: 750px;
		flex: unset !important;
	}
	.rows {
		.red {
			@include themify($themes) {
				color: themed("alert");
			}
			&.comment {
				font-weight: bold;
				font-size: 16px;
			}
		}
		.green {
			@include themify($themes) {
				color: themed("success");
			}
		}
		padding: 5px;
		border-radius: 10px;
		min-width: 280px;
		max-width: 750px;
		box-sizing: border-box;
		display: flex;
		flex: 1;
		flex-direction: column;
		@include themify($themes) {
			background-color: themed("profilePlayerBackgroundColor");
			color: themed("profilePlayerColor");
		}
		> h3 {
			padding: 5px;
			border-radius: 5px;
			background-color: rgba(0, 0, 0, 0.1);
			@include themify($themes) {
				color: themed("warn");
			}
			font-weight: 700;
			font-size: 18px;
		}
		> .no_content {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			padding: 20px;
		}
		> .togglers {
			display: flex;
			align-items: center;
			margin-bottom: 5px;
			> * {
				flex: 1;
				min-width: 35px;
				&._off {
					opacity: 0.5;
				}
			}
			flex-wrap: wrap;
			button > i {
				pointer-events: none;
			}
		}
		> .row {
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			padding: 5px;
			margin-bottom: 3px;
			// &:nth-child(2n) {
			// 	background-color: rgba(0, 0, 0, 0.3);
			// }
			> .head {
				margin-bottom: 5px;
				width: 100%;
				display: flex;
				align-items: center;
				> * {
					&:not(:first-child) {
						margin-left: 5px;
					}
				}
				> .title {
					width: 120px;
					overflow: hidden;
				}
				> .flex1 {
					flex: 1;
				}
				> .t {
					font-family: "Roboto Mono";
					> .d {
						font-weight: bold;
						margin-right: 5px;
					}
					> .t {
						font-size: 11px;
					}
				}
			}
			> .infoWrapper {
				width: 100%;
				padding: 10px 0;
				background-color: rgba(0, 0, 0, 0.3);
				border-radius: 5px;
				> .info {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					> *:not(:first-child):not(.ac_0):not(.ac_1):not(.ac_16) {
						margin-left: 10px;
					}
					&.info1.ac_0,
					&.info1.ac_1,
					&.info1.ac_16 {
						flex-direction: column;
						a {
							word-break: break-all;
						}
						> div {
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							&:not(:first-child) {
								margin-top: 10px;
							}
							flex-wrap: wrap;
							> * {
								&:not(:first-child) {
									margin-left: 5px;
								}
								word-break: break-all;
							}
						}
					}
					&.info2 {
						margin-top: 5px;
					}

					flex-wrap: wrap;
					> .c {
						display: flex;
						align-items: center;
						justify-content: center;
						> *:not(:first-child) {
							margin-left: 10px;
						}
					}
				}
			}
		}
	}
}
