.chatButton {
	position: fixed;
	bottom: 20px; //90px;
	left: 20px; // 25px;
	z-index: 10;
	transition: transform 0.3s ease;
	&.off {
		pointer-events: none;
		transform: scale(0, 0);
	}
	&.on {
		pointer-events: auto;
		transform: scale(1, 1);
	}
	> button {
		border-radius: 50%;
		position: relative;
		> i {
			padding-right: 0px !important;
		}
		> .count {
			@include themify($themes) {
				background-color: themed("success");
				color: themed("dark");
			}
			position: absolute;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			font-size: 16px;
			top: -10px;
			right: -10px;
			border-radius: 5px;
			font-weight: bold;
			transition: transform 0.3s ease-in-out;
			user-select: none;
			&.off {
				transform: scale(0);
			}
		}
	}
}
