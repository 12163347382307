.chartComponent {
	.ct-chart {
		.yLbl {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
		.ct-line {
			stroke-width: 2px;
		}
		.ct-grids {
			> .ct-grid {
				stroke: none;
				&.ct-vertical {
					@include themify($themes) {
						stroke: themed("warn");
					}
				}
				stroke-dasharray: unset;
				&:first-child,
				&:last-child {
					opacity: 0;
				}
			}
		}
	}
	> .slider {
		// position: absolute;
		// bottom: 25px;
		// left: 0;
		// right: 0;

		-webkit-appearance: none; /* Override default CSS styles */
		appearance: none;
		width: 100%; /* Full-width */
		height: 5px; /* Specified height */
		background: #d3d3d3; /* Grey background */
		outline: none; /* Remove outline */
		// opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
		// -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
		// transition: opacity 0.2s;
		padding: 0;
	}
	/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
	@mixin thumb {
		-webkit-appearance: none; /* Override default look */
		appearance: none;
		width: 36px;
		height: 36px;
		cursor: pointer;
		border-radius: 5px;
		background-color: white;
		outline: none;
		border: none;
	}
	.slider::-webkit-slider-thumb {
		@include thumb();
	}
	.slider::-moz-range-thumb {
		@include thumb();
	}
	> .dateRange {
		display: flex;
		margin-top: 20px;
	}
}
.chartByUrl {
	> .chartTopButtons {
		display: flex;
		flex-wrap: wrap;
		> * {
			margin: 3px;
			border-radius: 3px;
			flex: unset;
			padding: 5px 10px;
			font-size: 11px;
		}
	}
	min-width: 300px;
	&.labelsOn {
		.ct-label {
			stroke-width: 0.1px;
			fill: rgba(255, 255, 255, 0.6);
		}
	}
	&.labelsOff {
		.ct-label {
			stroke-width: 0px;
			fill: rgba(255, 255, 255, 0);
		}
	}

	> .statsWrapper {
		overflow-x: scroll;
		width: 100%;
		> .ct-chart {
			width: 5000px;
		}
	}
	> .legend {
		margin-top: 5px;
		display: flex;
		flex-wrap: wrap;
		> .legendEntry {
			padding: 4px;
			&.toggled {
				filter: grayscale(1);
			}
			> b {
				font-size: 11px;
			}
			display: flex;
			flex-direction: row;
			align-items: center;
			> div {
				width: 12px;
				height: 12px;
				margin-left: 10px;
				border-radius: 5px;
			}
		}
	}
}
