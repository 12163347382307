.pages {
	display: flex;
	align-items: center;
	justify-content: center;
	> button {
		max-width: 30px;
		font-weight: bold;
		font-family: "Roboto Mono";
		font-size: 15px;
	}
}
