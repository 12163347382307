@mixin containerClass {
	font-size: 14px;
	font-weight: normal;
	position: relative;
	&:not(.container-lobby):not(.container-offline) {
		margin-top: $headerHeight;
	}
	flex: 1;
	min-height: calc(100% - #{$headerHeight});
	background-color: transparent;
	@include themify($themes) {
		background-color: themed("containerBackgroundColor");
		color: themed("containerColor");
	}
}
.modalBody {
	.container {
		margin-top: 0 !important;
	}
}
.btnToast,
.goToPassword {
	> .btns {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
		> button {
			padding: 5px 10px;
			border-radius: 5px;
		}
	}
}
.app {
	min-height: 100%;
	position: relative;
	> .react-responsive-modal-root > .react-responsive-modal-container {
		> .modalBody {
			overflow: unset;
			background-color: transparent !important;
			> div > .container {
				@include containerClass;
				margin-top: 0px;
				border-radius: 10px;
				background-color: transparent !important;
			}
		}
	}
	> .bodyPane {
		min-height: 100%;
		min-width: 300px; //20px on scrollbar
		position: absolute;
		background-color: rgb(59, 65, 85);
		display: flex;
		flex-direction: column;
		&.chatOn {
			@media (min-width: ($chatPaneWidth + 399px)) {
				&.left {
					left: 0;
					right: $chatPaneWidth;
				}
				&.right {
					left: $chatPaneWidth;
					right: 0;
				}
			}
			@media (max-width: ($chatPaneWidth + 399px)) {
				display: none;
				pointer-events: none;
			}
		}
		&.chatOff {
			width: 100%;
		}
		> .container {
			@include containerClass;
		}
	}
}
