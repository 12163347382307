.whoIsOnlineContainer {
	display: flex;
	align-items: center;
	flex-direction: column;
	> h1 {
		font-size: 22px;
		margin-top: 20px;
	}
	> .filter {
		display: flex;
		flex-direction: column;
		max-width: 750px;
		width: 100%;
		> * {
			width: 100%;
			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}
		margin-bottom: 10px;
	}
	> .wrapper {
		max-width: 750px;
		width: 100%;
		justify-content: center;
		box-sizing: border-box;
		padding: 5px;
		border-radius: 5px;
		display: flex;
		flex-wrap: wrap;
		@include themify($themes) {
			background-color: themed("profilePlayerBackgroundColor");
			color: themed("profilePlayerColor");
		}
		.avatarComponent {
			margin: 3px;
			&.off {
				display: none;
			}
		}
		> .rsc {
			margin: 3px;
			> div {
				display: flex;
				align-items: center;
				> b.i {
					font-weight: bold;
				}
			}
			> b.ch {
				font-size: 12px;
			}
			background-color: rgba(0, 0, 0, 0.2);
			padding: 3px;
			border-radius: 5px;
		}
	}
}
