.wavetext.mobile {
	font-size: 22px;
	text-align: center;
	> * {
		background: linear-gradient(#591c8e, #ca2867);
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}
.wavetext:not(.mobile) {
	$offset: -5px;
	$count: 40;
	$duration: 0.3;
	@keyframes wave {
		from {
			transform: translateY(-$offset);
			color: white;
		}
		to {
			transform: translateY($offset);
		}
	}
	text-align: center;
	padding: $offset 0;
	font-size: 22px;
	> * {
		display: inline-block;
		animation-duration: #{$duration}s;
		animation-name: wave;
		animation-iteration-count: 50; //infinite;
		animation-direction: alternate;
	}
	@for $i from 0 to $count {
		:nth-child(#{$count}n + #{$i}) {
			animation-delay: -#{($count - $i) * 2 * $duration / $count}s;
			color: rgba(100 + $i * 20 % 155, 10, 100 + $i * 40 % 55, 1);
		}
	}
}
.neonbackground {
	$duration: 1.5;
	@keyframes anim {
		from {
			background-color: rgba(255, 108, 250, 0.1);
		}
		to {
			background-color: rgba(124, 77, 255, 0.1);
		}
	}
	animation-duration: #{$duration}s;
	animation-name: anim;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}
